<div class="container" >
    <h2 mat-dialog-title class="title">{{title | translate}}</h2>
    <div mat-dialog-content>
  
      <div>
        {{content1 | translate}}
      </div>
      <br>  
  
    </div>
    <br>
    <div mat-dialog-actions>
      <button mat-raised-button mat-dialog-close   (click)="onOKClick()" color="primary">
        {{'OK' | translate}}
      </button>
    </div>
  </div>
  