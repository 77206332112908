<app-loader [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
  [displayProgressSpinner]="loaderService.loader">
</app-loader>

<main class="content">
  <router-outlet></router-outlet>
  <app-footer *ngIf="hideLoader == true" style="min-height: 220px; display: flex; align-items: end;"></app-footer>

  <button id="btnScrollTop" class="ct-scroll-top" (click)="onClickOnTop()">
    <mat-icon class="ct-scroll-top-icon">keyboard_arrow_up</mat-icon>
  </button>

</main>

<mat-progress-spinner *ngIf="hideLoader == false" style="margin:0 auto;"  mode="indeterminate">
</mat-progress-spinner>


