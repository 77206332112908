<div *ngIf=" gtinAttribute " class="verified-company" id="htmlData">

  <div class="row mt-spacer-1 no-gutters">
    <div class="col-md-10 detailWorkBreak"
      *ngIf="arrErrorMessages.length==0 && gtinAttribute && gtinAttribute.productDescription">
      <h3 style="padding-top: 24px;">{{gtinAttribute.productDescription[0]?.value}}</h3>
    </div>
    <div class="col-md-12 gtindetailimagedirection"
      *ngIf="arrErrorMessages.length==0 && gtinAttribute && canDisplayAttributesSection">

      <div class="col-md-3" style="align-self: center;text-align: center;">
        <div *ngIf="gtinAttribute && gtinAttribute.productImageUrl">
          <img alt="Image cannot be displayed"
            style="max-width: 230px;max-height: 230px;width:100%;height: 100%; object-fit: contain;" id="imgDisplayPD"
            src={{gtinAttribute.productImageUrl[0]?.value}}
            onerror="this.onerror=null;this.src='../../../../assets/img/image_not_displayed.png';">
        </div>
        <div *ngIf="!(gtinAttribute && gtinAttribute.productImageUrl)">
          <img alt="Not available" src="{{cdnUrl}}/image_not_displayed.png">
        </div>
      </div>

      <div class="col-md-9 structured-list">

        <table class="company">
          <tbody>
            <tr>
              <td>{{'GTIN' | translate}}</td>
              <td><strong class="detailWorkBreak">{{gtinAttribute.gtin}}</strong></td>
            </tr>

            <tr *ngFor="let item of gtinAttribute.brandName; let i= index;">
              <td rowspan="1" *ngIf="i==0">{{'Brand_name' | translate}}</td>
              <td rowspan="1" *ngIf="i!=0"></td>
              <td class="value">
                <strong class="detailWorkBreak"> ({{item.language}})&nbsp;{{item.value}} </strong>
              </td>
            </tr>

            <tr *ngFor="let item of gtinAttribute.productDescription; let i= index;">
              <td rowspan="1" *ngIf="i==0">{{'Product_description' | translate}}</td>
              <td rowspan="1" *ngIf="i!=0"></td>
              <td class="value">
                <strong class="detailWorkBreak"> ({{item.language}})&nbsp;{{item.value}}</strong>
              </td>
            </tr>

            <tr *ngIf="!(gtinAttribute && gtinAttribute.productDescription)">
              <td>{{'Product_description' | translate}}</td>
              <td class="detailvalueunknown"> {{'Unknown' | translate}}
              </td>
            </tr>

            <tr *ngFor="let item of gtinAttribute.productImageUrl; let i= index;">
              <td *ngIf="i==0">{{'Product_image_URL' | translate}}</td>
              <td rowspan="1" *ngIf="i!=0"></td>
              <td>({{item.language}})&nbsp;
                <a rel="noopener" class="detaillink" target="_blank" href={{item.value}}>
                  {{item.value}}</a>
              </td>
            </tr>


            <tr *ngIf="!(gtinAttribute && gtinAttribute.productImageUrl)">
              <td>{{'Product_image_URL' | translate}}</td>
              <td class="detailvalueunknown"> {{'Unknown' | translate}}
              </td>
            </tr>

            <tr>
              <td>{{'Global_Product_Category' | translate}}</td>
              <td><strong class="detailWorkBreak">{{gtinAttribute.gpcCategoryCode}}</strong></td>
            </tr>

            <ng-container *ngIf="gtinAttribute && gtinAttribute.netContent">
              <tr *ngFor="let item of gtinAttribute.netContent[0]?.netContents; let i= index;">
                <td *ngIf="i==0">{{'Net_content' | translate}}</td>
                <td *ngIf="i!=0"></td>
                <td><strong class="detailWorkBreak">{{item}}</strong></td>
              </tr>
            </ng-container>

            <ng-container *ngIf="!(gtinAttribute && gtinAttribute.netContent)">
              <tr>
                <td>{{'Net_content' | translate}}</td>
                <td class="detailvalueunknown"> {{'Unknown' | translate}}
                </td>
              </tr>
            </ng-container>


            <tr>
              <td>{{'Countries_of_sale' | translate}}</td>

              <td *ngIf="gtinAttribute.countryOfSaleCode else display_unknown">
                <strong> {{gtinAttribute.countryOfSaleCode[0]?.countryOfSaleCodes}}</strong>
              </td>
              <!-- <ng-template #display_unknown>
                <td class="detailvalueunknown">
                  {{'Unknown' | translate}}
                </td>
              </ng-template> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div class="col-md-12 print-pdf gtindetailPDFButtonDirection"
      *ngIf="arrErrorMessages.length==0 && gtinAttribute && gtinAttribute.productDescription">
      <button color="primary" class="pdf-button" id="btnPDF" (click)="downloadPDF()">
        {{'Print_PDF' | translate}}
      </button>
    </div> -->
    <div fxLayout="row" class="col-md-12"
      *ngIf="arrErrorMessages.length==0 && gtinAttribute && gtinAttribute.dateUpdated">
      <p class="info-data">
        {{'This_data_has_been_provided_by' | translate}} {{gtinAttribute.gs1Licence.licenseeName === 'Licensee name withheld for privacy reasons' ? 
        ('licensee_name_withheld' | translate) : gtinAttribute.gs1Licence.licenseeName}},
        {{'and_was_last_updated_on' | translate}}
        {{gtinAttribute.dateUpdated}}.
      </p>
    </div>
    <div fxLayout="row" class="col-md-12" *ngIf=" gtinAttribute && gtinAttribute.isComplete">
      <p class="info-data">
        {{'This_GTIN_record_has_complete_data' | translate}}.
      </p>
    </div>    

    <div fxLayout="row" class="col-md-12"
      *ngIf="gtinAttribute && gtinAttribute.gtinRecordStatus && gtinAttribute.gtinRecordStatus == 'INACTIVE'">
      <p class="info-data">
        {{'This_record_is_no_longer_being_kept_up-to-date_by_the_original_manufacturer' | translate}}
        <br>
        {{'Please_contact_your_local_GS1_Member_Organisation_for_additional_support' | translate}}
      </p>
    </div>

    <div fxLayout="row" class="col-md-12"
      *ngIf="gtinAttribute && gtinAttribute.gtinRecordStatus && gtinAttribute.gtinRecordStatus == 'DISCONTINUED'">
      <p class="info-data">
        {{'This_product_is_no_longer_produced_by_the_original_manufacturer_or_distributor' | translate}}
      </p>
    </div>
  </div>
</div>




<div *ngIf=" glnAttribute " class="verified-company" id="htmlData">
  <h3 *ngIf="glnAttribute?.glnRecords?.length > 1" class="pb-spacer-4" style="
    padding-top: 1.5rem;
    margin-bottom: 1.125rem;
    padding-bottom: 1.5rem;
">{{'This_GLN_Record_Contains_The_Following_Sections' | translate}}:</h3>
  <div id="accordion">
    <div *ngIf="legalEntityIndex!=-1" class="card">
      <div *ngIf="glnAttribute?.glnRecords?.length > 1" class="card-header" [style.cursor]="glnAttribute?.glnRecords?.length > 1 ? 'pointer' : 'default'" >
        <div class="card-header-info collapsed" data-toggle="collapse"  [ngClass]="glnAttribute?.glnRecords?.length > 1 ? '' : 'accordion-control'"
          onclick="var content = document.getElementById('legal-entity'); content.classList.contains('collapse') ? content.classList.remove('collapse') : content.classList.add('collapse'); this.classList.contains('collapsed')? this.classList.remove('collapsed') : this.classList.add('collapsed')"
          data-target="#legal-entity" aria-expanded="false" aria-controls="legal-entity">
          <h5>{{'LEGAL_ENTITY' | translate}}</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-chevron-down color-blue" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
        </div>
      </div>
      <div id="legal-entity" [ngClass]="glnAttribute?.glnRecords?.length > 1 ? 'collapse' : ''" class="" data-parent="#accordion" style="">
        <div style="margin-left: 10px;" class="row">
          <div class="col-md-12">
            <p style="color: #002c6c;" 
            [ngStyle]="{'font-size': glnAttribute.glnRecords.length > 1 ? '1.125rem' : '2rem', 
                        'font-weight':glnAttribute.glnRecords.length > 1 ? '400' :'300'
                       }" 
            class="color-blue"
            [ngClass]="glnAttribute.glnRecords.length > 1 ? 'no-m' : 'mb-2'">
            {{glnAttribute.glnRecords[legalEntityIndex]?.organizationName[0]?.value }}</p>
          </div>
          <div class="col-md-12">
            <p *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.glnRecordStatus?.toString().toLowerCase() !== 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Legal_Entity_Is' | translate}} {{
              glnAttribute.glnRecords[legalEntityIndex]?.glnRecordStatus?.toString().toLowerCase() }}</p>
              <p *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.glnRecordStatus?.toString().toLowerCase() === 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Legal_Entity_Is' | translate}} {{
                'Discontinued_Status_not_operational' | translate }}</p>
          </div>
          <div class="col-md-12">
            <div class="content-module-company">
              <div class="row">
                <div class="col-md-12 structured-list striped scroll">
                  <table class="company">
                    <tbody>
                      <tr>
                        <td>{{'Gln_Information_Short' | translate}}</td>
                        <td><strong>{{glnAttribute.glnRecords[legalEntityIndex]?.gln}}</strong></td>
                      </tr>
                      <tr>
                        <td>
                          {{'GLN_Type' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Designation_Of_What_A_GLN_Is_Being_Used_To_Identify' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td><strong>{{'LEGAL_ENTITY' | translate}}</strong></td>
                      </tr>
                      <tr>
                        <td>{{'Name' | translate}}</td>
                        <td>
                          <table style="margin: 0;">
                            <tr style="border-style: hidden;"
                              *ngFor=" let legalEntityName of glnAttribute.glnRecords[legalEntityIndex]?.organizationName">
                              <td style="color: #002c6c; padding: 0;"><strong>({{legalEntityName?.language+ ') ' +
                                  legalEntityName?.value}}</strong></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Organisation_Roles' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Role_And_or_Purpose' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.organizationRole else display_unknown">
                          <strong>{{ glnAttribute.glnRecords[legalEntityIndex]?.organizationRole.join(', ')
                            }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Parent_Organisation' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Legal_Entity_Or_Function_Directly_Above_The_Organisation' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.parentOrganization else display_unknown">
                          <strong>{{
                            glnAttribute.glnRecords[legalEntityIndex]?.parentOrganization.gln + ' (' +
                            (glnAttribute.glnRecords[legalEntityIndex]?.parentOrganization.glnType | translate) + ')'

                            }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Related_Locations' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'The_Place_Associated_With_An_Organisation' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>

                        </td>
                        <td *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.location else display_unknown"><strong
                            *ngFor="let relatedLocation of glnAttribute.glnRecords[legalEntityIndex]?.location">{{
                            relatedLocation.gln+ ' ('+ (relatedLocation.glnType | translate) +')'
                            }}<br /></strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Additional_Identifiers' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Identifiers_Assigned_And_Managed_By_Government_Bodies' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td
                          *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.additionalOrganizationID else display_unknown">
                          <strong
                            *ngFor="let additionalIdentifier of glnAttribute.glnRecords[legalEntityIndex]?.additionalOrganizationID">
                            {{
                            additionalIdentifier.organizationID+ ' ('+ additionalIdentifier.organizationID_Type+')'
                            }}<br /></strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="glnAttribute?.glnRecords[legalEntityIndex]?.address" style="margin-top: 20px;" class="col-md-12 pt-spacer-5">
            <h3 style="margin-bottom: 0.5rem; margin-top: 2rem;">{{'Where_Is_This_Organisation' | translate}}</h3>
            <div class="row">
              <div class="col-md-12 structured-list striped scroll" style="padding-left: 0;">
                <ng-container
                  *ngFor="let addressRecord of glnAttribute.glnRecords[legalEntityIndex]?.address; let i = index">
                  <div class="postal-address">
                    <p class="color-blue" style="color: #002c6c; font-weight:400; font-size: 15.3px;">{{'Postal_Address' | translate}}</p>
                    <table class="company address">
                      <tbody>
                        <tr>
                          <td>{{'Postal_Name' | translate}}</td>
                          <td *ngIf="addressRecord?.postalName?.value else display_unknown">
                            <strong>{{ addressRecord?.postalName?.value}}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>{{'Post_Office_Box_Number' | translate}}</td>
                          <td *ngIf="addressRecord?.postOfficeBoxNumber else display_unknown">
                            <strong>{{ addressRecord?.postOfficeBoxNumber }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>{{'StreetAddress' | translate}}</td>
                          <td style="padding: 0px;" *ngIf="addressRecord else display_unknown">
                            <table style="margin:3px 0;">
                              <tr style="border-style: hidden;">
                                <td style="color: #002c6c; padding: 0 9px;"><strong>{{
                                    addressRecord?.streetAddress?.value +
                                    (addressRecord?.streetAddress?.value ? ', ' : '') +
                                    addressRecord?.streetAddressLine2?.value +
                                    (addressRecord?.streetAddressLine2?.value ? ', ' : '')

                                    }}</strong></td>
                              </tr>
                              <tr style="border-style: hidden; " *ngIf="addressRecord && addressRecord?.streetAddressLine3">
                                <td style="color: #002c6c; padding: 0 9px;"><strong>{{
                                    addressRecord?.streetAddressLine3?.value +
                                    (addressRecord?.streetAddressLine3?.value ? ', ' : '') +
                                    addressRecord?.streetAddressLine4?.value

                                    }}</strong></td>
                              </tr>
                            </table>
                          </td>

                        </tr>
                        <tr>
                          <td>{{'Cross_Street' | translate}}</td>
                          <td *ngIf="addressRecord?.crossStreet?.value else display_unknown"><strong>{{
                              addressRecord?.crossStreet?.value }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Suburb' | translate}}</td>
                          <td *ngIf="addressRecord?.addressSuburb?.value else display_unknown"><strong>{{
                              addressRecord?.addressSuburb?.value}}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Address_Locality' | translate}}</td>
                          <td *ngIf="addressRecord?.addressLocality?.value else display_unknown"><strong>{{
                              addressRecord?.addressLocality?.value }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Region' | translate}}</td>
                          <td *ngIf="addressRecord?.addressRegion?.value else display_unknown"><strong>{{
                              addressRecord?.addressRegion?.value }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Postal_Code' | translate}}</td>
                          <td *ngIf="addressRecord?.postalCode else display_unknown"><strong>{{
                              addressRecord?.postalCode }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Country_Code' | translate}}</td>
                          <td *ngIf="addressRecord?.countryCode else display_unknown"><strong>{{
                              addressRecord?.countryCode }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Country_Subdivision_Code' | translate}}</td>
                          <td *ngIf="addressRecord?.countrySubdivisionCode else display_unknown"><strong>{{
                              addressRecord?.countrySubdivisionCode }}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
          <div *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.contactPoint" style="margin-top: 20px;"
            class="col-md-12 pt-spacer-5">
            <h3 style="margin-bottom: 0.3rem; margin-top: 2.5rem;">{{'How_to_get_in_contact' | translate}}</h3>
            <div class="row">
              <div class="col-md-12 structured-list striped scroll">
                <table class="company">
                  <tbody>
                    <tr>
                      <td>{{'Email' | translate}}</td>
                      <td><a [attr.href]="'mailto:' + contactPoint.email"  class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[legalEntityIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                        {{contactPoint.email
                        ?
                        contactPoint.email : '' }}<br /></strong></a></td>
                    </tr>
                    <tr>
                      <td>{{'Telephone' | translate}}</td>
                      <td>
                        <strong
                          *ngFor="let contactPoint of glnAttribute.glnRecords[legalEntityIndex]?.contactPoint">{{contactPoint.telephone
                          ? contactPoint.telephone : 'Unknown' | translate
                          }}<br /></strong>
                      </td>
                    </tr>
                    <tr>
                      <td>{{'CI_Website' | translate}}</td>
                      <td><a [attr.href]="contactPoint.website" class="detaillink" target="_blank"  *ngFor="let contactPoint of glnAttribute.glnRecords[legalEntityIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                        {{contactPoint.website
                        ?
                        contactPoint.website : '' }}<br /></strong></a></td>
                    </tr>
                    <tr style="background: none;">
                      <td>{{'Available_language' | translate}}</td>                      
                      <div *ngIf="glnAttribute.glnRecords[legalEntityIndex]?.uniqueLanguages?.length>0 else display_unknown">
                        <div style="color: #002c6c; margin-left:8px"
                          *ngFor="let language of glnAttribute.glnRecords[legalEntityIndex]?.uniqueLanguages">
                          <strong>{{ language }}</strong>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-spacer-3">
            <p class="info-data">
            </p>
          </div>
        </div>
        <div style="margin-top: 3.5rem;" *ngIf="this.l2sdDisplay?.legalEntityIndex">
          <div *ngIf="glnAttribute.linkset">
            <h3> {{ "L2SD_Links_to_other_information" | translate }}</h3>
            <app-glnl2sd [glnAttribute]=glnAttribute *ngIf="glnAttribute.linkset"></app-glnl2sd>
          </div>
        </div>
        <div fxLayout="row" class="col-md-12"
          *ngIf="arrErrorMessages.length==0 && glnAttribute && glnAttribute?.glnRecords[legalEntityIndex]?.dateUpdated">
          <p class="info-data" style="padding-left:12px; margin-left:10px;">
            {{'This_data_has_been_provided_by' | translate}} {{glnAttribute?.gs1Licence.licenseeName === 'Licensee name withheld for privacy reasons' ? 
            ('licensee_name_withheld' | translate) : glnAttribute?.gs1Licence.licenseeName}},
            {{'and_was_last_updated_on' | translate}}
            {{glnAttribute?.glnRecords[legalEntityIndex]?.dateUpdated | date: 'd MMM yyyy'}}.
          </p>
        </div>
        <div class="accordion-bar bg-orange"></div>
      </div>
    </div>



    <div *ngIf="functionIndex!=-1" class="card">
      <div *ngIf="glnAttribute.glnRecords.length > 1" class="card-header" [style.cursor]="glnAttribute.glnRecords.length > 1 ? 'pointer' : 'default'">
        <div class="collapsed card-header-info" [ngClass]="glnAttribute.glnRecords.length > 1 ? '' : 'accordion-control'"
          onclick="var content = document.getElementById('function'); content.classList.contains('collapse') ? content.classList.remove('collapse') : content.classList.add('collapse'); this.classList.contains('collapsed')? this.classList.remove('collapsed') : this.classList.add('collapsed')"
          data-toggle="collapse" data-target="#digital-location" aria-expanded="false" aria-controls="digital-location">
          <h5>{{'FUNCTION' | translate}}</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-chevron-down color-blue" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
        </div>
      </div>
      <div id="function" [ngClass]="glnAttribute.glnRecords.length > 1 ? 'collapse' : ''" class="" data-parent="#accordion">
        <div style="margin-left: 10px;" class="row">
          <div class="col-md-12">
            <p style="color: #002c6c;" 
            [ngStyle]="{'font-size': glnAttribute.glnRecords.length > 1 ? '1.125rem' : '2rem', 'font-weight':glnAttribute.glnRecords.length > 1 ? '400' :'300'}"
            class="color-blue"
            [ngClass]="glnAttribute.glnRecords.length > 1 ? 'no-m' : 'mb-2'">{{
              glnAttribute.glnRecords[functionIndex]?.department &&
              glnAttribute.glnRecords[functionIndex]?.department[0]?.value }}
            </p>
          </div>
          <div class="row mb-spacer-2">
            <div class="col-md-12">
                <p *ngIf="glnAttribute.glnRecords[functionIndex]?.glnRecordStatus?.toString().toLowerCase() !== 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Function_Is' | translate}} {{
                  glnAttribute.glnRecords[functionIndex]?.glnRecordStatus?.toString().toLowerCase() }}</p>
                  <p *ngIf="glnAttribute.glnRecords[functionIndex]?.glnRecordStatus?.toString().toLowerCase() === 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Function_Is' | translate}} {{
                    'Discontinued_Status_not_operational' | translate }}</p>
            </div>
            <div class="col-md-12">
              <div class="content-module-company">
                <div class="row">
                  <div class="col-md-12 structured-list striped scroll">
                    <table class="company">
                      <tbody>
                        <tr>
                          <td>{{'Gln_Information_Short' | translate}}</td>
                          <td><strong>{{glnAttribute.glnRecords[functionIndex]?.gln}} </strong></td>
                        </tr>
                        <tr>
                          <td>
                            {{'GLN_Type' | translate}}
                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Designation_Of_What_A_GLN_Is_Being_Used_To_Identify' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>

                          </td>
                          <td><strong>{{'FUNCTION' | translate}}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Name' | translate}}</td>
                          <td>
                            <table style="margin: 0;">
                              <tr style="border-style: hidden;"
                                *ngFor=" let functionEntityName of glnAttribute.glnRecords[functionIndex]?.department">
                                <td style="color: #002c6c; padding: 0;"><strong>({{functionEntityName?.language+ ') ' +
                                    functionEntityName?.value}}</strong></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{'Organisation_Roles' | translate}}
                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Role_And_or_Purpose' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>

                          </td>
                          <td *ngIf="glnAttribute.glnRecords[functionIndex]?.organizationRole else display_unknown">
                            <strong>{{
                              glnAttribute.glnRecords[functionIndex]?.organizationRole.join(', ')
                              }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{'Parent_Organisation' | translate}}
                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Legal_Entity_Or_Function_Directly_Above_The_Organisation' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>

                          </td>
                          <td *ngIf="glnAttribute.glnRecords[functionIndex]?.parentOrganization else display_unknown">
                            <strong>{{
                              glnAttribute.glnRecords[functionIndex]?.parentOrganization.gln + ' (' +
                              (glnAttribute.glnRecords[functionIndex]?.parentOrganization.glnType | translate) + ')'
                              }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{'Related_Locations' | translate}}
                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'The_Place_Associated_With_An_Organisation' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>

                          </td>
                          <td *ngIf="glnAttribute.glnRecords[functionIndex]?.location else display_unknown"><strong
                              *ngFor="let relatedLocation of glnAttribute.glnRecords[functionIndex]?.location">{{
                              relatedLocation.gln+ ' ('+ (relatedLocation.glnType | translate) +')'
                              }}<br /></strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{'Additional_Identifiers' | translate}}
                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Identifiers_Assigned_And_Managed_By_Government_Bodies' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>

                          </td>
                          <td
                            *ngIf="glnAttribute.glnRecords[functionIndex]?.additionalOrganizationID else display_unknown">
                            <strong
                              *ngFor="let additionalIdentifier of glnAttribute.glnRecords[functionIndex]?.additionalOrganizationID">
                              {{
                              additionalIdentifier.organizationID+ ' ('+ additionalIdentifier.organizationID_Type+')'
                              }}<br /></strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="glnAttribute?.glnRecords[functionIndex]?.address" style="margin-top: 20px; padding-left: 0;" class="col-md-12 pt-spacer-5">
              <h3 style="margin-bottom: 0.5rem; margin-top: 2rem;">{{'Where_Is_This_Organisation' | translate}}</h3>
              <div class="row">
                <div class="col-md-12 structured-list striped scroll" style="padding-left: 0;">
                  <ng-container
                    *ngFor="let addressRecord of glnAttribute.glnRecords[functionIndex]?.address; let i = index">
                    <div class="postal-address">
                      <p class="color-blue" style="color: #002c6c; font-weight:400; font-size: 15.3px;">{{'Postal_Address' | translate}}</p>
                      <table class="company address">
                        <tbody>
                          <tr>
                            <td>{{'Postal_Name' | translate}}</td>
                            <td *ngIf="addressRecord?.postalName?.value else display_unknown">
                              <strong>{{ addressRecord?.postalName?.value}}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>{{'Post_Office_Box_Number' | translate}}</td>
                            <td *ngIf="addressRecord?.postOfficeBoxNumber else display_unknown">
                              <strong>{{ addressRecord?.postOfficeBoxNumber }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>{{'StreetAddress' | translate}}</td>
                            <td style="padding: 0px;" *ngIf="addressRecord else display_unknown">
                              <table style="margin:3px 0;">
                                <tr style="border-style: hidden;">
                                  <td style="color: #002c6c; padding: 0 9px;"><strong>{{
                                      addressRecord?.streetAddress?.value +
                                      (addressRecord?.streetAddress?.value ? ', ' : '') +
                                      addressRecord?.streetAddressLine2?.value +
                                      (addressRecord?.streetAddressLine2?.value ? ', ' : '')

                                      }}</strong></td>
                                </tr>
                                <tr style="border-style: hidden;" *ngIf="addressRecord && addressRecord?.streetAddressLine3">
                                  <td style="color: #002c6c; padding: 0 9px;"><strong>{{
                                      addressRecord?.streetAddressLine3?.value +
                                      (addressRecord?.streetAddressLine3?.value ? ', ' : '') +
                                      addressRecord?.streetAddressLine4?.value

                                      }}</strong></td>
                                </tr>
                              </table>
                            </td>

                          </tr>
                          <tr>
                            <td>{{'Cross_Street' | translate}}</td>
                            <td *ngIf="addressRecord?.crossStreet?.value else display_unknown"><strong>{{
                                addressRecord?.crossStreet?.value }}</strong></td>
                          </tr>
                          <tr>
                            <td>{{'Suburb' | translate}}</td>
                            <td *ngIf="addressRecord?.addressSuburb?.value else display_unknown"><strong>{{
                                addressRecord?.addressSuburb?.value}}</strong></td>
                          </tr>
                          <tr>
                            <td>{{'Address_Locality' | translate}}</td>
                            <td *ngIf="addressRecord?.addressLocality?.value else display_unknown"><strong>{{
                                addressRecord?.addressLocality?.value }}</strong></td>
                          </tr>
                          <tr>
                            <td>{{'Region' | translate}}</td>
                            <td *ngIf="addressRecord?.addressRegion?.value else display_unknown"><strong>{{
                                addressRecord?.addressRegion?.value }}</strong></td>
                          </tr>
                          <tr>
                            <td>{{'Postal_Code' | translate}}</td>
                            <td *ngIf="addressRecord?.postalCode else display_unknown"><strong>{{
                                addressRecord?.postalCode }}</strong></td>
                          </tr>
                          <tr>
                            <td>{{'Country_Code' | translate}}</td>
                            <td *ngIf="addressRecord?.countryCode else display_unknown"><strong>{{
                                addressRecord?.countryCode }}</strong></td>
                          </tr>
                          <tr>
                            <td>{{'Country_Subdivision_Code' | translate}}</td>
                            <td *ngIf="addressRecord?.countrySubdivisionCode else display_unknown"><strong>{{
                                addressRecord?.countrySubdivisionCode }}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;" *ngIf="glnAttribute.glnRecords[functionIndex]?.contactPoint"
              class="col-md-12 pt-spacer-5" style="padding-left:0 ;">
              <h3 style="margin-bottom: 0.3rem; margin-top: 2.5rem;">{{'How_to_get_in_contact' | translate}}</h3>
              <div class="row">
                <div class="col-md-12 structured-list striped scroll">
                  <table class="company">
                    <tbody>
                      <tr>
                        <td>{{'Email' | translate}}</td>
                        <td><a [attr.href]="'mailto:' + contactPoint.email" class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[functionIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                            {{contactPoint.email
                            ?
                            contactPoint.email : '' }}<br /></strong></a></td>
                      </tr>
                      <tr>
                        <td>{{'Telephone' | translate}}</td>
                        <td>
                          <strong
                            *ngFor="let contactPoint of glnAttribute.glnRecords[functionIndex]?.contactPoint">{{contactPoint.telephone
                            ? contactPoint.telephone : 'Unknown' | translate
                            }}<br /></strong> 
                        </td>
                      </tr>
                      <tr>
                        <td>{{'Website' | translate}}</td>
                        <td><a [attr.href]="contactPoint.website" target="_blank" class="detaillink"  *ngFor="let contactPoint of glnAttribute.glnRecords[functionIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                          {{contactPoint.website
                          ?
                          contactPoint.website : '' }}<br /></strong></a></td>
                      </tr>
                      <tr style="background: none;">
                        <td>{{'Available_language' | translate}}</td>
                        <div *ngIf="glnAttribute.glnRecords[functionIndex]?.uniqueLanguages.length>0 else display_unknown">
                          <div style="color: #002c6c; margin-left:8px"
                            *ngFor="let language of glnAttribute.glnRecords[functionIndex]?.uniqueLanguages">
                            <strong>{{ language }}</strong>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="margin-top: 3.5rem;" *ngIf="this.l2sdDisplay?.functionIndex">
              <div *ngIf="glnAttribute.linkset">
                <h3> {{ "L2SD_Links_to_other_information" | translate }}</h3>
                <app-glnl2sd [glnAttribute]=glnAttribute *ngIf="glnAttribute.linkset"></app-glnl2sd>
              </div>
            </div>
            <div class="col-md-12 pt-spacer-3">

            </div>

          </div>
          <div fxLayout="row" class="col-md-12"
            *ngIf="arrErrorMessages.length==0 && glnAttribute && glnAttribute?.glnRecords[functionIndex]?.dateUpdated">
            <p class="info-data">
              {{'This_data_has_been_provided_by' | translate}} {{glnAttribute?.gs1Licence.licenseeName  === 'Licensee name withheld for privacy reasons' ? 
              ('licensee_name_withheld' | translate) : glnAttribute?.gs1Licence.licenseeName}},
              {{'and_was_last_updated_on' | translate}}
              {{glnAttribute?.glnRecords[functionIndex]?.dateUpdated | date: 'd MMM yyyy'}}.
            </p>
          </div>
        </div>
      </div>




    </div>


    <div *ngIf="fixedPhysicalIndex!=-1" class="card">
      <div *ngIf="glnAttribute.glnRecords.length > 1" class="card-header" [style.cursor]="glnAttribute.glnRecords.length > 1 ? 'pointer' : 'default'">
        <div class="collapsed card-header-info" data-toggle="collapse" [ngClass]="glnAttribute.glnRecords.length > 1 ? '' : 'accordion-control'"
          onclick="var content = document.getElementById('fixed-physical-location'); content.classList.contains('collapse') ? content.classList.remove('collapse') : content.classList.add('collapse'); this.classList.contains('collapsed')? this.classList.remove('collapsed') : this.classList.add('collapsed')"
          data-target="#fixed-physical-location" aria-expanded="false" aria-controls="fixed-physical-location">
          <h5>{{'FIXED_PHYSICAL_LOCATION' | translate}}</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-chevron-down color-blue" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
        </div>
      </div>
      <div id="fixed-physical-location" [ngClass]="glnAttribute.glnRecords.length > 1 ? 'collapse' : ''" class="" data-parent="#accordion">
        <div style="margin-left: 10px;" class="row">
          <div class="col-md-12">
            <p style="color: #002c6c;" 
            [ngStyle]="{'font-size': glnAttribute.glnRecords.length > 1 ? '1.125rem' : '2rem', 'font-weight':glnAttribute.glnRecords.length > 1 ? '400' :'300'}"
            class="color-blue"
            [ngClass]="glnAttribute.glnRecords.length > 1 ? 'no-m' : 'mb-2'">
              {{glnAttribute.glnRecords[fixedPhysicalIndex]?.physicalLocationName[0]?.value}}
            </p>
          </div>
          <div class="col-md-12">
              <p *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.glnRecordStatus?.toString().toLowerCase() !== 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Fixed_Physical_Location_Is' | translate}} {{
                glnAttribute.glnRecords[fixedPhysicalIndex]?.glnRecordStatus?.toString().toLowerCase() }}</p>
                <p *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.glnRecordStatus?.toString().toLowerCase() === 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Fixed_Physical_Location_Is' | translate}} {{
                  'Discontinued_Status_not_operational' | translate }}</p>
          </div>
          <div class="col-md-12">
            <div class="content-module-company">
              <div class="row">
                <div class="col-md-12 structured-list striped scroll">
                  <table class="company">
                    <tbody>
                      <tr>
                        <td>{{'Gln_Information_Short' | translate}}</td>
                        <td><strong>{{glnAttribute.glnRecords[fixedPhysicalIndex]?.gln}}</strong></td>
                      </tr>
                      <tr>
                        <td>
                          {{'GLN_Type' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Designation_Of_What_A_GLN_Is_Being_Used_To_Identify' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>

                        </td>
                        <td><strong>{{'FIXED_PHYSICAL_LOCATION' | translate}}</strong></td>
                      </tr>
                      <tr>
                        <td>{{'Name' | translate}}</td>
                        <td>
                          <table style="margin: 0;">
                            <tr style="border-style: hidden;"
                              *ngFor=" let fplName of glnAttribute.glnRecords[fixedPhysicalIndex]?.physicalLocationName">
                              <td style="color: #002c6c; padding: 0;"><strong>({{fplName?.language+ ') ' + fplName?.value}}</strong>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Location_Roles' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Role_And_or_Purpose' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.locationRole else display_unknown" >
                          <strong>{{
                            glnAttribute.glnRecords[fixedPhysicalIndex]?.locationRole.join(', ')
                            }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Contained_In_Place' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Larger_Physical_Location_A_Sub-location_Is_Located_Within' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>

                        <td *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.containedInPlace else display_unknown">
                          <strong>{{
                            glnAttribute.glnRecords[fixedPhysicalIndex]?.containedInPlace.gln + ' (' +
                            (glnAttribute.glnRecords[fixedPhysicalIndex]?.containedInPlace.glnType | translate) + ')'
                            }}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Related_Organisations' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'The_Organisation_Associated_With_A_Place' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td
                          *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.relatedOrganization else display_unknown">
                          <strong
                            *ngFor="let relatedOrgs of glnAttribute.glnRecords[fixedPhysicalIndex]?.relatedOrganization">{{
                            relatedOrgs.gln+ ' ('+(relatedOrgs.glnType | translate)+')'
                            }}<br /></strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Additional_Identifiers' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Identifiers_Assigned_And_Managed_By_Government_Bodies' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.additionalLocationID else display_unknown">
                          <strong
                            *ngFor="let additionalIdentifier of glnAttribute.glnRecords[fixedPhysicalIndex]?.additionalLocationID">{{
                            additionalIdentifier.locationID+ ' ('+ additionalIdentifier.locationID_Type+')'
                            }}<br /></strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div *ngIf="glnAttribute?.glnRecords[fixedPhysicalIndex]?.address || glnAttribute.glnRecords[fixedPhysicalIndex]?.geoCoordinates" style="margin-top: 20px;" class="col-md-12 pt-spacer-5">
            <h3 style="margin-bottom: 0.5rem; margin-top: 2rem;">{{'Where_Is_This_Organisation' | translate}}</h3>
            <div class="row">
              <div class="col-md-12 structured-list striped scroll" style="padding-left: 0;">
                <ng-container
                  *ngFor="let addressRecord of glnAttribute.glnRecords[fixedPhysicalIndex]?.address; let i = index">
                  <div class="postal-address">
                    <p class="color-blue" style="color: #002c6c; font-weight:400; font-size: 15.3px;">{{'Postal_Address' | translate}}</p>
                    <table class="company address">
                      <tbody>
                        <tr>
                          <td>{{'Postal_Name' | translate}}</td>
                          <td *ngIf="addressRecord?.postalName?.value else display_unknown">
                            <strong>{{ addressRecord?.postalName?.value}}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>{{'Post_Office_Box_Number' | translate}}</td>
                          <td *ngIf="addressRecord?.postOfficeBoxNumber else display_unknown">
                            <strong>{{ addressRecord?.postOfficeBoxNumber }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>{{'StreetAddress' | translate}}</td>
                          <td style="padding: 0px;" *ngIf="addressRecord else display_unknown">
                            <table style="margin:3px 0;">
                              <tr style="border-style: hidden;">
                                <td style="color: #002c6c; padding: 0 9px;"><strong>{{
                                    addressRecord?.streetAddress?.value +
                                    (addressRecord?.streetAddress?.value ? ', ' : '') +
                                    addressRecord?.streetAddressLine2?.value +
                                    (addressRecord?.streetAddressLine2?.value ? ', ' : '')

                                    }}</strong></td>
                              </tr>
                              <tr style="border-style: hidden;" *ngIf="addressRecord && addressRecord?.streetAddressLine3">
                                <td style="color: #002c6c; padding: 0 9px;"><strong>{{
                                    addressRecord?.streetAddressLine3?.value +
                                    (addressRecord?.streetAddressLine3?.value ? ', ' : '') +
                                    addressRecord?.streetAddressLine4?.value

                                    }}</strong></td>
                              </tr>
                            </table>
                          </td>

                        </tr>
                        <tr>
                          <td>{{'Cross_Street' | translate}}</td>
                          <td *ngIf="addressRecord?.crossStreet?.value else display_unknown"><strong>{{
                              addressRecord?.crossStreet?.value }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Suburb' | translate}}</td>
                          <td *ngIf="addressRecord?.addressSuburb?.value else display_unknown"><strong>{{
                              addressRecord?.addressSuburb?.value}}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Address_Locality' | translate}}</td>
                          <td *ngIf="addressRecord?.addressLocality?.value else display_unknown"><strong>{{
                              addressRecord?.addressLocality?.value }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Region' | translate}}</td>
                          <td *ngIf="addressRecord?.addressRegion?.value else display_unknown"><strong>{{
                              addressRecord?.addressRegion?.value }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Postal_Code' | translate}}</td>
                          <td *ngIf="addressRecord?.postalCode else display_unknown"><strong>{{
                              addressRecord?.postalCode }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Country_Code' | translate}}</td>
                          <td *ngIf="addressRecord?.countryCode else display_unknown"><strong>{{
                              addressRecord?.countryCode }}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Country_Subdivision_Code' | translate}}</td>
                          <td *ngIf="addressRecord?.countrySubdivisionCode else display_unknown"><strong>{{
                              addressRecord?.countrySubdivisionCode }}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
          <br />
          <div *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.geoShape" class="col-md-12 pt-spacer-5">
            <div class="row">
              <div class="col-md-12 structured-list striped scroll" style="padding-left: 0;">
                <div class="postal-address">
                  <table class="company address">
                    <tbody>
                      <ng-container *ngFor="let shape of glnAttribute.glnRecords[fixedPhysicalIndex]?.geoShape">
                        <tr style="background: none;" *ngFor="let key of shape | keys">

                          <td style="padding-left: 0px;">
                            <table>
                              <tr>
                                <td style="padding-left: 0;">
                                  <p style="color: #002c6c; font-weight:400;  font-size: 15.3px;">{{'Geoshape' | translate}}</p>
                                </td>
                              </tr>
                              <tr style="border-style: hidden;">
                                <td>{{ 'Type' |translate }} </td>
                                <td>{{ key |translate }}</td>
                              </tr>
                              <tr style="border-style: hidden;">
                                <td><strong>{{ 'Coordinates' |translate }}</strong></td>
                                <td><strong>{{ shape[key] }}</strong></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.geoCoordinates" [style.margin-top]="glnAttribute?.glnRecords[fixedPhysicalIndex]?.address ? '20px' : '0'" class="col-md-12 pt-spacer-5">
            <div class="row">
              <div class="col-md-12 structured-list striped scroll" style="padding-left: 0;">
                <div class="postal-address">
                  <p style="color: #002c6c; font-weight:400;  font-size: 15.3px;">{{'Geocoordinates' | translate}}</p>
                  <table class="company address">
                    <tbody>
                      <ng-container
                        *ngFor="let geoCoordinateName of this.getObjectKeys( glnAttribute.glnRecords[fixedPhysicalIndex]?.geoCoordinates)">
                        <tr>
                          <td>{{ geoCoordinateName |translate }}</td>
                          <td>{{ glnAttribute.glnRecords[fixedPhysicalIndex]?.geoCoordinates[geoCoordinateName] }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div style="margin-top: 20px;" *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.contactPoint"
            class="col-md-12 pt-spacer-5">
            <h3 style="margin-bottom: 0.3rem; margin-top: 2.5rem;">{{'How_to_get_in_contact' | translate}}</h3>
            <div class="row">
              <div class="col-md-12 structured-list striped scroll">
                <table class="company">
                  <tbody>
                    <tr>
                      <td>{{'Email' | translate}}</td>
                      <td><a [attr.href]="'mailto:' + contactPoint.email" class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[fixedPhysicalIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                        {{contactPoint.email
                        ?
                        contactPoint.email : '' }}<br /></strong></a></td>
                    </tr>
                    <tr>
                      <td>{{'Telephone' | translate}}</td>
                      <td>
                        <strong
                          *ngFor="let contactPoint of glnAttribute.glnRecords[fixedPhysicalIndex]?.contactPoint">{{contactPoint.telephone
                          ? contactPoint.telephone : 'Unknown' | translate
                          }}<br /></strong> 
                      </td>
                    </tr>
                    <tr>
                      <td>{{'Website' | translate}}</td>
                      <td><a [attr.href]="contactPoint.website" target="_blank" class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[fixedPhysicalIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                        {{contactPoint.website
                        ?
                        contactPoint.website : '' }}<br /></strong></a></td>
                    </tr>
                    <tr style="background: none;">
                      <td>{{'Available_language' | translate}}</td>
                      <div *ngIf="glnAttribute.glnRecords[fixedPhysicalIndex]?.uniqueLanguages.length>0 else display_unknown">
                        <div style="color: #002c6c; margin-left:8px"
                          *ngFor="let language of glnAttribute.glnRecords[fixedPhysicalIndex]?.uniqueLanguages">
                          <strong>{{ language }}</strong>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-spacer-3">
            <p class="info-data">
            </p>
          </div>
          <div style="margin-top: 3.5rem;" *ngIf="this.l2sdDisplay?.fixedPhysicalIndex">
            <div *ngIf="glnAttribute.linkset">
              <h3> {{ "L2SD_Links_to_other_information" | translate }}</h3>
              <app-glnl2sd [glnAttribute]=glnAttribute *ngIf="glnAttribute.linkset"></app-glnl2sd>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="col-md-12"
          *ngIf="arrErrorMessages.length==0 && glnAttribute && glnAttribute?.glnRecords[fixedPhysicalIndex]?.dateUpdated">
          <p class="info-data" style="padding-left:12px; margin-left:10px;">
            {{'This_data_has_been_provided_by' | translate}} {{glnAttribute?.gs1Licence.licenseeName === 'Licensee name withheld for privacy reasons' ? 
            ('licensee_name_withheld' | translate) : glnAttribute?.gs1Licence.licenseeName}},
            {{'and_was_last_updated_on' | translate}}
            {{glnAttribute?.glnRecords[fixedPhysicalIndex]?.dateUpdated | date: 'd MMM yyyy'}}.
          </p>
        </div>
        <div class="accordion-bar bg-orange"></div>
      </div>
    </div>



    <div *ngIf="digitalIndex!=-1" class="card">
      <div *ngIf="glnAttribute.glnRecords.length > 1" class="card-header" [style.cursor]="glnAttribute.glnRecords.length > 1 ? 'pointer' : 'default'">
        <div class="collapsed card-header-info" [ngClass]="glnAttribute.glnRecords.length > 1 ? '' : 'accordion-control'"
          onclick="var content = document.getElementById('digital-location'); content.classList.contains('collapse') ? content.classList.remove('collapse') : content.classList.add('collapse'); this.classList.contains('collapsed')? this.classList.remove('collapsed') : this.classList.add('collapsed')"
          data-toggle="collapse" data-target="#digital-location" aria-expanded="false" aria-controls="digital-location">
          <h5>{{'DIGITAL_LOCATION' | translate}}</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-chevron-down color-blue" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
        </div>
      </div>
      <div id="digital-location" [ngClass]="glnAttribute.glnRecords.length > 1 ? 'collapse' : ''" class="" data-parent="#accordion">
        <div style="margin-left: 10px;" class=" row">
          <div class="col-md-12">
            <p style="color: #002c6c;" 
            [ngStyle]="{'font-size': glnAttribute.glnRecords.length > 1 ? '1.125rem' : '2rem', 'font-weight':glnAttribute.glnRecords.length > 1 ? '400' :'300'}"
            class="color-blue"
            [ngClass]="glnAttribute.glnRecords.length > 1 ? 'no-m' : 'mb-2'">
              {{glnAttribute.glnRecords[digitalIndex]?.digitalLocationName[0]?.value}}</p>
          </div>
          <div class="col-md-12">
              <p *ngIf="glnAttribute.glnRecords[digitalIndex]?.glnRecordStatus?.toString().toLowerCase() !== 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Digital_Location_Is' | translate}} {{
                glnAttribute.glnRecords[digitalIndex]?.glnRecordStatus?.toString().toLowerCase() }}</p>
                <p *ngIf="glnAttribute.glnRecords[digitalIndex]?.glnRecordStatus?.toString().toLowerCase() === 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Digital_Location_Is' | translate}} {{
                  'Discontinued_Status_not_operational' | translate }}</p>
          </div>
          <div class="col-md-12">
            <div class="content-module-company">
              <div class="row">
                <div class="col-md-12 structured-list striped scroll">
                  <table class="company">
                    <tbody>
                      <tr>
                        <td>{{'Gln_Information_Short' | translate}}</td>
                        <td><strong>{{glnAttribute.glnRecords[digitalIndex]?.gln}}</strong></td>
                      </tr>
                      <tr>
                        <td>
                          {{'GLN_Type' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Designation_Of_What_A_GLN_Is_Being_Used_To_Identify' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>
                        </td>
                        <td><strong>{{'DIGITAL_LOCATION' | translate}}</strong></td>
                      </tr>
                      <tr>
                        <td>{{'Name' | translate}}</td>
                        <td>
                          <table style="margin: 0;">
                            <tr style="border-style: hidden;"
                              *ngFor=" let digitalEntityName of glnAttribute.glnRecords[digitalIndex]?.digitalLocationName">
                              <td style="color: #002c6c; padding: 0;"><strong>({{digitalEntityName?.language+ ') ' +
                                  digitalEntityName?.value}}</strong></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Location_Roles' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Role_And_or_Purpose' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>

                        </td>
                        <td *ngIf="glnAttribute.glnRecords[digitalIndex]?.locationRole else display_unknown ">
                          <strong>{{glnAttribute.glnRecords[digitalIndex]?.locationRole.join(', ')
                            }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Related_Organisations' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'The_Organisation_Associated_With_A_Place' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>

                        </td>
                        <td *ngIf="glnAttribute.glnRecords[digitalIndex]?.relatedOrganization else display_unknown">
                          <strong
                            *ngFor="let relatedOrgs of glnAttribute.glnRecords[digitalIndex]?.relatedOrganization">{{
                            relatedOrgs.gln+ ' ('+ (relatedOrgs.glnType | translate)+')'
                            }}<br /></strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{'Additional_Identifiers' | translate}}
                          <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                            matTooltip="{{'Identifiers_Assigned_And_Managed_By_Government_Bodies' | translate}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                              class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                              </path>
                            </svg>

                          </span>

                        </td>
                        <td *ngIf="glnAttribute.glnRecords[digitalIndex]?.additionalLocationID else display_unknown">
                          <strong
                            *ngFor="let additionalIdentifier of glnAttribute.glnRecords[digitalIndex]?.additionalLocationID">
                            {{
                            additionalIdentifier.locationID+ ' ('+ additionalIdentifier.locationID_Type+')'
                            }}<br /></strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="glnAttribute?.glnRecords[digitalIndex]?.digitalAddress" style="margin-top: 20px;" class="col-md-12 pt-spacer-5">
            <h3 style="margin-bottom: 0.5rem; margin-top: 2rem;">{{'Where_Is_This_Organisation' | translate}}</h3>
            <div class="row">
              <div class="col-md-12 structured-list striped scroll" style="padding-left: 0;">
                <div class="digital-address">
                  <p class="color-blue" style="color: #002c6c; font-weight:400; font-size: 1.125rem;">{{'Digital_Address' | translate}}</p>
                  <table class="company address">
                    <tbody>
                      <tr>
                        <td>{{'Digital_Address' | translate}}</td>
                        <td *ngIf="glnAttribute.glnRecords[digitalIndex]?.digitalAddress else display_unknown">
                          <strong>{{
                            glnAttribute.glnRecords[digitalIndex]?.digitalAddress }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px;" *ngIf="glnAttribute.glnRecords[digitalIndex]?.contactPoint"
            class="col-md-12 pt-spacer-5">
            <h3 style="margin-bottom: 0.3rem; margin-top: 2.5rem;">{{'How_to_get_in_contact' | translate}}</h3>
            <div class="row">
              <div class="col-md-12 structured-list striped scroll">
                <table class="company">
                  <tbody>
                    <tr>
                      <td>{{'Email' | translate}}</td>
                      <td><a [attr.href]="'mailto:' + contactPoint.email"  class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[digitalIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                        {{contactPoint.email
                        ?
                        contactPoint.email : '' }}<br /></strong></a></td>
                    </tr>
                    <tr>
                      <td>{{'Telephone' | translate}}</td>
                      <td>
                        <strong
                          *ngFor="let contactPoint of glnAttribute.glnRecords[digitalIndex]?.contactPoint">{{contactPoint.telephone
                          ? contactPoint.telephone : 'Unknown' | translate
                          }}<br /></strong>
                      </td>
                    </tr>
                    <tr>
                      <td>{{'CI_Website' | translate}}</td>
                      <td><a [attr.href]="contactPoint.website" target="_blank" class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[digitalIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                        {{contactPoint.website
                        ?
                        contactPoint.website : '' }}<br /></strong></a></td>
                    </tr>
                    <tr style="background: none;">
                      <td>{{'Available_language' | translate}}</td>
                      <div *ngIf="glnAttribute.glnRecords[digitalIndex]?.uniqueLanguages.length>0 else display_unknown">
                        <div style="color: #002c6c; margin-left:8px"
                          *ngFor="let language of glnAttribute.glnRecords[digitalIndex]?.uniqueLanguages">
                          <strong>{{ language }}</strong>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-spacer-3">

          </div>
        </div>
        <div style="margin-top: 3.5rem;" *ngIf="this.l2sdDisplay?.digitalIndex">
          <div *ngIf="glnAttribute.linkset">
            <h3> {{ "L2SD_Links_to_other_information" | translate }}</h3>
            <app-glnl2sd [glnAttribute]=glnAttribute *ngIf="glnAttribute.linkset"></app-glnl2sd>
          </div>
        </div>
        <div fxLayout="row" class="col-md-12"
          *ngIf="arrErrorMessages.length==0 && glnAttribute && glnAttribute?.glnRecords[digitalIndex]?.dateUpdated">
          <p class="info-data" style="padding-left:12px; margin-left:10px;">
            {{'This_data_has_been_provided_by' | translate}} {{glnAttribute?.gs1Licence.licenseeName  === 'Licensee name withheld for privacy reasons' ? 
            ('licensee_name_withheld' | translate) : glnAttribute?.gs1Licence.licenseeName}},
            {{'and_was_last_updated_on' | translate}}
            {{glnAttribute?.glnRecords[digitalIndex]?.dateUpdated | date: 'd MMM yyyy'}}.
          </p>
        </div>
        <div class="accordion-bar bg-orange"></div>
      </div>
    </div>



    <div *ngIf="mobileIndex!=-1" class="card">
      <div *ngIf="glnAttribute.glnRecords.length > 1" class="card-header" [style.cursor]="glnAttribute.glnRecords.length > 1 ? 'pointer' : 'default'">
        <div class="collapsed card-header-info" [ngClass]="glnAttribute.glnRecords.length > 1 ? '' : 'accordion-control'"
          onclick="var content = document.getElementById('mobile'); content.classList.contains('collapse') ? content.classList.remove('collapse') : content.classList.add('collapse'); this.classList.contains('collapsed')? this.classList.remove('collapsed') : this.classList.add('collapsed')"
          data-toggle="collapse" data-target="#digital-location" aria-expanded="false" aria-controls="digital-location">
          <h5>{{'MOBILE_PHYSICAL_LOCATION' | translate}}</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-chevron-down color-blue" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
        </div>
      </div>
      <div id="mobile" [ngClass]="glnAttribute.glnRecords.length > 1 ? 'collapse' : ''" class="" data-parent="#accordion">
        <div style="margin-left: 10px;" class="row">
          <div class="col-md-12">
            <p style="color: #002c6c;" 
            [ngStyle]="{'font-size': glnAttribute.glnRecords.length > 1 ? '1.125rem' : '2rem', 'font-weight':glnAttribute.glnRecords.length > 1 ? '400' :'300'}"
            class="color-blue"
            [ngClass]="glnAttribute.glnRecords.length > 1 ? 'no-m' : 'mb-2'">
              {{glnAttribute.glnRecords[mobileIndex]?.physicalLocationName[0]?.value}}</p>
          </div>


          <div class="row mb-spacer-2">
            <div class="col-md-12">
                <p *ngIf="glnAttribute.glnRecords[mobileIndex]?.glnRecordStatus?.toString().toLowerCase() !== 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Mobile_Physical_Location_Is' | translate}} {{
                  glnAttribute.glnRecords[mobileIndex]?.glnRecordStatus?.toString().toLowerCase() }}</p>
                  <p *ngIf="glnAttribute.glnRecords[mobileIndex]?.glnRecordStatus?.toString().toLowerCase() === 'discontinued'" style="font-size: 1rem; color:#696d73; font-weight: 400;"  class="color-ui-6 body-sm">{{'This_Mobile_Physical_Location_Is' | translate}} {{
                    'Discontinued_Status_not_operational' | translate }}</p>
            </div>
            <div class="col-md-12">
              <div class="content-module-company">
                <div class="row">
                  <div class="col-md-12 structured-list striped scroll">
                    <table class="company">
                      <tbody>
                        <tr>
                          <td>{{'Gln_Information_Short' | translate}}</td>
                          <td><strong>{{glnAttribute.glnRecords[mobileIndex]?.gln}}</strong></td>
                        </tr>
                        <tr>
                          <td>
                            {{'GLN_Type' | translate}}
                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Designation_Of_What_A_GLN_Is_Being_Used_To_Identify' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>

                          </td>
                          <td><strong>{{'MOBILE_PHYSICAL_LOCATION' | translate}}</strong></td>
                        </tr>
                        <tr>
                          <td>{{'Name' | translate}}</td>
                          <td>
                            <table style="margin: 0;">
                              <tr style="border-style: hidden;"
                                *ngFor=" let mobileEntityName of glnAttribute.glnRecords[mobileIndex]?.physicalLocationName">
                                <td style="color: #002c6c; padding: 0;"><strong>({{mobileEntityName?.language+ ') ' +
                                    mobileEntityName?.value}}</strong></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{'Location_Roles' | translate}}

                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Role_And_or_Purpose' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>
                          </td>
                          <td *ngIf="glnAttribute.glnRecords[mobileIndex]?.locationRole else display_unknown">
                            <strong>{{
                              glnAttribute.glnRecords[mobileIndex]?.locationRole.join(', ')
                              }}</strong>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {{'Related_Organisations' | translate}}

                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'The_Organisation_Associated_With_A_Place' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>
                          </td>
                          <td *ngIf="glnAttribute.glnRecords[mobileIndex]?.relatedOrganization else display_unknown ">
                            <strong
                              *ngFor="let relatedOrgs of glnAttribute.glnRecords[mobileIndex]?.relatedOrganization">{{
                              relatedOrgs.gln+ ' ('+ (relatedOrgs.glnType | translate)+')'
                              }}<br /></strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{'Additional_Identifiers' | translate}}

                            <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                              matTooltip="{{'Identifiers_Assigned_And_Managed_By_Government_Bodies' | translate}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                                class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path
                                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                                </path>
                              </svg>

                            </span>
                          </td>
                          <td *ngIf="glnAttribute.glnRecords[mobileIndex]?.additionalLocationID  else display_unknown ">
                            <strong
                              *ngFor="let additionalIdentifier of glnAttribute.glnRecords[mobileIndex]?.additionalLocationID">
                              {{
                              additionalIdentifier.locationID+ ' ('+ additionalIdentifier.locationID_Type+')'
                              }}<br /></strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="glnAttribute.glnRecords[mobileIndex]?.baseLocation" style="margin-top: 20px;"
              class="col-md-12 pt-spacer-5">
              <h3 style="margin-bottom: 0.5rem; margin-top: 2rem;">{{'Where_Is_This_Organisation' | translate}}</h3>
              <div class="row">
                  <p class="color-blue" style="color: #002c6c; font-weight: 400; font-size: 1.125rem; padding-left: 0;">{{'Base_Location' |
                    translate}}</p>
                <div class="col-md-12 structured-list striped scroll">
                  
                  <table class="company">
                    <tbody>
                      <tr>
                        <td>
                          <span style="font-size: 12px;"
                            *ngIf="glnAttribute.glnRecords[mobileIndex]?.baseLocation?.glnType">{{glnAttribute.glnRecords[mobileIndex]?.baseLocation?.glnType
                            | translate}}
                            (GLN)</span>


                          <!-- <span tabindex="0" class="gln-tooltip" data-toggle="tooltip" data-placement="right" title=""
                          matTooltip="The base location associated with a place.">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c6c"
                            class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                            <path
                              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                            </path>
                          </svg>

                        </span> -->
                        </td>
                        <td *ngIf="glnAttribute.glnRecords[mobileIndex]?.baseLocation else display_unknown">
                          <strong>{{glnAttribute.glnRecords[mobileIndex]?.baseLocation.gln
                            }}</strong>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;" *ngIf="glnAttribute.glnRecords[mobileIndex]?.contactPoint"
              class="col-md-12 pt-spacer-5">
              <h3 style="margin-bottom: 0.3rem; margin-top: 2.5rem;">{{'How_to_get_in_contact' | translate}}</h3>
              <div class="row">
                <div class="col-md-12 structured-list striped scroll">
                  <table class="company">
                    <tbody>
                      <tr>
                        <td>{{'Email' | translate}}</td>
                        <td><a [attr.href]="'mailto:' + contactPoint.email" class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[mobileIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                          {{contactPoint.email
                          ?
                          contactPoint.email : '' }}<br /></strong></a></td>
                       
                      </tr>
                      <tr>
                        <td>{{'Telephone' | translate}}</td>
                        <td>
                          <strong
                            *ngFor="let contactPoint of glnAttribute.glnRecords[mobileIndex]?.contactPoint">{{contactPoint.telephone
                            ? contactPoint.telephone : 'Unknown' | translate
                            }}<br /></strong>
                        </td>
                      </tr>
                      <tr>
                        <td>{{'CI_Website' | translate}}</td>
                        <td><a [attr.href]="contactPoint.website" target="_blank" class="detaillink" *ngFor="let contactPoint of glnAttribute.glnRecords[mobileIndex]?.contactPoint"><strong style="font-weight: normal; color: #00799e; font-size: 12px;">
                          {{contactPoint.website
                          ?
                          contactPoint.website : '' }}<br /></strong></a></td>
                      </tr>
                      <tr style="background: none;">
                        <td>{{'Available_language' | translate}}</td>
                        <div *ngIf="glnAttribute.glnRecords[mobileIndex]?.uniqueLanguages.length>0 else display_unknown">
                          <div style="color: #002c6c; margin-left:8px"
                            *ngFor="let language of glnAttribute.glnRecords[mobileIndex]?.uniqueLanguages">
                            <strong>{{ language }}</strong>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="margin-top: 3.5rem;" *ngIf="this.l2sdDisplay?.mobileIndex">
              <div *ngIf="glnAttribute.linkset">
                <h3> {{ "L2SD_Links_to_other_information" | translate }}</h3>
                <app-glnl2sd [glnAttribute]=glnAttribute *ngIf="glnAttribute.linkset"></app-glnl2sd>
              </div>
            </div>
            <div class="col-md-12 pt-spacer-3">
              <br /><br />
            </div>


          </div>
        </div>
        <div fxLayout="row" class="col-md-12"
          *ngIf="arrErrorMessages.length==0 && glnAttribute && glnAttribute?.glnRecords[mobileIndex]?.dateUpdated">
          <p class="info-data" style="padding-left:12px; margin-left:10px;">
            {{'This_data_has_been_provided_by' | translate}} {{glnAttribute?.gs1Licence.licenseeName === 'Licensee name withheld for privacy reasons' ? 
            ('licensee_name_withheld' | translate) : glnAttribute?.gs1Licence.licenseeName}},
            {{'and_was_last_updated_on' | translate}}
            {{glnAttribute?.glnRecords[mobileIndex]?.dateUpdated | date: 'd MMM yyyy'}}.
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="glnAttribute.linkset && this.l2sdDisplay.legalEntityIndex != true && this.l2sdDisplay.fixedPhysicalIndex != true && this.l2sdDisplay.functionIndex != true && this.l2sdDisplay.mobileIndex != true && this.l2sdDisplay.digitalIndex != true" class="card">
      <div class="card-header" [style.margin-top]="(glnAttribute.glnRecords && glnAttribute.glnRecords.length > 1) ? '0' : '30px'" [style.cursor]="(glnAttribute.glnRecords && glnAttribute.glnRecords.length > 1) ? 'pointer' : 'default'">
        <div class="collapsed card-header-info" [ngClass]="(glnAttribute.glnRecords && glnAttribute.glnRecords.length > 1) ? '' : 'accordion-control'"
          onclick="var content = document.getElementById('l2sd'); content.classList.contains('collapse') ? content.classList.remove('collapse') : content.classList.add('collapse'); this.classList.contains('collapsed')? this.classList.remove('collapsed') : this.classList.add('collapsed')"
          data-toggle="collapse" data-target="#gln-l2sd" aria-expanded="false" aria-controls="digital-location">
          <h5> {{ "L2SD_Links_to_other_information" | translate }}</h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-chevron-down color-blue" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
        </div>
      </div>
      <div id="l2sd" [ngClass]="(glnAttribute.glnRecords && glnAttribute.glnRecords.length > 1) ? 'collapse' : ''" class="" data-parent="#accordion">
        <div class="card-body">
          <div class="row mb-spacer-2">
            <div class="col-md-12">
              <div class="content-module-company">
                <div class="row">
                  <div class="col-md-12 structured-list striped scroll">
                    <table class="company">
                      <tbody>
                        <div *ngIf="glnAttribute.linkset">
                          <div>
                            <app-glnl2sd [glnAttribute]=glnAttribute *ngIf="glnAttribute.linkset"></app-glnl2sd>
                          </div>
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #display_unknown>
      <td style="color: #696d73;">
        {{'Unknown' | translate}}
      </td>
    </ng-template>
  </div>