import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-auth-callback',
  template: `<div></div>`
})
export class AuthCallbackComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.finishLogin(); 
  }

}
