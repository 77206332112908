import { GoogleAnalyticsService } from './../services/googleanalytics.service';
import { SharedService } from './../services/shared.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/services/loader.service';
import { GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  obs: any;
  gtin: string;
  user_name:any;
  user_type:any;
  searchText:string;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  hideLoader = false;  

  constructor(private router: Router, private serv : SharedService,public authService: AuthService,
    public loaderService: LoaderService, public googleAnalyticsService: GoogleAnalyticsService) {
    this.addGoogleAnalysticTracking();
  }
  
  ngOnInit(): void {

    this.router.events.subscribe(evt => {
      if (evt instanceof GuardsCheckEnd) {
          if(evt.shouldActivate == true  && evt.url.includes('home')){
          this.hideLoader = true;
        }
      }

      if (evt instanceof NavigationEnd) {
        this.googleAnalyticsService.pageViewEmitter('page_view', evt.urlAfterRedirects);
      }
    });

      window.addEventListener('storage', (event) => {
          if (event.storageArea == localStorage) {
               let token = localStorage.getItem('CRMUserProfile');
               console.log(token);
               if(token == undefined || token == null) {
                this.authService.logout();
               }
          }
      });
    // this.router.events.subscribe(evt => {
    //   if (evt instanceof GuardsCheckEnd) {
    //       if(evt.shouldActivate == true){
    //       this.hideLoader = true;
    //     }
    //   }

    //   if (evt instanceof NavigationEnd) {
    //     this.googleAnalyticsService.pageViewEmitter('page_view', evt.urlAfterRedirects);
    //   }
    // });

  }
  OnSearchClick(text) {
    this.searchText = text;
  }

  logout() {
    this.authService.logout();
  }

  public searchGtin() {

    var arr = this.gtin.split(',').map(function(item) {
      return item;
    });


    this.serv.getGtins(arr).subscribe(res => {

      this.obs = res;

    });

  }

  public searchGln() {

    var arr = this.gtin.split(',').map(function(item) {
      return item;
    });


    this.serv.getGlns(arr).subscribe(res => {

      this.obs = res;

    });

  }
  private addGoogleAnalysticTracking()
  {
        if (environment.gaTrackingId) {
        // register google tag manager
        const gTagManagerScript = document.createElement('script');
        gTagManagerScript.async = true;
        gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
        document.head.appendChild(gTagManagerScript);

        // register google analytics
        const gaScript = document.createElement('script');
        gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${environment.gaTrackingId}');
        `;
        document.head.appendChild(gaScript);
      }
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 220px from the top of the document, show the button
  scrollFunction() {
    if (document.documentElement.scrollTop > 220) {
      document.getElementById("btnScrollTop").style.display = "block";
    } else {
      document.getElementById("btnScrollTop").style.display = "none";
    }
  }

  onClickOnTop() {
    let el = document.getElementById('app-header');
    el.scrollIntoView();
  }

  title = 'Verified by GS1';
}
