<div class="bg-ui-1">

  <div>
    <app-header id="app-header" [displayBanner]="true" selectedTab="support">
    </app-header>
    <div class="container">
      <h1 class="fw-normal pt-4 pb-4 title">
        {{ 'Support' | translate }}
      </h1>
      <mat-card class="support-card">
        <h4 class="text-muted title-text">
          {{ 'Getting_Started_with_Verified_by_GS1' | translate }} ?
        </h4>

        <div>
          <p class="text-muted subtitle-text">
            {{ 'Use_the_resources_below_to_help_you_search_and_find_the_right_information' | translate }}.
          </p>
          <br />
          <mat-grid-list cols="4" rowHeight="1:1">
            <mat-grid-tile
              style="box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);">
              <mat-card class="bluetop" style="height:100%; border-top:7px solid #002c6c; border-radius:2px">
                <div class="second_card">
                  <a style="color:#002c6c" href="javascript:void(0);" (click)="clickSupportGuide()">
                    <div class="text-center">
                      <img mat-card-image src="../../../assets/img/quickstart.png" alt="Activate Quickstart" />
                    </div>
                    <div class="pt-2 pb-3" style="font-weight: 500; margin-left: -5px;">{{ 'start_guide' | translate }}
                      ></div>
                  </a>
                </div>
                <hr style="border-color: #666363; margin: 0 -5px;" />
                <div class="pa-2">
                  <p class="des" style="word-break:break-word; color:#888b8d; margin-left:-5px;">
                    {{ 'Visual_guide_to_help_you_take_your_first_steps' | translate }}
                  </p>
                </div>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <h3 style="font-size: 24px; font-weight: 400; margin-top: 2.5rem;" class="text-muted">{{
          'Issues_using_Verified_by_GS1' | translate }} ?</h3>
        <p class="text-muted" style="word-break:break-word; font-size: 16px;">
          {{ 'Our_customer_support_team_is_ready_to_help_you' | translate }}
        </p>
        <div class="d-flex mt-5 pb-5">
          <div class="flex-fill">
            <button mat-fab color="primary" style="background-color: #f26334; color: #ffffff;"
              (click)="clickSupportLink()">
              <mat-icon>language</mat-icon>
            </button>
            <a href="javascript:void(0);" class="text-blue font text-weight-bold mt-2 no-cursor support-link"
              (click)="clickSupportLink()">
              {{ 'GS1_Support_Website' | translate }}
            </a>
          </div>
          <div class="flex-fill">
            <a [href]="'mailto:' + email">
              <button mat-fab color="primary" style="background-color: #f26334; color: #ffffff;">
                <mat-icon>mail</mat-icon>
              </button>
            </a>
            <a [href]="'mailto:' + email" class="text-blue font text-weight-bold mt-2 support-link"> {{email}} </a>
          </div>
        </div>

      </mat-card>

    </div>
  </div>
</div>