import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent {
  title: string = "Session_Modal_Title";
  content1: string = "Session_Modal_Message";
  constructor(public translate: TranslateService, public authService: AuthService) { }

  onOKClick() {
    this.authService.logout();
  }

}
