import {
    Directive,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
  } from '@angular/core';
  import { MatTab } from '@angular/material/tabs';
  import { fromEvent, Subscription } from 'rxjs';
  import { tap, debounceTime } from 'rxjs/operators';
  
  import { ScrollRestoreMangerDirective } from './scroll-restore-manager.directive';
  
  @Directive({
    selector: '[appScrollableContainer]',
  })
  export class ScrollableContainerDirective implements OnInit, OnDestroy {
    private scrollTrackingSub: Subscription;
  
    public ngOnInit(): void {
      this.setUpScrollTracking();
      this.updateContainerElement();
    }
  
    public ngOnDestroy(): void {
      this.scrollTrackingSub.unsubscribe();
    }
  
    private get host(): HTMLElement {
      return this.el.nativeElement;
    }
  
    private get id(): string {
      return this.matTab.textLabel;
    }
  
    private setUpScrollTracking(): void {
      console.log("setupscrollTracking");
      this.ngZone.runOutsideAngular(() => {
        this.scrollTrackingSub = fromEvent(this.host, 'scroll')
          .pipe(
            debounceTime(200),
            tap(() => {
              this.updateScrollData();
            })
          )
          .subscribe();
      });
    }
  
    // needed only for tabs using ngTemplate
    private updateContainerElement(): void {
      if (!this.scrollRestoreManger.containersScrollMap.has(this.id)) {
        return;
      }
  
      const currentScrollData = this.scrollRestoreManger.containersScrollMap.get(
        this.id
      );
  
      this.scrollRestoreManger.containersScrollMap.set(this.id, {
        ...currentScrollData,
        el: this.host,
      });
    }
  
    private updateScrollData(): void {
      this.scrollRestoreManger.containersScrollMap.set(this.id, {
        el: this.host,
        scrollTop: this.host.scrollTop,
      });
    }
  
    constructor(
      private readonly el: ElementRef,
      private readonly matTab: MatTab, // or other token with container ID
      private readonly scrollRestoreManger: ScrollRestoreMangerDirective,
      private readonly ngZone: NgZone
    ) {}
  }
  