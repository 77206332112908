import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'app-gtinl2sd',
  templateUrl: './gtinl2sd.component.html',
  styleUrls: ['./gtinl2sd.component.scss']
})
export class Gtinl2sdComponent implements OnInit {

  @Input() gtinAttribute: any;
  linkTypes: any = [];
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {

    this.sharedService.getLinkTypes().subscribe((response: Array<any>) => {
      this.linkTypes = response.sort(this.compare);
    });

  }  

  compare(current, next) {
    if (current.linkTypeName < next.linkTypeName) {
      return -1;
    }
    if (current.linkTypeName > next.linkTypeName) {
      return 1;
    }
    return 0;
  }

}
