import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-gtincompanyinfo',
  templateUrl: './gtincompanyinfo.component.html',
  styleUrls: ['./gtincompanyinfo.component.scss']
})
export class GtincompanyinfoComponent implements OnChanges {

  @Input() gtinAttribute: any;
  @Input() keyType: any;

  constructor() { }

  ngOnChanges(): void {

    if (this.gtinAttribute.gs1Licence.hasOwnProperty('licenceType')) {

        var licenceTypes = JSON.parse(localStorage.getItem("licenceTypes"));
        if(licenceTypes)
        {
          var licenceDescription =  licenceTypes.find(c => c.type === this.gtinAttribute.gs1Licence.licenceType)?.description;
          this.gtinAttribute.gs1Licence.licenceType = licenceDescription;
        }
    }

    if (this.gtinAttribute.gs1Licence.dateCreated) {
      var pipe = new DatePipe('en-US');
      this.gtinAttribute.gs1Licence.dateCreated = pipe.transform(this.gtinAttribute.gs1Licence.dateCreated);
    }

    if (this.gtinAttribute.gs1Licence.dateUpdated) {
      var pipe = new DatePipe('en-US');
      this.gtinAttribute.gs1Licence.dateUpdated = pipe.transform(this.gtinAttribute.gs1Licence.dateUpdated);
    }

  }
    getAddressText(): string {
    const addressLocality = this.gtinAttribute.gs1Licence?.address?.addressLocality?.value;
    const addressRegion = this.gtinAttribute.gs1Licence?.address?.addressRegion?.value;

    if (addressLocality && addressRegion) {
      return `${addressLocality}, ${addressRegion}`;
    } else if (addressLocality) {
      return addressLocality;
    } else if (addressRegion) {
      return addressRegion;
    } else {
      return ''; 
    }
  }
}
