<div class="verified-company " [ngClass]="{l2sdBorder : gtinAttribute?.productDescription}" id="l2sdData">
  <div class="row no-gutters">
    <div class="col-md-12" >
      <h4 class="l2sdHeader">
        {{ "L2SD_Links_to_product_information" | translate }}
      </h4>
    </div>

    <div *ngFor="let item of linkTypes; let i = index" class="col-md-12">
      <div *ngIf="gtinAttribute?.linkset[item.linkType]" [style.display]=" item.display? 'block' : 'none'">
        <div class="col-md-12">
          <h5 class="linkTypeHeader">
            {{ item.linkTypeName | translate }}
            <span style="vertical-align: top">
              <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltip="{{ item.linkToolTipKey | translate }}"
                matTooltipPosition="after" matTooltipClass="after">info_outline</mat-icon>
            </span>
          </h5>
        </div>

        <div *ngIf="gtinAttribute?.linkset[item.linkType]">
          <div *ngFor=" let item of gtinAttribute.linkset[item.linkType]; let i = index ">
            <a rel="noopener" class="detaillink" target="_blank" href="{{ item.href }}">
              {{ item.title }}</a>
            <span *ngIf="item.type" class="textHTML">{{item.type.substring(item.type.indexOf('/')>-1 ?
              (item.type.indexOf('/')+1) : 0) | uppercase }}</span>         
            <div class="language">{{ "language" | translate }}: {{ item?.hreflang?.join(", ") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>