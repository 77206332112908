import { Directive, HostListener } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

export interface ScrollData {
  el: HTMLElement;
  scrollTop: number;
}

@Directive({
  selector: '[appScrollRestoreManger]',
  // exportAs: 'appScrollRestoreManger',
})
export class ScrollRestoreMangerDirective {
  public readonly containersScrollMap = new Map<string, ScrollData>();

  @HostListener('selectedTabChange', ['$event']) public selectedTabChange({
    tab: { textLabel: containerId },
  }: MatTabChangeEvent): void {
    this.restoreContainerScroll(containerId);
  }

  // public selectedChange(containerId: string): void {
  //   this.restoreContainerScroll(containerId);
  // }

  private restoreContainerScroll(containerId: string): void {
    console.log("containerId  "+containerId);
    if (!this.containersScrollMap.has(containerId)) {
      return;
    }

    const { el, scrollTop } = this.containersScrollMap.get(containerId);
    el.scrollTop = scrollTop;
  }
}
