import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  validationResult: any;
  constructor(
    public translate: TranslateService
  ) { }
  checkContainsChar(value): string {
    if (/^[0-9]+$/.test(value)) {
      return "";
    }
    else {
      return 'error_mesage_numeric_only';
    }
  }
  padLength(value): any {

    if (value.length == 8) {
      value = `000000${value}`;
    }

    if (value.length == 12) {
      value = `00${value}`;
    }

    if (value.length == 13) {
      value = `0${value}`;
    }
    return value;

  }

  padGlnLength(value): any {

    
    return value;

  }

  checkLength(value): any {
    if (value.length == 8) {
      value = `000000${value}`;
    }
    if (value.length == 12) {
      value = `00${value}`;
    }
    if (value.length == 13) {
      value = `0${value}`;
    }
    if (/^(?:[A-Za-z0-9]{8}|[A-Za-z0-9]{12}|[A-Za-z0-9]{13}|[A-Za-z0-9]{14})$/.test(value)) {
      return this.validationResult = { gtin: value, errorMessage: '' }
    }
    else {
      return this.validationResult = { gtin: value, errorMessage: 'error_mesage_length_validation' }
    }
  }

  checkGlnLength(value): any {
    if (value.length == 8) {
      value = `000000${value}`;
    }
    if (value.length == 12) {
      value = `00${value}`;
    }
    if (/^(?:[A-Za-z0-9]{8}|[A-Za-z0-9]{12}|[A-Za-z0-9]{13}|[A-Za-z0-9]{14})$/.test(value)) {
      return this.validationResult = { gln: value, errorMessage: '' }
    }
    else {
      return this.validationResult = { gln: value, errorMessage: 'error_mesage_length_validation' }
    }
  }

  checkHaveMinLength(value: string): string {
    if (value.length < 7) {
      return "error_mesage_length_validation";
    }
    else {
      return "";
    }
  }

  checkLengthForOtherKeys(key: string, value: string): string {
    if(key == 'sscc')
    {
      if (value.length < 18) {
        return "error_mesage_otherkey_length_validation";
      }
    }
    else {
      return "";
    }
  }

  checkNonCosumerFacing(value: string): string {
    if (value.startsWith('195')) {
      return "error_mesage_GTIN_unsupported";
    }
    else {
      return "";
    }
  }
  translateErrorCodes(value: string, errorCode: string, type: string): string {
    var error_message = "";
    if (errorCode == "E001") {
      
      var message = "error_mesage_length_validation";
      if (type == "otherkey")
      {
          message = "error_mesage_length_validation_otherkey";
      }
      if (type == "company")
      {
          message = "error_mesage_length_validation_company";
      }

      this.translate.get(message, { 0: value.length }).
        subscribe((text: string) => {
          error_message = text;
          if (type == "otherkey" || type == "company")
          {
              error_message = error_message.replace("{length}", value.length.toString());
          }
        });
    }
    if (errorCode == "E002") {
      var message = "error_mesage_GTIN_E002";
      if (type == "otherkey")
      {
          message = "error_mesage_E002_otherkey";
      }
      this.translate.get(message, { 0: value }).
        subscribe((text: string) => {
          error_message = text;
          if (type == "otherkey")
          {
            error_message = error_message.replace("{otherkey}", value);
          }
        });
    }
    if (errorCode == "E007") {
      this.translate.get("error_mesage_GTIN_unsupported", { 0: value.substring(0, 1) }).
        subscribe((text: string) => {
          error_message = text;

        });
    }

    if (errorCode == "keyType") {
      this.translate.get("error_mesage_keyType_is_required", { 0: value.substring(0, 1) }).
        subscribe((text: string) => {
          error_message = text;

        });
    }

    if (errorCode == "E021") {
      this.translate.get("error_mesage_large_or_small_batch", { 0: value.substring(0, 1) }).
        subscribe((text: string) => {
          error_message = text;

        });
    }

    if (errorCode == "E010") {
      this.translate.get("error_mesage_value_should_not_be_null", { 0: value.substring(0, 1) }).
        subscribe((text: string) => {
          error_message = text;

        });
    }

    
    if (errorCode == "E003") {
      var message = "error_mesage_numeric_only";
      if (type == "otherkey")
      {
          message = "error_mesage_numeric_only_otherkey";
      }
      this.translate.get(message, { 0: value.substring(0, 1) }).
        subscribe((text: string) => {
          error_message = text;
          if (type == "otherkey")
          {
            error_message = error_message.replace("{otherkey}", value);
          }
        });
    }
    if (errorCode == "E030") {
      error_message = this.getErrorMessage(value, "error_this_number_is_not_supported") + "= " +
        this.getErrorMessage(value, "error_it_is_for_special_applications");
    }
    if (errorCode == "E031") {
      error_message = this.getErrorMessage(value, "error_this_number_is_reserved_for_rfid") + "= " +
        this.getErrorMessage(value, "error_it_is_not_used_for_other_purposes.");
    }
    if (errorCode == "E032") {
      error_message = this.getErrorMessage(value, "error_this_number_is_not_supported") + "= " +
        this.getErrorMessage(value, "error_it_is_for_demonstrations");
    }
    if (errorCode == "E033") {
      error_message = this.getErrorMessage(value, "error_this_number_is_reserved_for_serial") + "= " +
        this.getErrorMessage(value, "error_it_is_not_used_for_other_purposes.");
    }
    if (errorCode == "E034") {
      error_message = this.getErrorMessage(value, "error_this_number_is_reserved_for_books") + "= " +
        this.getErrorMessage(value, "error_it_is_not_used_for_other_purposes.");
    }
    if (errorCode == "E035") {
      error_message = this.getErrorMessage(value, "error_this_number_is_not_supported") + "= " +
        this.getErrorMessage(value, "error_it_is_for_special_refund_receipts");
    }
    if (errorCode == "E036") {
      error_message = this.getErrorMessage(value, "error_this_number_is_not_supported") + "= " +
        this.getErrorMessage(value, "error_it_is_for_coupons");
    }
    if (errorCode == "E037") {
      error_message = this.getErrorMessage(value, "error_this_number_is_not_supported") + "= " +
        this.getErrorMessage(value, "error_it_is_for_coupons");
    }
    if (errorCode == "E038") {
      error_message = this.getErrorMessage(value, "error_this_number_is_not_supported");
    }
    if (errorCode == "E039") {
      error_message = this.getErrorMessage(value, "error_we_cannot_guarantee_that_this_number") + "= E039contactGS1";
    }
    return error_message;
  }

  getErrorMessage(value: string, message : string): string {
    var error_message = "";
    this.translate.get(message, { 0: value.substring(0, 1) }).
    subscribe((text: string) => {
      error_message = text;
    });
    return error_message;
  }

  checkGTINCount(length): string {
    if (length > 500) {
      return "error_mesage_GTIN_Limit";
    }
    else if (length == 0) {
      return "error_mesage_valid_barcode_numbers"
    }
    else {
      return "";
    }
  }

  checkGLNCount(length): string {
    if (length > 500) {
      return "error_mesage_GTIN_Limit";
    }
    else if (length == 0) {
      return "error_mesage_valid_barcode_numbers"
    }
    else {
      return "";
    }
  }

  GetCheckDigit(value): number {
    // return if value is null or blank
    if ((value?.length ?? 0) <= 0) { return -1; }
    // ensure value is even # of chars for correct calculation regardless of length
    if (value.length % 2 != 0) value = "0" + value;
    var sum = 0;
    for (var i = 0; i < value.length - 1; i++) {
      var c = value[i];
      var val = c - 0;
      if (val < 0 || val > 9) return -1;
      sum += val * ((i % 2) == 0 ? 3 : 1);
      //console.log(sum);
    }
    if (sum % 10 !== 0) {
      //console.log('sum ==>' + sum);
      return (Math.abs(this.RoundNumber(sum, 10) - sum));
    }
    else { return 0; }

  }
  RoundNumber(num, precision) {
    num = parseFloat(num);
    if (!precision) return num.toLocaleString();
    var roundedSum = (Math.ceil(num / precision) * precision);
    //console.log('higher multiple of 10(ceil) ==>' + roundedSum);
    return roundedSum.toLocaleString();
  };

  checkDigit (keyWoCD) {
        keyWoCD = [...keyWoCD].reverse().join('');
        let sum = 0;
        for (let i = keyWoCD.length - 1; i >= 0; i--) {
            if (parseInt(keyWoCD[i]) === 0) {
                continue;
            } else {
                if (i % 2 !== 0) {
                    sum += parseInt(keyWoCD[i]) * 1;
                }
                else {
                    sum += parseInt(keyWoCD[i]) * 3;
                }
            }
        }
        let checkDigit = Math.ceil(sum / 10) * 10 - sum;
        return checkDigit;
};
}
