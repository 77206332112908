import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CheckdigitError } from 'src/app/models/checkdigit-errormessage-model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { SharedService } from 'src/services/shared.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { TouDocument } from 'src/app/models/touDocument-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonUtilityFunctions } from 'src/app/helpers/common-util-functions';


@Component({
  selector: 'app-gtinattributes',
  templateUrl: './gtinattributes.component.html',
  styleUrls: ['./gtinattributes.component.scss'] 
})
export class GtinattributesComponent implements OnInit {
  [x: string]: any;
  link: string;
  @Input() gtinAttribute: any;
  @Input() glnAttribute: any;
  @Input() arrErrorMessages: Array<string>;
  @Input() CheckdigitErrorMessage: CheckdigitError;
  @Input() canDisplayAttributesSection: boolean;
  @Output("OnSearchClick") OnSearchClick: EventEmitter<any> = new EventEmitter();
  @Output("OnSearchGlnClick") OnSearchGlnClick: EventEmitter<any> = new EventEmitter();
  cdnUrl: string = environment.cdnUrl + '/img';
  legalEntityIndex: number = -1;
  fixedPhysicalIndex: number = -1;
  functionIndex: number = -1;
  mobileIndex: number = -1;
  digitalIndex: number = -1;
  l2sdDisplay: { [key: string]: boolean } = {};
  displayGlnName: string;
  base64Image: string = '';
  product_image_url: string = '';
  disablePDFButton: boolean = false;
  TouDocument: TouDocument = new TouDocument();

  constructor(private sharedService: SharedService, public authService: AuthService, public translate: TranslateService,
    private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
  //console.log(this.glnAttribute);
  
    if (this.glnAttribute)
    {
      
    }
    if (this.glnAttribute && this.glnAttribute.glnRecords) {
      
      this.glnAttribute.HasDiscontinued = false;
      this.glnAttribute.HasActive = false;
      this.glnAttribute.HasInActive = false;
      this.glnAttribute.HasOk = false;
      this.glnAttribute.AllDiscontinued = false;
      if (this.glnAttribute.glnRecordStatus == 'OK')
      {
        this.glnAttribute.HasOk = true;
      }
      if (this.glnAttribute.glnRecordStatus == 'DISCONTINUED')
      {
        this.glnAttribute.HasDiscontinued = true;
      }
      if (this.glnAttribute.glnRecordStatus == 'ACTIVE')
      {
        this.glnAttribute.HasActive = true;
      }
      if (this.glnAttribute.glnRecordStatus == 'INACTIVE')
      {
        this.glnAttribute.HasInActive = true;
      }
      var amountDiscontinued = 0;
        for (var i=0; i<this.glnAttribute.glnRecords.length; i++)
        {
            switch(this.glnAttribute.glnRecords[i].glnRecordStatus)
            {
              case 'DISCONTINUED':
                this.glnAttribute.HasDiscontinued = true;
                amountDiscontinued++;
              break;
              case 'ACTIVE':
                this.glnAttribute.HasActive = true;
              break;
              case 'INACTIVE':
                this.glnAttribute.HasInActive = true;
              break;
              case 'OK':
                this.glnAttribute.HasOk = true;
              break;
            }

            switch(this.glnAttribute.glnRecords[i].keyRecordStatus)
            {
              case 'DISCONTINUED':
                this.glnAttribute.HasDiscontinued = true;
              break;
              case 'ACTIVE':
                this.glnAttribute.HasActive = true;
              break;
              case 'INACTIVE':
                this.glnAttribute.HasInActive = true;
              break;
              case 'OK':
                this.glnAttribute.HasOk = true;
              break;
            }
        }

        if (this.glnAttribute.glnRecords.length == amountDiscontinued && this.glnAttribute.glnRecords.length > 1)
        {
          this.glnAttribute.AllDiscontinued = true;
        }

      for (var i = 0; i < this.glnAttribute.glnRecords.length; i++) {
       
        if (this.glnAttribute.glnRecords[i].glnType == "LEGAL_ENTITY") {
          this.legalEntityIndex = i;
          if (this.glnAttribute.glnRecords[i]?.location) {
            //this.glnAttribute.glnRecords[i].locationJoined = this.glnAttribute.glnRecords[i].location.map(u => u.gln + ' ('+ this.translate.instant(u.glnType)+')').join(', ');
            // this.glnAttribute.glnRecords[i].locationJoined= this.glnAttribute.glnRecords[i].location.map(relatedOrg => ({
            //   gln: relatedOrg.gln || 'Unknown',
            //   typeName: this.getGlnTypeName(relatedOrg.glnType)
            // }));
          }
          if (this.glnAttribute.glnRecords[i].organizationRole) {
            //this.glnAttribute.glnRecords[i].organizationRole = this.glnAttribute.glnRecords[i].organizationRole.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase());
            this.glnAttribute.glnRecords[i].organizationRole   = this.glnAttribute.glnRecords[i].organizationRole.map(w => this.getRoleName(w,'organizationRoles'));           
          }

          if (this.glnAttribute.glnRecords[i].additionalOrganizationID) {
            this.glnAttribute.glnRecords[i].additionalOrganizationIDJoined = this.glnAttribute.glnRecords[i].additionalOrganizationID.map(u => u.organizationID).join(', ');
          }
          if (this.glnAttribute.glnRecords[i]?.contactPoint) {
            this.glnAttribute.glnRecords[i].websiteJoined = this.glnAttribute.glnRecords[i].contactPoint.map(u => u.website).join(', ');
          }
          this.glnAttribute.glnRecords[i].uniqueLanguages= this.getUniqueLanguages(this.glnAttribute.glnRecords[i]);
        }

        if (this.glnAttribute.glnRecords[i].glnType == "FIXED_PHYSICAL_LOCATION") {
          this.fixedPhysicalIndex = i;
          console.log(this.fixedPhysicalIndex);
          if (this.glnAttribute.glnRecords[i].locationRole) {
            //this.glnAttribute.glnRecords[i].locationRole = this.glnAttribute.glnRecords[i].locationRole.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase());
             this.glnAttribute.glnRecords[i].locationRole = this.glnAttribute.glnRecords[i].locationRole.map(w => this.getRoleName(w,'locationRoles'));
             

          }
          
          if (this.glnAttribute.glnRecords[i].relatedOrganization) {
            this.glnAttribute.glnRecords[i].relatedOrganizationJoined = this.glnAttribute.glnRecords[i].relatedOrganization.map(u => u.gln).join(', ');
          }
          if (this.glnAttribute.glnRecords[i].additionalLocationID) {
            this.glnAttribute.glnRecords[i].additionalLocationIDJoined = this.glnAttribute.glnRecords[i].additionalLocationID.map(u => u.locationID).join(', ');
          }
          if (this.glnAttribute.glnRecords[i]?.contactPoint) {
            this.glnAttribute.glnRecords[i].websiteJoined = this.glnAttribute.glnRecords[i].contactPoint.map(u => u.website).join(', ');
          }
          this.glnAttribute.glnRecords[i].uniqueLanguages= this.getUniqueLanguages(this.glnAttribute.glnRecords[i]);
        }

        if (this.glnAttribute.glnRecords[i].glnType == "DIGITAL_LOCATION") {
          this.digitalIndex = i;
          
          if (this.glnAttribute.glnRecords[i].locationRole) {
            //this.glnAttribute.glnRecords[i].locationRole = this.glnAttribute.glnRecords[i].locationRole.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase());
            this.glnAttribute.glnRecords[i].locationRole = this.glnAttribute.glnRecords[i].locationRole.map(w => this.getRoleName(w,'locationRoles'));            
          }
          if (this.glnAttribute.glnRecords[i].relatedOrganization) {
            this.glnAttribute.glnRecords[i].relatedOrganizationJoined = this.glnAttribute.glnRecords[i].relatedOrganization.map(u => u.gln).join(', ');
          }
          if (this.glnAttribute.glnRecords[i].additionalLocationID) {
            this.glnAttribute.glnRecords[i].additionalLocationIDJoined = this.glnAttribute.glnRecords[i].additionalLocationID.map(u => u.locationID).join(', ');
          }
          if (this.glnAttribute.glnRecords[i]?.contactPoint) {
            this.glnAttribute.glnRecords[i].websiteJoined = this.glnAttribute.glnRecords[i].contactPoint.map(u => u.website).join(', ');
          }
          this.glnAttribute.glnRecords[i].uniqueLanguages= this.getUniqueLanguages(this.glnAttribute.glnRecords[i]);
        }

        if (this.glnAttribute.glnRecords[i].glnType == "FUNCTION") {
          this.functionIndex = i;
          if (this.glnAttribute.glnRecords[i].organizationRole) {
            //this.glnAttribute.glnRecords[i].organizationRole = this.glnAttribute.glnRecords[i].organizationRole.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase());
            this.glnAttribute.glnRecords[i].organizationRole =this.glnAttribute.glnRecords[i].organizationRole.map(w => this.getRoleName(w,'organizationRoles'));
          }
          
          if (this.glnAttribute.glnRecords[i].additionalOrganizationID) {
            this.glnAttribute.glnRecords[i].additionalOrganizationIDJoined = this.glnAttribute.glnRecords[i].additionalOrganizationID.map(u => u.organizationID + ' (' + u.organizationID_Type + ')').join(', ');
          }
          if (this.glnAttribute.glnRecords[i]?.contactPoint) {
            this.glnAttribute.glnRecords[i].websiteJoined = this.glnAttribute.glnRecords[i].contactPoint.map(u => u.website).join(', ');
          }
          this.glnAttribute.glnRecords[i].uniqueLanguages= this.getUniqueLanguages(this.glnAttribute.glnRecords[i]);
        }

        if (this.glnAttribute.glnRecords[i].glnType == "MOBILE_PHYSICAL_LOCATION") {
          this.mobileIndex = i;
          if (this.glnAttribute.glnRecords[i].locationRole) {
            //this.glnAttribute.glnRecords[i].locationRole = this.glnAttribute.glnRecords[i].locationRole.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase());
            this.glnAttribute.glnRecords[i].locationRole = this.glnAttribute.glnRecords[i].locationRole.map(w => this.getRoleName(w,'locationRoles'));
            
          }
          
          if (this.glnAttribute.glnRecords[i].additionalLocationID) {
            this.glnAttribute.glnRecords[i].additionalLocationIDJoined = this.glnAttribute.glnRecords[i].additionalLocationID.map(u => u.locationID).join(', ');
          }
          if (this.glnAttribute.glnRecords[i]?.contactPoint) {
            this.glnAttribute.glnRecords[i].websiteJoined = this.glnAttribute.glnRecords[i].contactPoint.map(u => u.website).join(', ');
          }
          if (this.glnAttribute.glnRecords[i].relatedOrganization) {
            this.glnAttribute.glnRecords[i].relatedOrganizationJoined = this.glnAttribute.glnRecords[i].relatedOrganization.map(u => u.gln).join(', ');
          }
          this.glnAttribute.glnRecords[i].uniqueLanguages= this.getUniqueLanguages(this.glnAttribute.glnRecords[i]);
        }

      }
      this.findGlnIndexGreaterThanOne();      
    }
  }
  // TODO: use  this from common
  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }  
  // Get unique languages from the entire list
  getUniqueLanguages(obj: any): string[] {
    if (!obj || !obj.contactPoint || !Array.isArray(obj.contactPoint)) {
      return [];
    }
  
    let allLanguages: string[] = [];
  
    obj.contactPoint.forEach((contactPoint: any) => {
      if (contactPoint && Array.isArray(contactPoint.availableLanguage)) {
        allLanguages = allLanguages.concat(contactPoint.availableLanguage);
      }
    });
  
    // Remove duplicates and return the unique languages
    return Array.from(new Set(allLanguages));
  }
  getRoleName(rolekey: string, roleType:string): string {
    if(roleType=== 'locationRoles'){
      const locationRoles = JSON.parse(localStorage.getItem(roleType))
      const locationRole = locationRoles.find(loc => loc.locationRole === rolekey);
      return locationRole ? locationRole.description : undefined;
    }
    if(roleType=== 'organizationRoles'){
      const organizationRoles = JSON.parse(localStorage.getItem(roleType))
      const organizationRole = organizationRoles.find(loc => loc.organizationRole === rolekey);
      return organizationRole ? organizationRole.description : undefined;
    }
  }
  
  findGlnIndexGreaterThanOne() {
    const indices = [
      this.legalEntityIndex,
      this.fixedPhysicalIndex,
      this.functionIndex,
      this.mobileIndex,
      this.digitalIndex,
    ];

    const countIndicesNotMinusOne = indices.filter((index) => index !== -1).length;
    if (countIndicesNotMinusOne === 1) {
      // Find the index that is not -1
      const notMinusOneIndex = indices.findIndex((index) => index !== -1);
      // Set the property on l2sdDisplay
      switch (notMinusOneIndex) {
        case 0:
          this.l2sdDisplay.legalEntityIndex = true;
          break;
        case 1:
          this.l2sdDisplay.fixedPhysicalIndex = true;
          break;
        case 2:
          this.l2sdDisplay.functionIndex = true;
          break;
        case 3:
          this.l2sdDisplay.mobileIndex = true;
          break;
        case 4:
          this.l2sdDisplay.digitalIndex = true;
          break;
      }
    } else {
      this.l2sdDisplay.asAccordian = true;
    }
  }
  
  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  downloadPDF() {
    this._snackBar.open(this.translate.instant('Generating_PDF_Message'), this.translate.instant('OK'), {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    this.disablePDFButton = true;
    var preferedLanguageCode = localStorage.getItem("preferedLanguageCode");
    this.sharedService.downloadPdf("[" + JSON.stringify(this.gtinAttribute) + "]", preferedLanguageCode, true)
      .subscribe(
        (response) => {
          this.disablePDFButton = false;
          this._snackBar.dismiss();
          this.TouDocument = response as TouDocument;
          const arrayBuffer = this.sharedService.base64ToArrayBuffer(this.TouDocument.FileBytes);
          this.sharedService.createAndDownloadBlobFile(arrayBuffer, "Gtin Report", 'pdf');
        },
        error => {
          this.disablePDFButton = false;
          this._snackBar.dismiss();
          this._snackBar.open(this.translate.instant('Generating_PDF_Message_error'), "close", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'mat-snack-bar-error-container'
          });
        },
        () => {

        }
      )
  }
  clickSupportLink() {
    this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      window.open(currentMO.link, '_blank');

    }, err => { console.log(err); });
  }
  clickCheckdigitCalc() {
    window.open(environment.checkdigit_Calculator_Url, '_blank');
  }
}
