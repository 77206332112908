import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'app-termofuse',
  templateUrl: './termofuse.component.html',
  styleUrls: ['./termofuse.component.scss']
})
export class TermofuseComponent {

  title: string = "TOUNew_Title";
  content1: string = "MOTOUNew_Content1";
  content2: string = "MOTOU_Content2";
  lastestVersion: string;
  Accept_and_continue: string;
  fileBytes: string;
  contentType: string;
  fileName: string;

  constructor(public dialogRef: MatDialogRef<TermofuseComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { isChangeInVersion: boolean, latestTOUVersion: string, fileBytes: string, contentType: string, fileName: string }, private cookieService: CookieService,
    public translate: TranslateService, public authService: AuthService, public sharedService: SharedService) {
    dialogRef.disableClose = true;
    this.lastestVersion = data.latestTOUVersion;
    this.fileBytes = data.fileBytes;
    this.fileName = data.fileName;
    this.contentType = data.contentType;

    if (data.isChangeInVersion == true) {
      this.title = "TOUUpdate_Title";
      this.content1 = "MOTOUUpdate_Content1";
      this.Accept_and_continue = "Accept_and_continue";
    }
  }

  onAcceptClick() {
    this.authService.currentCRMUser.GDOSTOUVersion = this.lastestVersion;
    this.authService.currentCRMUser.GDOSCTOUAcceptanceDate = new Date();
    localStorage.setItem('CRMUserProfile', JSON.stringify(this.authService.currentCRMUser));

    this.sharedService.updateClaims(this.authService.currentCRMUser).subscribe(response => {
      //console.log("TOU version updated.");
    }, error => {
      console.log(error);
    });
  }

  redirectToTermOfUse()
  {
    const arrayBuffer = this.base64ToArrayBuffer(this.fileBytes);
    this.createAndDownloadBlobFile(arrayBuffer, this.fileName, 'null', this.contentType);
  }

  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  createAndDownloadBlobFile(arrayBuffer, filename, extension = 'pdf', contentType = null) {

    const blob = new Blob([arrayBuffer], { type: contentType });
    const fileName = extension == 'null' ? `${filename}` : `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
