import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TouDocument {
  public FileBytes: string;
  public Version: string;
  public FileName : string;
  public ContentType: string;
}
