import { SharedService } from './../services/shared.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GtinComponent } from './components/gtin/gtin.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FormsModule } from '@angular/forms';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonMaterialModule } from 'src/modules/common-material.module';
import { AngularMaterialModule } from '../modules/angular-material.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthCallbackComponent } from 'src/auth-callback/auth-callback.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from 'src/services/auth.service';
import { HeaderComponent } from './components/header/header.component';
import { SearchTextFilterComponent } from './components/search-text-filter/search-text-filter/search-text-filter.component';
import { GtinDetailComponent } from './components/gtin-detail/gtin-detail/gtin-detail.component';
import { JwtInterceptor } from '../app//helpers/jwt-interceptor';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CookieService } from 'ngx-cookie-service';
import { I18nModule } from './i18n/i18n.module';
import { OverlayService } from './components/loader/overlay/overlay.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from 'src/CustomPaginatorConfiguration';
import { FooterComponent } from './components/footer/footer.component';
import { TermofuseComponent } from './components/termofuse/termofuse.component';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { MobileComponent } from './components/header/mobile/mobile.component';
import { DesktopComponent } from './components/header/desktop/desktop.component';
import { GtinattributesComponent } from './components/gtinattributes/gtinattributes.component';
import { Gtinl2sdComponent } from './components/gtinl2sd/gtinl2sd.component';
import { OtherKeysl2sdComponent } from './components/otherKeysl2sd/otherKeysl2sd.component';
import { GtincompanyinfoComponent } from './components/gtincompanyinfo/gtincompanyinfo.component';
import { GtinHeaderComponent } from './components/gtin-header/gtin-header.component';
import { ScrollRestoreMangerDirective } from './directives/scroll-restore-manager.directive';
import { ScrollableContainerDirective } from './directives/scrollable-container.directive';
import { HideMeDirective } from './directives/hide-me';
import { CardComponent } from './components/card/card.component';
import { Dropdown } from './components/dropdown/dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { Glnl2sdComponent } from './components/glnl2sd/glnl2sd.component';
import { KeysPipe } from './helpers/keys.pipe';
import { Support } from './components/support/support.component';

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
@NgModule({
  declarations: [
    AppComponent,
    GtinComponent,
    AuthCallbackComponent,
    HeaderComponent,
    SearchTextFilterComponent,
    GtinDetailComponent,
    UnauthorizedComponent,
    LoaderComponent,
    FooterComponent,
    TermofuseComponent,
    SessionTimeoutDialogComponent,
    MobileComponent,
    DesktopComponent,
    GtinattributesComponent,    
    Gtinl2sdComponent, GtincompanyinfoComponent, GtinHeaderComponent,Glnl2sdComponent,OtherKeysl2sdComponent,
    ScrollRestoreMangerDirective,
    ScrollableContainerDirective,
    HideMeDirective,
    CardComponent,
    Dropdown,
    Support,
    KeysPipe
       
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    FormsModule,
    FlexLayoutModule,
    CommonMaterialModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    I18nModule,
    MatSelectModule
  ],
  exports:[CommonMaterialModule],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor,multi:true },
    {provide:MatPaginatorIntl, useClass:CustomMatPaginatorIntl},   
    SharedService,AuthService, CookieService,OverlayService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
