<div class="verified-company "   id="otherKeyl2sdData">
  <div class="row no-gutters">
    <div class="col-md-12" >
      <h4 class="l2sdHeader">
        {{ "L2SD_Links_to_other_information" | translate }}
      </h4>
    </div>

<div>
  <div class="row no-gutters">
    <div *ngFor="let linkTypeItem of linkTypes; let i = index" class="col-md-12">
      <div *ngFor="let linksetObject of otherKeyAttribute.linkset; let j = index" class="col-md-12">

        <div *ngIf="this.hasProperty(linksetObject, linkTypeItem.linkType)">
          <div class="col-md-12">
            <h5 class="linkTypeHeader">
              {{ linkTypeItem.linkTypeName | translate }}
              <span style="vertical-align: top">
                <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()"
                  matTooltip="{{ linkTypeItem.linkToolTipKey | translate }}" matTooltipPosition="after"
                  matTooltipClass="after">info_outline</mat-icon>
              </span>
            </h5>
          </div>
          <div *ngFor="let subItem of linksetObject[linkTypeItem.linkType]; let j = index">
            <a rel="noopener" class="detaillink" target="_blank" href="{{ subItem.href }}">
              {{ subItem.title }}</a>
            <span *ngIf="subItem.type" class="textHTML">{{ subItem.type.substring(subItem.type.indexOf('/') > -1 ?
              (subItem.type.indexOf('/') + 1) : 0) | uppercase }}</span>
            <div class="language">{{ "language" | translate }}: {{ subItem?.hreflang?.join(", ") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>