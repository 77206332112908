import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CRMUserProfile } from '../app/models/user';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  currentUser: CRMUserProfile;
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      if (this.authService.currentCRMUser.Email == '' || this.authService.currentCRMUser.CloudRegStatus != "Registration Complete") {
        this.router.navigate(['/unauthorized']);
        return false;
      }
      return true;
    }
    this.authService.login();
    return false;
  }
}
