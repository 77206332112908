<div class="phoneContent bg-ui-1">
  <header>
    <div class="header-top" >
      <div class="container">
          <div class="top">

            <div style="flex:1" class="header-logo">
              <div fxLayout="row" >
                <div  fxLayout.lt-md="column">
                  <a (click)="redirectToCustomURL()" class="header-logo-image">
                    <img class="logo-img" alt="GS1 logo" [src]="header_image_url">
                  </a>
                  <a href="/home" style="text-decoration: none;">
                    <span class="header-logo-text">{{'Header_Title' | translate}}</span>
                  </a>
                </div>
              </div>
            </div>

            <div type="button" (click)="onOpenMenu($event)" data-toggle="collapse" data-target="#navbar-collapse" class="navbar-toggle collapsed" aria-expanded="true"><span class="icon-bar top-bar"></span><span class="icon-bar middle-bar"></span><span class="icon-bar bottom-bar"></span></div>
          </div>

      </div>
    </div>
    <div >
      <div class="navigation" style="clear: both;">
        <div class="container" *ngIf="isMenuOpen">
          <div class="menu-phone">
            <section class="row region region-bottom-header">
              <div class="col-md-12 justify-menu">
                <div id="block-gsone-revamp-gs1mainmenublock" class="settings-tray-editable block block-gsone-custom block-gsone-main-menu-block" data-drupal-settingstray="editable">
                  <div class="content">
                    <div class="clear-both menu-header">
                      <ul class="nav navbar-nav">
                        <li class="main-link-div ">
                          <a class="main-link">{{user_defaultlanguage | translate}}</a>
                          <div class="open-div d-lg-none d-xl-none hidden-md hidden-lg" (click)="OpenDivClick($event)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002C6C" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                            </svg>
                          </div>
                          <ul class="content-div dn" id="mini-panel-about_submenu">
                            <li class="row">

                              <div class="d-block d-md-none col-md-12 links-mobile">
                                <div *ngFor="let acc of user_languages">
                                  <a (click)="clickLanguageMenuItem(acc)">{{acc | translate}}</a>
                                </div>
                              </div>
                              <br>
                            </li>
                          </ul>
                        </li>

                        <li class="main-link-div ">
                            <a (click)="clickSupportLink()" class="main-link">{{'Support' | translate}}</a>
                        </li>

                        <li class="main-link-div ">
                          <a (click)="logout()" class="main-link">{{'Logout' | translate}}</a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
</header>

<!-- <div *ngIf="displayBanner" fxLayout="row" fxLayout.lt-md="column" class="container">
  <div fxFlex="72%" class="bg-blue">
    <div  class="header-banner-container" >
      <h2 class="banner-text color-white" >{{'Header_Title' | translate}}</h2>
      <p class="banner-description color-white display-banner-description">{{'label_lookup_1' | translate}}
        {{'label_lookup_2' | translate}}</p>

    </div>
  </div>
  <div fxFlex="28%" class="header-banner-image">
    <img style="height: 260px; width: 100%; object-fit: cover;" src="{{cdnUrl}}/header_banner.jpg" alt="VBGIDCard Image" />
  </div>

</div> -->

</div>
