import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client-ts';
import { Router } from '@angular/router';
import { CRMUserProfile } from '../app/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: User;
  public currentCRMUser: CRMUserProfile = new CRMUserProfile();
  private _userManager = new UserManager(getClientSettings());

  constructor(private router: Router) {
    this._userManager.getUser().then(user => {
      this._user = user;
      if (this.isLoggedIn()) {
        let crmUserProfile = localStorage.getItem('CRMUserProfile');
        if(crmUserProfile == undefined || crmUserProfile == null)
        {
          this.setCRMUserProfile();
        }
        else
        {
          this.currentCRMUser = JSON.parse(crmUserProfile);
        }
      }
    });

    this._userManager.events.addSilentRenewError(function () {
      console.log('error in renewing silenttoken');
    });

    this._userManager.events.addAccessTokenExpired(x=> {
      console.log('addAccessTokenExpired');
      localStorage.removeItem("CRMUserProfile");
      //this.logout();
    });

    this._userManager.events.addAccessTokenExpiring(x => {

      this.signinSilent().then(u => {
        this._user = u;
        this.setCRMUserProfile();
        localStorage.setItem('CRMUserProfile', JSON.stringify(this.currentCRMUser));
      });
    });
    this._userManager.events.addUserLoaded(function () {
    });
  }

  public login = () => {
    return this._userManager.signinRedirect();

  }

  public signinSilent = () => {
    return this._userManager.signinSilent();
  }

  public finishLogin = (): Promise<void> => {
    return this._userManager.signinRedirectCallback()
      .then(user => {
        this._user = user;
        this.setCRMUserProfile();
        localStorage.setItem('CRMUserProfile', JSON.stringify(this.currentCRMUser));
        this.router.navigate(['/home']);
      })
  }

  public logout = () => {
    localStorage.removeItem("CRMUserProfile");
    this._userManager.signoutRedirect();
  }

  isLoggedIn(): boolean {
    return this._user != null && this._user.expired != undefined && !this._user.expired;
  }

  public checkAuthenticationAsPromise(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        resolve(this.isLoggedIn());
      }, 1)
    });
  }

  getCRMUserProfile(): any {
    let crmUserProfile = localStorage.getItem('CRMUserProfile');
    if (crmUserProfile != undefined && crmUserProfile != null) {
      this.currentCRMUser = JSON.parse(crmUserProfile);
    }
    else {
      this.currentCRMUser = new CRMUserProfile()
    }
    return this.currentCRMUser;
  }

  setCRMUserProfile() {
    Object.entries(this._user.profile).forEach(
      ([key, value]) => {
        this.currentCRMUser[key.replace('http://gs1.org/claims/crm-', '')] = value;
      });

    this.currentCRMUser["token"] = this._user.access_token;
  }
}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: environment.ssoAuthority,// 'https://gs1sso-dev.gs1.org',
    client_id: environment.ssoClientId,// 'GDOSCUIDEV',
    redirect_uri: environment.ssoRedirectUri,
    post_logout_redirect_uri: environment.ssoPostLogoutRedirectUri,
    response_type: environment.ssoResponseType,// "code",
    scope: environment.ssoScope,// "openid profile",
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: false,
    silent_redirect_uri: environment.ssoSilentRefreshUri
  };
}
function signinSilent() {
  this._userManager.signinSilent();
}

