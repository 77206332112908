import {Component, Input ,Output, EventEmitter} from '@angular/core'

@Component({
    selector: 'app-card', 
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})

export class CardComponent {
    @Input() imageSrc: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() isSelected: boolean = false;
    @Output() cardClicked = new EventEmitter<void>();
   

    toggleSelection() {
        this.cardClicked.emit();
      }

}