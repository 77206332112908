import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TouDocument } from 'src/app/models/touDocument-model';
import { AuthService } from 'src/services/auth.service';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  currentYear : number = new Date().getFullYear();
  TouDocument: TouDocument = new TouDocument();

  constructor(public authService: AuthService, public sharedService: SharedService,private router: Router,)
  {

  }

  public redirectToTermOfUse()
  {
    this.sharedService.getTermsOfUseVersion(this.authService.currentCRMUser.MOID).subscribe((response) => {

      this.TouDocument = response as TouDocument;

      const arrayBuffer = this.base64ToArrayBuffer(this.TouDocument.FileBytes);
    this.createAndDownloadBlobFile(arrayBuffer, this.TouDocument.FileName, 'null', this.TouDocument.ContentType);
    });
  }

  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  createAndDownloadBlobFile(arrayBuffer, filename, extension = 'pdf', contentType = null) {

    const blob = new Blob([arrayBuffer], { type: contentType });
    const fileName = extension == 'null' ? `${filename}` : `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  redirectToCustomURL() {   
    this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      if (currentMO.customPrivacyPolicylink)
        window.open(currentMO.customPrivacyPolicylink, '_blank');
      else
      window.open("https://www.gs1.org/privacy-policy", '_blank');

    }, err => { console.log(err); });
  }
}
