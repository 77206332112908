<div class="container" >
  <h2 mat-dialog-title class="title">{{title | translate}}</h2>
  <div mat-dialog-content>

    <div>
      {{content1 | translate}}
    </div>
    <br>
    <div>
      {{content2 | translate}}
      <span><a (click)="redirectToTermOfUse()" title="{{'Terms_of_Use' | translate}}" target="_blank" class="toulink">{{'Terms_of_Use' | translate}}</a></span>

    </div>

  </div>
  <br>
  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="onAcceptClick()" color="primary" class="acceptButton">
      {{'Accept_and_continue' | translate}}
    </button>
  </div>
</div>
