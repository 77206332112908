import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  countryData: any;
  showGtinList: boolean = true;
  showGlnList: boolean = true;
  showCompanyList: boolean = true;
  showOtherKeyList: boolean = true;
  constructor(private http: HttpClient) { }

  setShowGtinList(value: boolean): void {
    this.showGtinList = value;
  }

  getShowGtinList(): boolean {
    return this.showGtinList;
  }

  setShowGlnList(value: boolean): void {
    this.showGlnList = value;
  }

  getShowGlnnList(): boolean {
    return this.showGlnList;
  }
  setshowCompanyList(value: boolean): void {
    this.showCompanyList = value;
  }

  getshowCompanyList(): boolean {
    return this.showCompanyList;
  }

  setShowOtherKeyList(value: boolean): void {
    this.showOtherKeyList = value;
  }

  getShowOtherKeyList(): boolean {
    return this.showOtherKeyList;
  }

  getGtins(values: any) {
    return this.http.post(environment.gs1GRPUrl + '/gtins/verified', values);    
  }

  getGlns(values: any) {
    return this.http.post(environment.gs1GRPUrl + '/glns/verified', values);    
  }


  getCountries() {
    return this.http.get(environment.cdnUrl + '/countries/geo-country-code.json');
  }
  getRoles(roleType:string) {
    return this.http.get(environment.cdnUrl + '/roles/'+roleType+'Roles.json');
  }

  getUnitCodes() {
    return this.http.get(environment.cdnUrl + '/netcontent/unitcode.json');
  }

  getLanguageCodes() {
    return this.http.get(environment.cdnUrl + '/languages/language-mapping.json');
  }

  getSupportLink() {
    return this.http.get(environment.cdnUrl + '/support/supportlink-mapping.json');
  }
  getLinkTypes() {
    return this.http.get(environment.cdnUrl +'/linktypes/link_type_mapping.json');
  }
  getGlnLinkTypes() {
    return this.http.get(environment.cdnUrl +'/linktypes/gln_link_type_mapping.json');
  }

  geLicenceTypes() {
    //return this.http.get( 'assets/licencetype/licencetype.json');
    return this.http.get(environment.cdnUrl + '/licencetypes/licencetype.json');
  }

  getOtherKeys(keyType: any, keyValues: any) {
    return this.http.post(environment.gs1GRPUrl + '/keys/'+keyType+'/verified', keyValues);    
  }
  getCompanyies(payload: any) {
    return this.http.post(environment.gs1GRPUrl + '/licences/search', payload);    
  }

  updateClaims(value: any) {

    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8',
      })
    };

    return this.http.post(environment.gs1VBGUrl + '/updateuserclaims', JSON.stringify(value), httpOptions);
  }

  getGPCdescription(code: string, languageCode: string) {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8',
      })
    };
    return this.http.post(environment.gs1VBGUrl + '/getgpcdescription?code=' + code + '&languageCode=' + languageCode, httpOptions,
      { responseType: 'text' });
  }

  getTermsOfUseVersion(moId: string) {
    return this.http.get<any>(environment.gs1VBGUrl + '/gettouversion?mo-id=' + moId);
  }

  getImageBase64(imageUrl: string) {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8',
      })
    };
    return this.http.post(environment.gs1VBGUrl + '/getImageBase64?imageUrl=' + imageUrl, httpOptions,
    { responseType: 'text' });
  }
  downloadPdf(gtinpayload: string, language: string, isSinglePage:boolean=false) {

     return this.http.post<any>(environment.gs1VBGUrl + '/downloadpdfbytes?selectedLanguage='+language+'&isSinglePage='+ isSinglePage, gtinpayload);
  }
  createAndDownloadBlobFile(arrayBuffer, filename, extension = 'pdf', contentType = null) {

    const blob = new Blob([arrayBuffer], { type: contentType });
    const fileName = extension == 'null' ? `${filename}` : `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        //this.disablePDFButton=false;
        //this._snackBar.dismiss();
      }
    }
  }
  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

}
