
export class CommonUtilityFunctions {
    static getGPCLanguageCode(languageCode?) {
        var preferedLanguageCode = languageCode ?? localStorage.getItem("preferedLanguageCode");
        var languageCodes = JSON.parse(localStorage.getItem("languageCodes"));
        
        var preferedGPCLanguageCode = languageCodes.find(c => c.code === preferedLanguageCode)
          && languageCodes.find(c => c.code === preferedLanguageCode).gpcLanguageCode;
        return preferedGPCLanguageCode;
      }
      static SetLanguagePreferences(userDefaultlanguage) {
        var languageCodes = JSON.parse(localStorage.getItem("languageCodes"));
        var preferedLanguageCodes = languageCodes.find(c => c.name === userDefaultlanguage)
          && languageCodes.find(c => c.name === userDefaultlanguage);
    
        var preferedLanguageCode = preferedLanguageCodes?.code;
        var preferedGPCLanguageCode = preferedLanguageCodes?.gpcLanguageCode;
    
        localStorage.setItem("preferedGPCLanguageCode", preferedGPCLanguageCode);
        localStorage.setItem("preferedLanguageCode", preferedLanguageCode);
        return preferedLanguageCode;
      }
      static getObjectKeys(obj: any): string[] {
        return Object.keys(obj);
      }
}