import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
    selector: 'select-dropdown', 
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})

export class Dropdown {
    @Input() options: any;
    @Input() placeHolder : string
    selected = '';    
    @Output() selectedItemEvent = new EventEmitter<string>();

    selectedItemChanged()
    {
        this.selectedItemEvent.emit(this.selected);
    }
}