import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null ){
      if(environment.gaTrackingId)
      {
         gtag('event', eventName, {
                 eventCategory: eventCategory,
                 eventLabel: eventLabel,
                 eventAction: eventAction,
                 eventValue: eventValue
               });
      }
    }


    public pageViewEmitter( eventName: string, pagePath: string
    ){
      if(environment.gaTrackingId)
      {
        gtag('config', environment.gaTrackingId, eventName,{
          page_path : pagePath
        });
      }
    }
}
