<div class="verified-company">
  <div class="row no-gutters">
    <div class="col-md-12 structured-list">
      <div *ngIf="gtinAttribute.gtin && keyType == 'GTIN'" style="margin-top: 20px;" class="l2sdHeader">
        {{ "CI_Header" | translate }}
      </div>
      <div *ngIf="(gtinAttribute.gln || (gtinAttribute && gtinAttribute?.glnRecords && gtinAttribute?.glnRecords[0]?.gln)) && keyType == 'GLN'" style="font-weight: 300;" class="l2sdHeader">
        {{ "CI_Header_GLN" | translate }}
      </div>
      <div *ngIf="gtinAttribute?.gs1Licence && keyType == 'otherKeys'" style="font-weight: 300;" class="l2sdHeader">
        {{ "CI_Header_Other_keys" | translate }}
      </div>

      <table class="company">
        <tbody>
          <tr>
            <td>{{ "CI_CompanyName" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.licenseeName; else display_unknown" class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.licenseeName === 'Licensee name withheld for privacy reasons' ? 
              ('licensee_name_withheld' | translate) : gtinAttribute.gs1Licence.licenseeName}}
            </td>
          </tr>

          <tr>
            <td>{{ "CI_Address" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.address; else display_unknown">
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.postalName;">
              {{gtinAttribute.gs1Licence.address.postalName.value}}
            </td>
          </tr>
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.streetAddress;">
              {{gtinAttribute.gs1Licence.address.streetAddress.value}}
            </td>
          </tr>
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.streetAddressLine2;">
              {{gtinAttribute.gs1Licence.address.streetAddressLine2.value}}
            </td>
          </tr>
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.streetAddressLine3;">
              {{gtinAttribute.gs1Licence.address.streetAddressLine3.value}}
            </td>
          </tr>
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.streetAddressLine4;">
              {{gtinAttribute.gs1Licence.address?.streetAddressLine4.value}}
            </td>
          </tr>
          <tr class="custom-row">
             <td *ngIf="gtinAttribute.gs1Licence.address?.addressLocality || gtinAttribute.gs1Licence.address?.addressRegion;">
              {{ getAddressText() }}
            </td> 
          </tr>
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.postalCode; ">
              {{gtinAttribute.gs1Licence.address.postalCode}}
            </td>
          </tr>
          <tr class="custom-row">
            <td *ngIf="gtinAttribute.gs1Licence.address?.countryCode;">
              {{gtinAttribute.gs1Licence.address.countryCode}}
            </td>
          </tr>
          </td>

          </tr>

          <tr class="displayElement">
            <td>{{ "CI_TelephoneNumber" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.telephone; else display_unknown" class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.telephone}}
            </td>
          </tr>

          <tr class="displayElement">
            <td>{{ "CI_EmailAddress" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.email; else display_unknown" class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.email}}
            </td>
          </tr>
          <tr>
            <td>{{ "CI_Website" | translate }}</td>

            <td *ngIf="gtinAttribute.gs1Licence.contactPoint; else display_unknown" class="detailWorkBreak">
          <tr class="custom-row" *ngFor="let item of gtinAttribute.gs1Licence.contactPoint; let i= index;">
            <td class="value">
              <a rel="noopener" class="detaillink" target="_blank" href={{item.website}}>
                {{item.website}}</a>
            </td>
          </tr>
          </td>
          </tr>

          <tr>
            <td>{{ "CI_LicenceType" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.licenceType; else display_unknown" class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.licenceType}}
            </td>
          </tr>

          <tr>
            <td>{{ "CI_LicenceKey" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.licenceKey; else display_unknown" class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.licenceKey}}
            </td>
          </tr>

          <tr>
            <td>{{ "CI_GLN" | translate }}</td>
            <td *ngIf="gtinAttribute.gs1Licence.licenseeGLN; else display_unknown" class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.licenseeGLN}}
            </td>
          </tr>

          <tr>
            <td>{{ "CI_MO" | translate }}</td>
            <td
              *ngIf="gtinAttribute.gs1Licence.licensingMO && gtinAttribute.gs1Licence.licensingMO.moName; else display_unknown"
              class="detailWorkBreak">
              {{gtinAttribute.gs1Licence.licensingMO.moName}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <br>
    <br>
    <br>
    <div fxLayout="row" class="col-md-12">
      <p class="info-data">
        {{'CI_Footer_Licence_Information' | translate}} {{gtinAttribute.gs1Licence.licensingMO.moName}}
        {{'CI_Footer_Last_Updated' | translate}} {{gtinAttribute.gs1Licence.dateUpdated}}.
      </p>
    </div>

    <ng-template #display_unknown>
      <td class="detailvalueunknown">
        {{ "Unknown" | translate }}
      </td>
    </ng-template>

  </div>
</div>