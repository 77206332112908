import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'app-glnl2sd',
  templateUrl: './glnl2sd.component.html',
  styleUrls: ['./glnl2sd.component.scss']
})
export class Glnl2sdComponent implements OnInit {

  @Input() glnAttribute: any;
  linkTypes: any = [];
  filteredLinkTypes: any[] = [];
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    console.log(this.glnAttribute);
    this.sharedService.getLinkTypes().subscribe((response: Array<any>) => {
      this.linkTypes = response.sort(this.compare);

    });

  }
  hasProperty(obj: any, prop: string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }
  getObjectKeys(obj: any): string[] {
    if (obj == null) {
      return []; // Return an empty array if obj is null or undefined
    }

    if (typeof obj !== 'object') {
      throw new Error('Input is not an object');
    }

    return Object.keys(obj);
  }

  // Function to filter linkset based on linkType
  getLinksetByLinkType(linkType: string): any {
    return this.glnAttribute.linkset.find(item => item.anchor === linkType);
  }
  compare(current, next) {
    if (current.linkTypeName < next.linkTypeName) {
      return -1;
    }
    if (current.linkTypeName > next.linkTypeName) {
      return 1;
    }
    return 0;
  }

}
