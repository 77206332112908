import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-text',
  templateUrl: './search-text-filter.component.html',
  styleUrls: ['./search-text-filter.component.scss']
})
export class SearchTextFilterComponent {

  @Input() search: string;
  @Input() placeHolder: string;
  @Input() hideButton: boolean;
  @Output() searchTextEvent = new EventEmitter<string>();

  constructor(private router: Router) {}
  isCleared: boolean = false;
  onSearch() {
    this.searchTextEvent.emit(this.search);
    this.router.navigate(['./home']);
  }
  onClear() {
    this.search = '';
    this.searchTextEvent.emit(this.search);
  }

}
