import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CheckdigitError } from 'src/app/models/checkdigit-errormessage-model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { SharedService } from 'src/services/shared.service';
import { TouDocument } from 'src/app/models/touDocument-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-gtin-header',
  templateUrl: './gtin-header.component.html',
  styleUrls: ['./gtin-header.component.scss']
})
export class GtinHeaderComponent implements OnInit {

  [x: string]: any;
  link: string;
  @Input() gtinAttribute: any;
  @Input() keyType: any;
  @Input() glnAttribute: any;
  @Input() arrErrorMessages: Array<string>;
  @Input() CheckdigitErrorMessage: CheckdigitError;
  @Input() canDisplayAttributesSection: boolean;  
  @Input() search: string;  
  @Output() searchTextEvent = new EventEmitter<string>();
  cdnUrl: string = environment.cdnUrl + '/img';
  base64Image: string = '';
  product_image_url: string = '';
  disablePDFButton:boolean=false;
  TouDocument: TouDocument = new TouDocument();  
  constructor(private sharedService: SharedService, public authService: AuthService, public translate: TranslateService,private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(this.arrErrorMessages && this.arrErrorMessages.length > 0){
      this.gtinAttribute = true
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  downloadPDF(){
    this._snackBar.open(this.translate.instant('Generating_PDF_Message'), this.translate.instant('OK'), {
      horizontalPosition: 'center',     
       verticalPosition: 'top',
     });
     this.disablePDFButton=true;
     var preferedLanguageCode = localStorage.getItem("preferedLanguageCode");
     this.sharedService.downloadPdf("["+ JSON.stringify(this.gtinAttribute)+"]", preferedLanguageCode,true)
     .subscribe(
       (response) => {
          this.disablePDFButton=false;
          this._snackBar.dismiss();        
         this.TouDocument = response as TouDocument;
         const arrayBuffer = this.sharedService.base64ToArrayBuffer(this.TouDocument.FileBytes);
         this.sharedService.createAndDownloadBlobFile(arrayBuffer, "Gtin Report", 'pdf');
       },
       error=>{
         this.disablePDFButton=false;
         this._snackBar.dismiss();
         this._snackBar.open(this.translate.instant('Generating_PDF_Message_error'),"close", {
           horizontalPosition: 'center',     
            verticalPosition: 'top',
            panelClass:'mat-snack-bar-error-container'
          });
       },
       ()=>{

       }
     )
  }  
  clickSupportLink() {
    this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      window.open(currentMO.link, '_blank');

    }, err => { console.log(err); });
  }
  clickCheckdigitCalc(isGMN) {
    isGMN ?
    window.open('https://www.gs1.org/services/check-character-calculator', '_blank') :
    window.open(environment.checkdigit_Calculator_Url, '_blank')
  }

  onSearch() {
    this.searchTextEvent.emit(this.CheckdigitErrorMessage.GtinSuggested);
  }
}
