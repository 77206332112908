import { MatPaginatorIntl } from "@angular/material/paginator";
import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  private translationSubscription: Subscription;
  constructor(public translate: TranslateService) {
    super();
    this.translate.get('Items_per_page').subscribe((res) => {
      this.itemsPerPageLabel = res;
      this.changes.next();
    });

      this.translationSubscription = this.translate.onLangChange.subscribe(() => {
        this.translate.get('Items_per_page').subscribe((res) => {
          this.itemsPerPageLabel = res;
          this.changes.next();
        });
      });
  }
  ngOnDestroy() {
    this.translationSubscription.unsubscribe();
  }
}
