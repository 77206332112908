import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/services/auth.service";
import { SharedService } from "src/services/shared.service";
import { environment } from 'src/environments/environment';

@Component({
    selector:'app-support',
    templateUrl:'./support.component.html',
    styleUrls:['./support.component.scss']
})

export class Support implements OnInit {
    email:string
    constructor(private sharedService: SharedService, public authService: AuthService){}
    ngOnInit(): void {
        this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
            var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
            this.email = currentMO?.email || 'helpdesk@gs1.org'    
          }, err => { console.log(err); });

    }
    clickSupportLink() {
        this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
          var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
          window.open(currentMO.link, '_blank');
    
        }, err => { console.log(err); });
    }
    
    clickSupportGuide() {
        const cdnUrl = environment.cdnUrl
        const selectedLang =  localStorage.getItem("preferedGPCLanguageCode") || 'en';
        window.open(`${cdnUrl}/support/VbG_Service_Component_Quickstart_Guide_${selectedLang}.pdf`, '_blank');
    }
}