import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
//import { stringify } from 'querystring';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/services/shared.service';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings]
      },
      cacheMechanism: 'Cookie'
    })
  ],
  exports: [TranslateModule]
})

export class I18nModule {

  constructor(
    translate: TranslateService,
    translateCacheService: TranslateCacheService,
    public sharedService: SharedService
  ) {
    translateCacheService.init();
    this.sharedService.getLanguageCodes().subscribe(result => {
      localStorage.setItem("languageCodes", JSON.stringify(result));

      var languageCodes = JSON.parse(localStorage.getItem("languageCodes"));
      let languages = new Array<string>();
      for (let item of languageCodes) {
        languages.push(item.code);
      };
      translate.addLangs(languages);

      var languagesString = languages.join('|');
      const browserLang = translateCacheService.getCachedLanguage() || translate.getBrowserLang();
      translate.use(browserLang.match(`/${languagesString}/`) ? browserLang : 'en');

    });

  }
}

export function httpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.cdnUrl+'/languages/', ".json?v="+ (new Date()).getTime());  
}

export function translateCacheFactory(translateService: TranslateService, translateCacheSettings: TranslateCacheSettings) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
