<div class="container" [ngClass]="{ 'selected': isSelected }" (click)="toggleSelection()">

    <label [ngClass]="{ 'selected': isSelected }" class="wrapper">
        <div [ngClass]="{ 'selected': isSelected }">
            <img [ngClass]="{ 'selected': isSelected }" [src]="imageSrc" />
            <h4 [ngClass]="{ 'selected': isSelected }">{{title | translate}}</h4>
            <p [ngClass]="{ 'selected': isSelected }">
                {{ subtitle | translate }}
            </p>
        </div>
    </label>
    <img class="selected_img" *ngIf="isSelected" src="../../../assets/img/download.svg" />
</div>