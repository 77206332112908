import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckdigitError {
  public CheckdigitEntered: string;
  public CheckdigitCalculated: number;
  public GtinWithoutCheckDgit : string;
  public GtinSuggested : string;
  public CheckdigitErrorMessageKey: string;
  public KeyName?: string;
  public remainingdigits?: string
}