import { TouDocument } from './../../models/touDocument-model';
import { environment } from 'src/environments/environment';
import { ValidationService } from './../../../services/validation.service';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { SharedService } from 'src/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RouteEventsService } from 'src/services/route-events.service';
import { DatePipe } from '@angular/common';
import { ValidationResponse } from 'src/app/models/validationResponse';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/services/loader.service';
import { async, forkJoin, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TermofuseComponent } from '../termofuse/termofuse.component';
import * as fileSaver from 'file-saver';
import { CheckdigitError } from 'src/app/models/checkdigit-errormessage-model';
import { BreakpointObserver, Breakpoints, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HideMeDirective } from 'src/app/directives/hide-me';
import GMN from '../../../services/gmn.js'
import { takeUntil } from 'rxjs/operators';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';



@Component({
  selector: 'app-gtin',
  templateUrl: './gtin.component.html',
  styleUrls: ['./gtin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GtinComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('matTabGroup', { static: true }) matTabGroup: ElementRef;
  @ViewChild('GTINColumn') GTINColumn: ElementRef;
  @ViewChild('ProductDescriptionColumn') ProductDescriptionColumn: ElementRef;
  @ViewChild('tabToggleRef') tabToggleRef: HideMeDirective;

  exportModel: any;
  model: any;
  user_name: any;
  user_type: any;
  dataSource: MatTableDataSource<any>;
  gtinAttribute: any;
  glnAttribute: any;
  otherKeyAttribute: any;
  gtinCount: any;
  glnCount: any;
  otherKeyCount: any;
  companyCount: any;
  errorMessage: string;
  arrErrorMessages: Array<string>;
  CheckdigitErrorMessage = new CheckdigitError();
  previousUrl: string;
  searchGtins: string;
  searchGlns: string;
  TOUCurrentVersion: string;
  canDisplayAttributesSection: boolean;
  validationResponse: ValidationResponse;
  loaderService: LoaderService;
  routeEventService: RouteEventsService;
  gpcCategoryCode: string = '';
  gpcCategoryCodeValue: string = '';
  TouDocument: TouDocument = new TouDocument();
  disablePDFButton: boolean = false;
  filterValues: any = {
    isComplete: false,
    productionDescription: ''
  };
  errorWithMultiSearchCount = 0;

  displayedColumns = ["ProductImage", "gtin", "description"];
  displayedGlnColumns = ["gln", "glnTypeLabel", "description"];
  displayedOtherKeyColumns = ["OtherKeyType", "OtherKeyLicenceName", "LicensingMO"];
  displayedCompanyColumns = ["licenceKey", "licenseeName", "addressLocality", "countryCode"];
  cdnUrl: string = environment.cdnUrl + '/img';
  breakpointObserver: any;
  isMobile: Observable<BreakpointState>;
  displayNameMap: any;
  destroyed = new Subject<void>();
  ProductInfo_Label: string;
  ComapnyInfo_Label: string;
  Additional_Information: string;
  selectedKey: string;
  city: string;
  street: string;
  postalcode: string;
  companySearchKeyword: string;
  countrySearchKey: string;
  countryList: any;

  constructor(private sharedService: SharedService, public authService: AuthService, private router: Router,
    private validationService: ValidationService, private route: ActivatedRoute,
    public translate: TranslateService, public dialog: MatDialog, private injector: Injector, private changeDetectorRef: ChangeDetectorRef
    , private _snackBar: MatSnackBar, breakpointObserver: BreakpointObserver
  ) {
    this.loaderService = injector.get<LoaderService>(LoaderService);
    this.routeEventService = injector.get<RouteEventsService>(RouteEventsService);

    if (breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.ProductInfo_Label = "Product_Information_Short";
      this.ComapnyInfo_Label = "Comapny_Information_Short";
    } else {
      this.ProductInfo_Label = "Product_Information";
      this.ComapnyInfo_Label = "Comapny_Information";
    }
    this.Additional_Information = "Additional_Information";
  }

  @HostListener('matSortChange', ['$event'])
  sortChange(e) {
    // save cookie with table sort data here
    console.log(e);
    this.dataSource.sort = this.sort;

    if (e.active == "glnTypeLabel" || e.active == "description") {
      this.sortStringDataSource(e.active, e.direction);
    }
    else if (this.selectedCardIndex == 2) {
      this.sortOtherKeyDataSource(e.active, e.direction);
    }
    else if (this.selectedCardIndex == 3) {
      this.sortCompanyDataSource(e.active, e.direction);
    }
    else {
      this.sortDataSource(e.active, e.direction);
    }

  }
  showOptional = false

  GS1KeyTypes = [
    { key: 'sscc', value: 'SSCC' },
    { key: 'grai', value: 'GRAI' },
    { key: 'giai', value: 'GIAI' },
    { key: 'gsrn', value: 'GSRN' },
    { key: 'gdti', value: 'GDTI' },
    { key: 'ginc', value: 'GINC' },
    { key: 'gsin', value: 'GSIN' },
    { key: 'gcn', value: 'GCN' },
    { key: 'cpid', value: 'CPID' },
    { key: 'gmn', value: 'GMN' }
  ]
  

  cards = [
    {
      imageSrc: '../../../assets/img/gtin.svg',
      title: 'Verify_Product',
      subtitle: 'Search_By_Global_Trade_Item_Number',
      placeHolder: 'Enter_Barcode_Number'
    },
    {
      imageSrc: '../../../assets/img/gln.svg',
      title: 'Verify_Location',
      subtitle: 'Search_By_Global_Location_Number',
      placeHolder: 'Enter_A_Global_Location_Number'
    },
    {
      imageSrc: '../../../assets/img/other-keys.svg',
      title: 'Verify_Other_Keys',
      subtitle: 'Search_By_Other_Keys',
      placeHolder: 'Search_For_Upto_500_GS1_Keys'
    },
    {
      imageSrc: '../../../assets/img/licensee.svg',
      title: 'Find_Company',
      subtitle: 'Search_By_Company_Name_And_Country',
      placeHolder: 'Enter_A_Company_Name'
    },
  ];
  selectedCardIndex: number = 0;
  currentCard: string = 'Verify Location/party'

  private createCountryDropdownOptions() {
    this.countryList = JSON.parse(localStorage.getItem("countries"))
    const options = this.countryList.map(item => ({ key: item.alpha2, value: item.country, alpha3: item.alpha3, numeric: item.numeric }));
    options.unshift({ key: 'ALL', value: 'All' });
    this.countryList= options;
  }

  OnSelectedItemChanged(selectedKey) {
    this.selectedKey = selectedKey;
  }

  onCardClicked(index: number, type: string) {
    this.createCountryDropdownOptions();
    this.selectedCardIndex = index
    if (this.currentCard != type) {
      this.gtinAttribute = null
      this.gtinCount = null
      this.glnCount = null
      this.otherKeyCount = null;
      this.companyCount = null;
      this.glnAttribute = null;
      this.otherKeyAttribute = null;
      this.dataSource = null;
      this.searchGtins = ''
      this.searchGlns = ''
      this.city = ''
      this.postalcode = ''
      this.street = ''
      this.sharedService.setshowCompanyList(false);
      this.router.navigate(['home']);
    }
    this.currentCard = type
    this.ProductInfo_Label = "Product_Information";
    if (this.selectedCardIndex == 1) {
      this.ProductInfo_Label = "Gln_Information";
    }
    // if (this.selectedCardIndex == 3) {
    //   this.createCountryDropdownOptions();
    // }

    this.selectedKey = null;
  }

  ngOnChanges() {

    this.changeDetectorRef.detectChanges();

  }

  sortDataSource(idVal: string, startVal?: string): void {
    this.dataSource.data.sort(
      (a, b) => {
        return startVal === 'asc' ? a[idVal] - b[idVal] : (startVal === 'desc' ? b[idVal] - a[idVal] : a[idVal] - b[idVal]);
      }
    );
  }

  sortOtherKeyDataSource(idVal: string, startVal?: string): void {
    this.dataSource.data.sort(
      (a, b) => {

        var aIdVal = '';
        var bIdVal = '';

        if (idVal == 'OtherKeyLicenceName') {
          if (a['gs1Licence'])
            aIdVal = a['gs1Licence']['licenseeName'];
          if (b['gs1Licence'])
            bIdVal = b['gs1Licence']['licenseeName'];
        }
        else if (idVal == 'LicensingMO') {
          if (a['gs1Licence'])
            aIdVal = a['gs1Licence']['licensingMO']['moName'];
          if (b['gs1Licence'])
            bIdVal = b['gs1Licence']['licensingMO']['moName'];
        }
        else {
          if (a[this.selectedKey] ? a[this.selectedKey] : a[this.selectedKey.toUpperCase()])
            aIdVal = a[this.selectedKey] ? a[this.selectedKey] : a[this.selectedKey.toUpperCase()];
          if (b[this.selectedKey] ? b[this.selectedKey] : b[this.selectedKey.toUpperCase()])
            bIdVal = b[this.selectedKey] ? b[this.selectedKey] : b[this.selectedKey.toUpperCase()];
        }

        //return startVal === 'asc' ? a[idVal] - b[idVal] : (startVal === 'desc' ? b[idVal] - a[idVal] : a[idVal] - b[idVal])

        return startVal === 'asc' ? bIdVal.localeCompare(aIdVal) : aIdVal.localeCompare(bIdVal);
      }
    );
  }
  sortCompanyDataSource(idVal: string, startVal?: string): void {
    this.dataSource.data.sort(
      (a, b) => {

        var aIdVal = '';
        var bIdVal = '';

        if (idVal == 'countryCode') {
          if (a["address"])
            aIdVal = a["address"].countryCode;
          if (b['address'])
            bIdVal = b["address"].countryCode;
        }
        else if (idVal == 'addressLocality') {
          if (a['address']?.addressLocality)
            aIdVal = a["address"].addressLocality.value;
          if (b['address']?.addressLocality)
            bIdVal = b["address"].addressLocality.value;
        }
        //TODO: Do we  need this for this? r emove otherwise, copied this from otherkey sorting logic
        else {
          if (a[this.selectedKey] ? a[this.selectedKey] : a[this.selectedKey.toUpperCase()])
            aIdVal = a[this.selectedKey] ? a[this.selectedKey] : a[this.selectedKey.toUpperCase()];
          if (b[this.selectedKey] ? b[this.selectedKey] : b[this.selectedKey.toUpperCase()])
            bIdVal = b[this.selectedKey] ? b[this.selectedKey] : b[this.selectedKey.toUpperCase()];
        }

        return startVal === 'asc' ? bIdVal.localeCompare(aIdVal) : aIdVal.localeCompare(bIdVal);
      }
    );
  }
  sortStringDataSource(idVal: string, startVal?: string): void {
    this.dataSource.data.sort(
      (a, b) => b[idVal].localeCompare(a[idVal])
    );
  }

  ngOnInit() {
    this.sharedService.setShowGtinList(true);
    this.sharedService.setShowGlnList(true);
    this.sharedService.setShowOtherKeyList(true);
    this.sharedService.setshowCompanyList(true);
    this.checkTermOfUseAcceptance();

    if (this.routeEventService.getPreviousUrl().indexOf("gtin-detail") > -1) {
      this.resetToLastState();
    }
    this.setLookupItemsCache();
    
  }
  private setLookupItemsCache() {
    forkJoin(
      [this.sharedService.getCountries(), this.sharedService.getUnitCodes(), this.sharedService.geLicenceTypes() ,this.sharedService.getRoles('location'),this.sharedService.getRoles('organization')]
    ).subscribe((response: Array<any>) => {
      localStorage.setItem("countries", JSON.stringify(response[0]));
      localStorage.setItem("unitCodes", JSON.stringify(response[1]));
      localStorage.setItem("licenceTypes", JSON.stringify(response[2]));
      localStorage.setItem("locationRoles", JSON.stringify(response[3]));
      localStorage.setItem("organizationRoles", JSON.stringify(response[4]));
    }, err => { console.log(err); });
  }

  private checkTermOfUseAcceptance() {

    this.sharedService.getTermsOfUseVersion(this.authService.currentCRMUser.MOID).subscribe((response) => {

      this.TouDocument = response as TouDocument;
      this.TOUCurrentVersion = this.TouDocument.Version;
      if (this.authService.currentCRMUser.GDOSTOUVersion != '') {
        var version = this.authService.currentCRMUser.GDOSTOUVersion;
        if (version != this.TOUCurrentVersion) {
          this.dialog.open(TermofuseComponent, {
            width: '500px',
            panelClass: 'custom-dialog-container',
            data: { isChangeInVersion: true, latestTOUVersion: this.TOUCurrentVersion, fileBytes: this.TouDocument.FileBytes, contentType: this.TouDocument.ContentType, fileName: this.TouDocument.FileName }
          });
        }
      }
      else {
        this.dialog.open(TermofuseComponent, {
          width: '500px',
          panelClass: 'custom-dialog-container',
          data: { isChangeInVersion: false, latestTOUVersion: this.TOUCurrentVersion, fileBytes: this.TouDocument.FileBytes, contentType: this.TouDocument.ContentType, fileName: this.TouDocument.FileName }
        });
      }

    })
  }

  public resetToLastState() {
    this.gtinCount = 2;
    this.model = JSON.parse(sessionStorage.getItem("gtinlist"));
    this.exportModel = JSON.parse(sessionStorage.getItem("exportGtinlist"))
    this.searchGtins = JSON.parse(sessionStorage.getItem("gtinsearch"));
    this.filterValues = JSON.parse(sessionStorage.getItem("filterValues"));
    this.errorWithMultiSearchCount = JSON.parse(sessionStorage.getItem("multiSearchErrorCount"));

    this.dataSource = new MatTableDataSource(this.model);
    this.dataSource.paginator = this.paginator;

    this.addFilterPredicate();
    if (this.selectedCardIndex == 2) {
      this.addOtherKeyFilterPredicate();
    }

    setTimeout(() => {
      this.paginator.pageIndex = JSON.parse(sessionStorage.getItem("pageIndex"));
      this.paginator.pageSize = JSON.parse(sessionStorage.getItem("pageSize"));
      this.dataSource.paginator = this.paginator;
      this.applyFilter(this.filterValues.productionDescription, false);

      this.dataSource.sort = this.sort;

      if (sessionStorage.getItem("sortActive") != 'undefined') {
        let sortColumn = JSON.parse(sessionStorage.getItem("sortActive"));
        this.sort.active = JSON.parse(sessionStorage.getItem("sortActive"));
        let sortDirection = JSON.parse(sessionStorage.getItem("sortDirection"));
        this.sort.direction = JSON.parse(sessionStorage.getItem("sortDirection"));

        this.dataSource.sort = this.sort;

        this.sort.sort({ id: null, start: 'asc', disableClear: false });
        (this.sort.sortables.get(sortColumn))['_setAnimationTransitionState']({ toState: 'active' });

        //Need to refactore this
        switch (sortColumn) {
          case "gtin": {
            if (sortDirection == "asc") {
              this.GTINColumn.nativeElement.click()
            }
            if (sortDirection == "desc") {
              this.GTINColumn.nativeElement.click()
              this.GTINColumn.nativeElement.click()
            }
            break;
          }
          case "description": {
            if (sortDirection == "asc") {
              this.ProductDescriptionColumn.nativeElement.click()
            }
            if (sortDirection == "desc") {
              this.ProductDescriptionColumn.nativeElement.click()
              this.ProductDescriptionColumn.nativeElement.click()
            }
            break;
          }
        }
      }

    }, 1)
  }
  getCountryCodeByName(countryName: string): string {
    let foundCountryCode = '---';
    if(this.countryList!=null){
      for (const country of this.countryList) {
        if (country.alpha3 === countryName || country.key === countryName || country.numeric == countryName) {
          foundCountryCode = country.value;
          break; // exit the loop once the country is found
        }
      }
    }
    return foundCountryCode;
  }

  public OnSearchGlnClick(glns: string, triggerLanguageChange: boolean) {
    this.sharedService.setShowGlnList(!triggerLanguageChange);
    this.sharedService.setShowOtherKeyList(false);
    this.sharedService.setShowGtinList(false);
    this.sharedService.setshowCompanyList(false);
    this.errorWithMultiSearchCount = 0
    this.clearFilter();

    if (this.tabToggleRef)
      this.tabToggleRef.hide = true;

    if (this.sort && this.sort.active != null) {
      this.sort.sort({ id: null, start: 'asc', disableClear: false });
      //(this.sort.sortables.get(this.sort.active))['_setAnimationTransitionState']({ toState: 'active' });
    }

    this.arrErrorMessages = new Array();
    this.CheckdigitErrorMessage = new CheckdigitError();
    if (!glns) {
      this.dataSource = null;
      this.glnAttribute = {};

      this.glnCount = 1;
  
      this.arrErrorMessages.push(this.validationService.translateErrorCodes("", "E021", "gtin"));
      return;
    }
    this.glnAttribute = null;
    this.gtinAttribute = null;
    this.otherKeyAttribute = null;
    this.dataSource = null;

    this.searchGlns = glns;
    var self = this;
    var singleGln = "";
    var arr = glns.split(',').filter((item: string) => {
      item = item.trim();
      if (item == '') {
        return false;
      }
      else {
        singleGln = item;
        return true;
      }
    }).map((item: string) => {
      return self.validationService.padGlnLength(item.replace(/\s/g, ""));
    });

    this.glnCount = arr.length;

    if (this.glnCount == 1) {
      var message = this.validationService.checkHaveMinLength(arr[0]);
      this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';


      if (this.errorMessage == '') {
        message = this.validationService.checkContainsChar(arr[0]);
        this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
      }
      if (this.errorMessage == '') {
        this.validationResponse = this.validationService.checkGlnLength(arr[0]);
        message = this.validationResponse.errorMessage;
        this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
        arr[0] = this.validationResponse.gln;
      }
      if (this.errorMessage == '') {
        var Checkdigitentered = arr[0].slice(-1);
        var checkdigitCalculated = this.validationService.GetCheckDigit(singleGln);
        var gtinWithoutCheckDgit = arr[0].substring(0, arr[0].length - 1);
        if (Checkdigitentered != checkdigitCalculated) {
          this.CheckdigitErrorMessage.CheckdigitEntered = Checkdigitentered;
          this.CheckdigitErrorMessage.CheckdigitCalculated = checkdigitCalculated;
          this.CheckdigitErrorMessage.GtinWithoutCheckDgit = gtinWithoutCheckDgit;
          this.CheckdigitErrorMessage.GtinSuggested = gtinWithoutCheckDgit + this.CheckdigitErrorMessage.CheckdigitCalculated;
          this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = "error_mesage_CheckDigit_suggestion";
        }
      }

    }
    else {
      message = this.validationService.checkGLNCount(this.glnCount);
      this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
    }
    // single gtin or more than 500 gtins or 0 gtins & basic client validation failed
    //no need to make api call
    if ((this.errorMessage != '' || this.CheckdigitErrorMessage.CheckdigitCalculated > 0) && (this.glnCount == 1 || this.glnCount > 500 || this.glnCount == 0)) {
      this.arrErrorMessages.push(this.errorMessage);
      this.dataSource = null;
      this.glnAttribute = {};
      if (this.tabToggleRef)
        this.tabToggleRef.hide = true;

      this.canDisplayAttributesSection = false;
      return;
    }

    setTimeout(() => this.loaderService.loader = true);

    this.sharedService.getGlns(arr).subscribe(response => {

      this.exportModel = JSON.parse(JSON.stringify(response));
      this.model = response;

      if (this.model.length == 0) {
        var message = this.translate.instant("There_are_no_product_attributes_available");
        this.arrErrorMessages.push(message);
      }
      if (this.glnCount == 1) {
        this.model.forEach(element => {

          var arrCountryCodes = new Array();
          var arrNetContents = new Array();

          if (element.validationErrors) {

            element.validationErrors[0].errors.forEach(error => {
              var errorMessage_translation = this.validationService.translateErrorCodes(element.gln, error.errorCode, "gln");

              var errors = errorMessage_translation.split('=');
              errors.forEach(errorText => {

                this.arrErrorMessages.push(errorText);
              });

            });

            this.errorMessage = element.validationErrors[0].errorMessage = this.arrErrorMessages.join(' ')
          }
          if (element.countryOfSaleCode) {
            element.countryOfSaleCode.forEach(countryOfSaleCode => {
              var countries = JSON.parse(localStorage.getItem("countries"));
              var countryName = countries.find(c => c.numeric === countryOfSaleCode.numeric)
                && countries.find(c => c.numeric === countryOfSaleCode.numeric).country;
              if (countryName && countryName.trim() != '') {
                arrCountryCodes.push(countryName);
              }
            });

            element.countryOfSaleCode[0].countryOfSaleCodes = arrCountryCodes.join(';  ');
          }
          if (element.netContent) {
            element.netContent.forEach(netContent => {
              var unitCodes = JSON.parse(localStorage.getItem("unitCodes"));
              var unitCodeDescription = unitCodes.find(c => c.unitCode === netContent.unitCode)
                && unitCodes.find(c => c.unitCode === netContent.unitCode).description;
              arrNetContents.push(netContent.value + ' ' + unitCodeDescription);
            });
            element.netContent[0].netContents = arrNetContents;

          }
          if (element.dateUpdated) {
            var pipe = new DatePipe('en-US');
            element.dateUpdated = pipe.transform(element.dateUpdated);
          }
          if (element.gpcCategoryCode) {
            this.gpcCategoryCodeValue = element.gpcCategoryCode;
            var preferedGPCLanguageCode = localStorage.getItem("preferedGPCLanguageCode");
            this.sharedService.getGPCdescription(element.gpcCategoryCode, preferedGPCLanguageCode).subscribe(result => {
              if (element.gpcCategoryCode != result) { element.gpcCategoryCode = `${element.gpcCategoryCode} - ${result}`; }
              else { element.gpcCategoryCode = result; }
            })

          }

          this.canDisplayAttributesSection = true;
          this.tabToggleRef.hide = false;

          if (element.licenseeName) {
            element.addPeriod = element.licenseeName.trim().lastIndexOf('.') == (element.licenseeName.trim().length - 1);
          }
          else {
            element.addPeriod = false;
          }
        });

        let updatedGlnAttribute = Object.assign({}, this.model[0]);
        if (updatedGlnAttribute.linkset) {
          delete updatedGlnAttribute.linkset;
          updatedGlnAttribute.linkset = this.model[0].linkset;
          this.glnAttribute = updatedGlnAttribute;
        }
        else {
          this.glnAttribute = this.model[0];
        }
        if (!this.glnAttribute.glnRecords) {
          if (this.tabToggleRef)
            this.tabToggleRef.hide = true;
        }

      }
      else {
        this.model.forEach(element => {
          var errorMessage = "";
          this.arrErrorMessages = [];
          if (element.validationErrors) {

            element.validationErrors[0].errors.forEach(error => {
              errorMessage = errorMessage + ' ' + error.message;
              var errorMessage_translation = this.validationService.translateErrorCodes(element.gln, error.errorCode, "gln");
              if (errorMessage_translation.indexOf(' E039contactGS1')) {
                var errorE039 = this.validationService.getErrorMessage(element.gln, error.errorCode);
                errorMessage_translation = errorMessage_translation.replace('E039contactGS1', errorE039);
              }
              this.arrErrorMessages.push(errorMessage_translation.replace('=', ''));
            });

            element.validationErrors[0].errorMessage = errorMessage;
            element.validationErrors[0].arrErrorMessages = this.arrErrorMessages;

            this.errorWithMultiSearchCount++;
          }
          else {
            if (element.hasOwnProperty('productDescription'))
              element.description = element.productDescription[0].value;
          }

          element.canDisplayAttributesSection = true;

          if (element.licenseeName) {
            element.addPeriod = element.licenseeName.trim().lastIndexOf('.') == (element.licenseeName.trim().length - 1);
          }
          else {
            element.addPeriod = false;
          }
        });

      }

      var glns = new Array();
      for (var i = 0; i < this.model.length; i++) {
        if (this.model[i].glnRecords != null && this.model[i].glnRecords.length > 0) {
          for (var j = 0; j < this.model[i].glnRecords.length; j++) {
            var gln = this.model[i].glnRecords[j];
            gln.canDisplayAttributesSection = this.model[i].canDisplayAttributesSection;
            gln.addPeriod = this.model[i].addPeriod;
            gln.gs1Licence = this.model[i].gs1Licence;
            gln.linkset = this.model[i]?.linkset;
            gln.endOfDescription = "";
            if (gln.address != null && gln.address.length > 0 && gln.address[0].addressLocality != null) {
              gln.endOfDescription += gln.address[0].addressLocality.value + " ";
            }

            if (gln.address != null && gln.address.length > 0 && gln.address[0].countryCode != null) {
              gln.endOfDescription += gln.address[0].countryCode;
            }

            switch (gln.glnType) {
              case 'LEGAL_ENTITY':
                gln.glnTypeLabel = "Legal Entity";
                gln.description = gln.organizationName[0].value + ' ' + gln.endOfDescription + ' ' + gln.glnTypeLabel;
                break;
              case 'FIXED_PHYSICAL_LOCATION':
                gln.glnTypeLabel = "Fixed Physical Location";
                gln.description = gln.physicalLocationName[0].value + ' ' + gln.endOfDescription + ' ' + gln.glnTypeLabel;
                break;
              case 'MOBILE_PHYSICAL_LOCATION':
                gln.glnTypeLabel = "Mobile Physical Location";
                gln.description = gln.physicalLocationName[0].value + ' ' + gln.endOfDescription + ' ' + gln.glnTypeLabel;
                break;
              case 'DIGITAL_LOCATION':
                gln.glnTypeLabel = "Digital Location";
                gln.description = gln.digitalLocationName[0].value + ' ' + gln.endOfDescription + ' ' + gln.glnTypeLabel;
                break;
              case 'FUNCTION':
                gln.glnTypeLabel = "Function";
                gln.description = gln.department[0].value + ' ' + gln.endOfDescription + ' ' + gln.glnTypeLabel;
                break;
            }
            glns.push(gln);
          }
        }

        if (this.model[i].validationErrors) {
          var glnError = this.model[i];
          glnError.descriptionError = "";
          if (glnError.validationErrors[0].arrErrorMessages) {
            for (var l = 0; l < glnError.validationErrors[0].arrErrorMessages.length; l++) {
              if (glnError.validationErrors[0].arrErrorMessages[l].indexOf("E039") > 0) {
                glnError.validationErrors[0].arrErrorMessages[l] = glnError.validationErrors[0].arrErrorMessages[l].replace("E039", "");
              }
              glnError.descriptionError += glnError.validationErrors[0].arrErrorMessages[l];
            }
          }

          if (glnError.validationErrors[0].errors) {
            for (var l = 0; l < glnError.validationErrors[0].errors.length; l++) {
              glnError.descriptionError += glnError.validationErrors[0].errors[l].message;
            }
          }

          glnError.glnTypeLabel = "";

          glns.push(glnError);
        }

        if (this.model[i].gs1Licence != null && (this.model[i].glnRecords == null || this.model[i].glnRecords.length == 0)) {
          var glnNotExist = this.model[i];
          glnNotExist.description = "";
          glnNotExist.endOfDescription = "";
          glnNotExist.organizationName = [{ "value": "" }];
          glnNotExist.glnTypeLabel = "";

          glns.push(glnNotExist);
        }
      }

      this.dataSource = new MatTableDataSource<any>(glns.reverse());
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1)

      this.addFilterPredicate();
      this.loaderService.loader = false;

    }, error => {
      console.log(error);
      this.loaderService.loader = false;
    });
  }

  public OnSearchGTINClick(gtins: string, triggerLanguageChange: boolean) {
    this.sharedService.setShowGtinList(!triggerLanguageChange);
    this.sharedService.setShowOtherKeyList(false);
    this.sharedService.setshowCompanyList(false);
    this.errorWithMultiSearchCount = 0
    this.clearFilter();

    if (this.tabToggleRef)
      this.tabToggleRef.hide = true;

    if (this.sort && this.sort.active != null) {
      this.sort.sort({ id: null, start: 'asc', disableClear: false });
      //(this.sort.sortables.get(this.sort.active))['_setAnimationTransitionState']({ toState: 'active' });
    }

    this.arrErrorMessages = new Array();
    this.CheckdigitErrorMessage = new CheckdigitError();
    if (!gtins) {
      this.dataSource = null;
      this.gtinAttribute = {};
      this.gtinCount = 1;
  
      this.arrErrorMessages.push(this.validationService.translateErrorCodes("", "E021", "gtin"));
      return;
    }
    this.gtinAttribute = null;
    this.glnAttribute = null;

    this.searchGtins = gtins;
    var self = this;
    var singleGtin = "";
    var arr = gtins.split(',').filter((item: string) => {
      item = item.trim();
      if (item == '') {
        return false;
      }
      else {
        singleGtin = item;
        return true;
      }
    }).map((item: string) => {
      return self.validationService.padLength(item.replace(/\s/g, ""));
    });

    this.gtinCount = arr.length;

    if (this.gtinCount == 1) {
      var message = this.validationService.checkHaveMinLength(arr[0]);
      this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';


      if (this.errorMessage == '') {
        message = this.validationService.checkContainsChar(arr[0]);
        this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
      }
      if (this.errorMessage == '') {
        this.validationResponse = this.validationService.checkLength(arr[0]);
        message = this.validationResponse.errorMessage;
        this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
        arr[0] = this.validationResponse.gtin;
      }
      if (this.errorMessage == '') {
        var Checkdigitentered = arr[0].slice(-1);
        var checkdigitCalculated = this.validationService.GetCheckDigit(singleGtin);
        var gtinWithoutCheckDgit = arr[0].substring(0, arr[0].length - 1);
        if (Checkdigitentered != checkdigitCalculated) {
          this.CheckdigitErrorMessage.CheckdigitEntered = Checkdigitentered;
          this.CheckdigitErrorMessage.CheckdigitCalculated = checkdigitCalculated;
          this.CheckdigitErrorMessage.GtinWithoutCheckDgit = gtinWithoutCheckDgit;
          this.CheckdigitErrorMessage.GtinSuggested = gtinWithoutCheckDgit + this.CheckdigitErrorMessage.CheckdigitCalculated;
          this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = "error_mesage_CheckDigit_suggestion";
        }
      }

    }
    else {
      message = this.validationService.checkGTINCount(this.gtinCount);
      this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
    }
    //TODO: verify below condition and remove if we do not perform client validation
    // as a quick fix changed the condition from this.gtinCount  to this.glnCount similar to GLN search logic

    // single gtin or more than 500 gtins or 0 gtins & basic client validation failed
    //no need to make api call
    if ((this.errorMessage != '' || this.CheckdigitErrorMessage.CheckdigitCalculated > 0) && (this.gtinCount == 1 || this.gtinCount > 500 || this.gtinCount == 0)) {
      this.arrErrorMessages.push(this.errorMessage);
      this.dataSource = null;
      this.gtinAttribute = {};
      if (this.tabToggleRef)
        this.tabToggleRef.hide = true;

      this.canDisplayAttributesSection = false;
      return;
    }

    setTimeout(() => this.loaderService.loader = true);

    this.sharedService.getGtins(arr).subscribe(response => {

      this.exportModel = JSON.parse(JSON.stringify(response));
      this.model = response;

      if (this.model.length == 0) {
        var message = this.translate.instant("There_are_no_product_attributes_available");
        this.arrErrorMessages.push(message);
      }
      if (this.gtinCount == 1) {
        this.model.forEach(element => {

          var arrCountryCodes = new Array();
          var arrNetContents = new Array();

          if (element.validationErrors) {

            element.validationErrors[0].errors.forEach(error => {
              var errorMessage_translation = this.validationService.translateErrorCodes(element.gtin, error.errorCode, "gtin");

              var errors = errorMessage_translation.split('=');
              errors.forEach(errorText => {

                this.arrErrorMessages.push(errorText);
              });

            });

            this.errorMessage = element.validationErrors[0].errorMessage = this.arrErrorMessages.join(' ')
          }
          if (element.countryOfSaleCode) {
            element.countryOfSaleCode.forEach(countryOfSaleCode => {
              var countries = JSON.parse(localStorage.getItem("countries"));
              var countryName = countries.find(c => c.numeric === countryOfSaleCode.numeric)
                && countries.find(c => c.numeric === countryOfSaleCode.numeric).country;
              if (countryName && countryName.trim() != '') {
                arrCountryCodes.push(countryName);
              }
            });

            element.countryOfSaleCode[0].countryOfSaleCodes = arrCountryCodes.join(';  ');
          }
          if (element.netContent) {
            element.netContent.forEach(netContent => {
              var unitCodes = JSON.parse(localStorage.getItem("unitCodes"));
              var unitCodeDescription = unitCodes.find(c => c.unitCode === netContent.unitCode)
                && unitCodes.find(c => c.unitCode === netContent.unitCode).description;
              arrNetContents.push(netContent.value + ' ' + unitCodeDescription);
            });
            element.netContent[0].netContents = arrNetContents;

          }
          if (element.dateUpdated) {
            var pipe = new DatePipe('en-US');
            element.dateUpdated = pipe.transform(element.dateUpdated);
          }
          if (element.gpcCategoryCode) {
            this.gpcCategoryCodeValue = element.gpcCategoryCode;
            var preferedGPCLanguageCode = localStorage.getItem("preferedGPCLanguageCode");
            this.sharedService.getGPCdescription(element.gpcCategoryCode, preferedGPCLanguageCode).subscribe(result => {
              if (element.gpcCategoryCode != result) { element.gpcCategoryCode = `${element.gpcCategoryCode} - ${result}`; }
              else { element.gpcCategoryCode = result; }
            })

          }
          if (element.hasOwnProperty('gtin') && (!element.hasOwnProperty('brandName') &&
            !element.hasOwnProperty('productDescription') &&
            !element.hasOwnProperty('productImageUrl') &&
            !element.hasOwnProperty('gpcCategoryCode') &&
            !element.hasOwnProperty('netContent') &&
            !element.hasOwnProperty('countryOfSaleCode')

          )) {
            this.canDisplayAttributesSection = false;
            this.tabToggleRef.hide = true;

          } else {
            this.canDisplayAttributesSection = true;
            this.tabToggleRef.hide = false;
          }

          if (element.licenseeName) {
            element.addPeriod = element.licenseeName.trim().lastIndexOf('.') == (element.licenseeName.trim().length - 1);
          }
          else {
            element.addPeriod = false;
          }
        });



        this.gtinAttribute = this.model[0];
        console.log(this.gtinAttribute)



      }
      else {
        this.model.forEach(element => {
          var errorMessage = "";
          this.arrErrorMessages = [];
          if (element.validationErrors) {

            element.validationErrors[0].errors.forEach(error => {
              errorMessage = errorMessage + ' ' + error.message;
              var errorMessage_translation = this.validationService.translateErrorCodes(element.gtin, error.errorCode, "gtin");
              if (errorMessage_translation.indexOf(' E039contactGS1')) {
                errorMessage_translation = errorMessage_translation.replace('E039contactGS1', '');
              }
              this.arrErrorMessages.push(errorMessage_translation.replace('=', ''));
            });

            element.validationErrors[0].errorMessage = errorMessage;
            element.validationErrors[0].arrErrorMessages = this.arrErrorMessages;

            this.errorWithMultiSearchCount++;
          }
          else {
            if (element.hasOwnProperty('productDescription'))
              element.description = element.productDescription[0].value;
          }

          if (element.hasOwnProperty('gtin') && (!element.hasOwnProperty('brandName') &&
            !element.hasOwnProperty('productDescription') &&
            !element.hasOwnProperty('productImageUrl') &&
            !element.hasOwnProperty('gpcCategoryCode') &&
            !element.hasOwnProperty('netContent') &&
            !element.hasOwnProperty('countryOfSaleCode')

          )) {
            element.canDisplayAttributesSection = false;

          } else { element.canDisplayAttributesSection = true; }

          if (element.licenseeName) {
            element.addPeriod = element.licenseeName.trim().lastIndexOf('.') == (element.licenseeName.trim().length - 1);
          }
          else {
            element.addPeriod = false;
          }
        });

      }

      this.dataSource = new MatTableDataSource<any>(this.model.reverse());
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1)

      this.addFilterPredicate();
      this.loaderService.loader = false;

    }, error => {
      console.log(error);
      this.loaderService.loader = false;
    });
  }

  public clientValidation (GS1key,selectedKey) { 
    switch(selectedKey){
      case 'sscc':
        if (GS1key.length != 18) {
          let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_SSCC");
          this.arrErrorMessages.push(message)
          return true
        }else if(!/^\d+$/.test(GS1key)){
          let message = this.translate.instant("this_GS1_key_can_only_contain_numeric_characters");
          this.arrErrorMessages.push(message)  
          return true  
        }else if(GS1key.length == 18){
          let keyWoCD = GS1key.slice(0, 17);
          let digit =  this.validationService.checkDigit(keyWoCD)
          if(GS1key.slice(-1) != digit ){
            this.CheckdigitErrorMessage.CheckdigitEntered = GS1key.slice(-1);
              this.CheckdigitErrorMessage.CheckdigitCalculated = digit;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = GS1key.slice(0, -1);
              this.CheckdigitErrorMessage.GtinSuggested = GS1key.slice(0, -1) + this.CheckdigitErrorMessage.CheckdigitCalculated;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_digit_of_your_gs1_key_is_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "sscc";
          }else { return false}  
          this.arrErrorMessages.push('')
          return true
        }
      case 'grai':
          if (GS1key.length > 30 || GS1key.length < 14) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_grai");
            this.arrErrorMessages.push(message)
            return true
          }else if(!/^\d+$/.test(GS1key.slice(0, 14))){
            let message = this.translate.instant("the_first_14_digits_of_this_gs1_key_can_only_contain_numeric_characters");
            this.arrErrorMessages.push(message)  
            return true
          } else if(GS1key[0] != '0'){
            let message = this.translate.instant("the_leading_digit_of_a_gs1_grai_must_be_0");
            this.arrErrorMessages.push(message)  
            return true
          } else {
            let digit =  this.validationService.checkDigit(GS1key.slice(0, 13))
            const regex = /^0\d{4}[a-zA-Z0-9_!"%&'()*+,\-.\/:;<=>?]{1,25}$/;
            if (!regex.test(GS1key)) {
              var message = this.translate.instant("the_number_you_have_entered_has_an_invalid_structure_to_be_a_grai");
              this.arrErrorMessages.push(message) 
            }
            if(digit != GS1key[13]){
              this.CheckdigitErrorMessage.CheckdigitEntered = GS1key[13];
              this.CheckdigitErrorMessage.CheckdigitCalculated = digit;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = GS1key.slice(0, 13);
              this.CheckdigitErrorMessage.remainingdigits = GS1key.slice(14);
              this.CheckdigitErrorMessage.GtinSuggested = GS1key.slice(0, 13) + this.CheckdigitErrorMessage.CheckdigitCalculated + this.CheckdigitErrorMessage.remainingdigits;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_digit_of_your_gs1_key_is_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "grai";
            }else { return false}
            this.arrErrorMessages.push('')  
            return true
          }
      case 'giai':
          if (GS1key.length > 30 || GS1key.length < 5) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_giai");
            this.arrErrorMessages.push(message)
            return true
          } else if(!/^\d{4}[a-zA-Z0-9_!"%&'()*+,-.\/:;<=>?]{1,26}$/.test(GS1key)){
            let message = this.translate.instant("the_number_you_have_entered_has_an_invalid_structure_to_be_a_giai");
            this.arrErrorMessages.push(message)
            return true
          }else { return false}
      case 'gsrn':
          if (GS1key.length != 18) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_gsrn");
            this.arrErrorMessages.push(message)
            return true
          }else if(!/^\d+$/.test(GS1key)){
            let message = this.translate.instant("this_GS1_key_can_only_contain_numeric_characters");
            this.arrErrorMessages.push(message)  
            return true  
          }else if(GS1key.length == 18){
            let keyWoCD = GS1key.slice(0, 17);
            let digit =  this.validationService.checkDigit(keyWoCD)
            if(digit != GS1key.slice(-1)){
              this.CheckdigitErrorMessage.CheckdigitEntered = GS1key.slice(-1);
              this.CheckdigitErrorMessage.CheckdigitCalculated = digit;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = keyWoCD;
              this.CheckdigitErrorMessage.GtinSuggested = keyWoCD + this.CheckdigitErrorMessage.CheckdigitCalculated;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_digit_of_your_gs1_key_is_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "gsrn";
            }else { return false}
            this.arrErrorMessages.push('')
            return true
          } else  { return false}
      case 'gdti':
          if (GS1key.length > 30 || GS1key.length < 13) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_gdti");
            this.arrErrorMessages.push(message)
            return true
          }else if(!/^\d+$/.test(GS1key.slice(0, 13))){
            let message = this.translate.instant("the_first_13_digits_of_this_gs1_key_can_only_contain_numeric_characters");
            this.arrErrorMessages.push(message)  
            return true
          }else if (!/^\d{13}[a-zA-Z0-9_!"%&'()*+,\-.\/:;<=>?]{0,17}$/.test(GS1key)){
            let message = this.translate.instant("the_number_you_have_entered_has_an_invalid_structure_to_be_a_gdti");
            this.arrErrorMessages.push(message)  
            return true
          }else {
            let digit =  this.validationService.checkDigit(GS1key.slice(0, 12))
            if(GS1key[12] != digit){
              this.CheckdigitErrorMessage.CheckdigitEntered = GS1key[12];
              this.CheckdigitErrorMessage.CheckdigitCalculated = digit;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = GS1key.slice(0, 12);
              this.CheckdigitErrorMessage.remainingdigits = GS1key.slice(13);
              this.CheckdigitErrorMessage.GtinSuggested = GS1key.slice(0, 12) + this.CheckdigitErrorMessage.CheckdigitCalculated + this.CheckdigitErrorMessage.remainingdigits;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_digit_of_your_gs1_key_is_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "gdti";
            } else { return false}

            this.arrErrorMessages.push('')  
            return true
          }
      case 'ginc':
          if (GS1key.length > 30 || GS1key.length < 5) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_ginc");
            this.arrErrorMessages.push(message)
            return true
          }else if (!/^\d{4}[a-zA-Z0-9_!"%&'()*+,\-.\/:;<=>?]{1,26}$/.test(GS1key)){
            let message = this.translate.instant("the_number_you_have_entered_has_an_invalid_structure_to_be_a_ginc");
            this.arrErrorMessages.push(message)  
            return true
          } else { return false}
      case 'gsin':
          if (GS1key.length != 17) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_gsin");
            this.arrErrorMessages.push(message)
            return true
          }else if(!/^\d+$/.test(GS1key)){
            let message = this.translate.instant("this_GS1_key_can_only_contain_numeric_characters");
            this.arrErrorMessages.push(message)  
            return true  
          }else if(GS1key.length == 17){
            let keyWoCD = GS1key.slice(0, 16);
            let digit =  this.validationService.checkDigit(keyWoCD)
            if(digit != GS1key.slice(-1)){
              this.CheckdigitErrorMessage.CheckdigitEntered = GS1key.slice(-1);
              this.CheckdigitErrorMessage.CheckdigitCalculated = digit;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = keyWoCD;
              this.CheckdigitErrorMessage.GtinSuggested = keyWoCD + this.CheckdigitErrorMessage.CheckdigitCalculated;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_digit_of_your_gs1_key_is_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "gsin";
            }else { return false}
            this.arrErrorMessages.push('')
            return true
          }
      case 'gcn':
          let gcn = GS1key.slice(0, 12);
          if (GS1key.length > 25 || GS1key.length < 13) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_gcn");
            this.arrErrorMessages.push(message)
            return true
          }else if(!/^\d+$/.test(GS1key)){
            let message = this.translate.instant("this_GS1_key_can_only_contain_numeric_characters");
            this.arrErrorMessages.push(message)  
            return true
          }else {
            let digit =  this.validationService.checkDigit(GS1key.slice(0, 12))
            gcn = gcn + digit + GS1key.slice(13)
            if(GS1key[12] != digit){
              this.CheckdigitErrorMessage.CheckdigitEntered = GS1key[12];
              this.CheckdigitErrorMessage.CheckdigitCalculated = digit;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = GS1key.slice(0, 12);
              this.CheckdigitErrorMessage.remainingdigits = GS1key.slice(13);
              this.CheckdigitErrorMessage.GtinSuggested = GS1key.slice(0, 12) + this.CheckdigitErrorMessage.CheckdigitCalculated + this.CheckdigitErrorMessage.remainingdigits;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_digit_of_your_gs1_key_is_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "gcn";
            } else { return false}
            this.arrErrorMessages.push('')  
            return true
          }
      case 'cpid':
          if (GS1key.length > 30 || GS1key.length < 5) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_cpid");
            this.arrErrorMessages.push(message)
            return true
          }else if (!/^\d{4}[0-9A-Z#-\/]{1,26}$/.test(GS1key)){
            let message = this.translate.instant("the_number_you_have_entered_has_an_invalid_structure_to_be_a_cpid");
            this.arrErrorMessages.push(message)  
            return true
          } else { return false}
      case 'gmn':
          this.gtinAttribute=true
          if (GS1key.length > 25 || GS1key.length < 7) {
            let message = this.translate.instant("the_number_you_have_entered_is_not_a_valid_length_to_be_a_gmn");
            this.arrErrorMessages.push(message)
            return true
          } else if (!/^\d{4}[a-zA-Z0-9_!"%&'()*+,\-.\/:;<=>?]{1,21}$/.test(GS1key)){
            let message = this.translate.instant("the_number_you_have_entered_has_an_invalid_structure_to_be_a_gmn");
            this.arrErrorMessages.push(message)  
            return true
          }
          else {
            let newGtin = GS1key.slice(0, -2)
            let checkchar = GS1key.slice(-2)
            let chalculatedCheckChar = GMN.checkCharacters(newGtin);
            if(checkchar != chalculatedCheckChar){
              this.CheckdigitErrorMessage.CheckdigitEntered = checkchar;
              this.CheckdigitErrorMessage.CheckdigitCalculated = chalculatedCheckChar;
              this.CheckdigitErrorMessage.GtinWithoutCheckDgit = newGtin;
              this.CheckdigitErrorMessage.GtinSuggested = newGtin + this.CheckdigitErrorMessage.CheckdigitCalculated;
              this.CheckdigitErrorMessage.CheckdigitErrorMessageKey = this.translate.instant("the_check_characters_of_your_global_model_number_are_incorrect_did_you_mean");
              this.CheckdigitErrorMessage.KeyName = "gmn";
            }else { return false}
            this.arrErrorMessages.push('')
            return true
          }
      }
  }

  public OnSearchOtherKeysClick(gtins: string, triggerLanguageChange: boolean) {
    this.sharedService.setShowOtherKeyList(!triggerLanguageChange);
    this.sharedService.setShowGtinList(false);
    this.sharedService.setshowCompanyList(false);
    this.sharedService.setShowGlnList(false);

    this.errorWithMultiSearchCount = 0
    this.clearFilter();

    if (this.tabToggleRef)
      this.tabToggleRef.hide = true;

    if (this.sort && this.sort.active != null) {
      this.sort.sort({ id: null, start: 'asc', disableClear: false });
      //(this.sort.sortables.get(this.sort.active))['_setAnimationTransitionState']({ toState: 'active' });
    }

    this.arrErrorMessages = new Array();

    if (!this.selectedKey) {
      this.selectedKey = ' ';
    }

    if (!gtins) {
      this.dataSource = null;
      this.otherKeyAttribute = new Array();
      this.otherKeyAttribute = null;
      //return;
    }

    this.gtinAttribute = null;
    this.glnAttribute = null;
    // this.otherKeyAttribute = null;

    this.searchGtins = gtins;
    var self = this;
    var singleGtin = "";
    var arr = gtins.split(',').filter((item: string) => {
      item = item.trim();
      if (item == '') {
        return false;
      }
      else {
        singleGtin = item;
        return true;
      }
    }).map((item: string) => {
      return item.trim();
    });

    this.otherKeyCount = arr.length;
    this.CheckdigitErrorMessage = new CheckdigitError();
    if (this.otherKeyCount == 1) {
     if(this.clientValidation(singleGtin,this.selectedKey)){
      return
     }
      var message = this.translate.instant("gs1_key_cannot_be_empty");
      this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
    }
    else {
      message = this.validationService.checkGTINCount(this.otherKeyCount);
      this.errorMessage = message.length > 1 ? this.translate.instant(message) : '';
    }


    setTimeout(() => this.loaderService.loader = true);

    this.sharedService.getOtherKeys(this.selectedKey, arr).subscribe(response => {

      this.exportModel = JSON.parse(JSON.stringify(response));
      this.model = response;
      if (this.model.length == 0) {
        var message = this.translate.instant("There_are_no_product_attributes_available");
        this.arrErrorMessages.push(message);
      }

      if (this.otherKeyCount == 1) {
        this.model.forEach(element => {

          var arrCountryCodes = new Array();
          var arrNetContents = new Array();

          if (element.validationErrors) {

            element.validationErrors[0].errors.forEach(error => {

              var keyValue = element[this.selectedKey] ? element[this.selectedKey] : element[this.selectedKey.toUpperCase()];

              var errorMessage_translation = this.validationService.translateErrorCodes(keyValue, error.errorCode, "otherkey");

              var errors = errorMessage_translation.split('=');
              errors.forEach(errorText => {

                this.arrErrorMessages.push(errorText);
              });

            });

            this.errorMessage = element.validationErrors[0].errorMessage = this.arrErrorMessages.join(' ')
          }
          if (element.countryOfSaleCode) {
            element.countryOfSaleCode.forEach(countryOfSaleCode => {
              var countries = JSON.parse(localStorage.getItem("countries"));
              var countryName = countries.find(c => c.numeric === countryOfSaleCode.numeric)
                && countries.find(c => c.numeric === countryOfSaleCode.numeric).country;
              if (countryName && countryName.trim() != '') {
                arrCountryCodes.push(countryName);
              }
            });

            element.countryOfSaleCode[0].countryOfSaleCodes = arrCountryCodes.join(';  ');
          }
          if (element.netContent) {
            element.netContent.forEach(netContent => {
              var unitCodes = JSON.parse(localStorage.getItem("unitCodes"));
              var unitCodeDescription = unitCodes.find(c => c.unitCode === netContent.unitCode)
                && unitCodes.find(c => c.unitCode === netContent.unitCode).description;
              arrNetContents.push(netContent.value + ' ' + unitCodeDescription);
            });
            element.netContent[0].netContents = arrNetContents;

          }
          if (element.dateUpdated) {
            var pipe = new DatePipe('en-US');
            element.dateUpdated = pipe.transform(element.dateUpdated);
          }
          if (element.gpcCategoryCode) {
            this.gpcCategoryCodeValue = element.gpcCategoryCode;
            var preferedGPCLanguageCode = localStorage.getItem("preferedGPCLanguageCode");
            this.sharedService.getGPCdescription(element.gpcCategoryCode, preferedGPCLanguageCode).subscribe(result => {
              if (element.gpcCategoryCode != result) { element.gpcCategoryCode = `${element.gpcCategoryCode} - ${result}`; }
              else { element.gpcCategoryCode = result; }
            })

          }

          if (element.licenseeName) {
            element.addPeriod = element.licenseeName.trim().lastIndexOf('.') == (element.licenseeName.trim().length - 1);
          }
          else {
            element.addPeriod = false;
          }
        });

        if (this.tabToggleRef)
          this.tabToggleRef.hide = true;

        this.otherKeyAttribute = this.model[0];
        this.otherKeyAttribute.HasDiscontinued = false;
      this.otherKeyAttribute.HasActive = false;
      this.otherKeyAttribute.HasInActive = false;
      this.otherKeyAttribute.HasOk = false;

      if (this.otherKeyAttribute.keyRecordStatus == 'OK')
      {
        this.otherKeyAttribute.HasOk = true;
      }
      if (this.otherKeyAttribute.keyRecordStatus == 'DISCONTINUED')
      {
        this.otherKeyAttribute.HasDiscontinued = true;
      }
      if (this.otherKeyAttribute.keyRecordStatus == 'ACTIVE')
      {
        this.otherKeyAttribute.HasActive = true;
      }
      if (this.otherKeyAttribute.keyRecordStatus == 'INACTIVE')
      {
        this.otherKeyAttribute.HasInActive = true;
      }

      }
      else {
        this.model.forEach(element => {
          var errorMessage = "";
          this.arrErrorMessages = [];
          element.glnTypeLabel = '';
          element.HasDiscontinued = false;
          element.HasActive = false;
          element.HasInActive = false;
          element.HasOk = false;
    
          if (element.keyRecordStatus == 'OK')
          {
            element.HasOk = true;
          }
          if (element.keyRecordStatus == 'DISCONTINUED')
          {
            element.HasDiscontinued = true;
          }
          if (element.keyRecordStatus == 'ACTIVE')
          {
            element.HasActive = true;
          }
          if (element.keyRecordStatus == 'INACTIVE')
          {
            element.HasInActive = true;
          }

          if (element.validationErrors) {

            element.validationErrors[0].errors.forEach(error => {

              var keyValue = element[this.selectedKey] ? element[this.selectedKey] : element[this.selectedKey.toUpperCase()];

              var errorMessage_translation = this.validationService.translateErrorCodes(keyValue, error.errorCode, "otherkey");
           
              var errors = errorMessage_translation.split('=');
              errors.forEach(errorText => {

                this.arrErrorMessages.push(errorText);
              });

            });

            // element.validationErrors[0].errors.forEach(error => {
            //   errorMessage = errorMessage + ' ' + error.message;
            //   var errorMessage_translation = this.validationService.translateErrorCodes(element.gtin, error.errorCode);
            //   if (errorMessage_translation.indexOf(' E039contactGS1')) {
            //     errorMessage_translation = errorMessage_translation.replace('E039contactGS1', '');
            //   }
            //   this.arrErrorMessages.push(errorMessage_translation.replace('=', ''));
            // });

            element.validationErrors[0].errorMessage = errorMessage;
            element.validationErrors[0].arrErrorMessages = this.arrErrorMessages;

            this.errorWithMultiSearchCount++;
          }
          else {
            if (element.hasOwnProperty('productDescription'))
              element.description = element.productDescription[0].value;
          }

          if (element.hasOwnProperty('gtin') && (!element.hasOwnProperty('brandName') &&
            !element.hasOwnProperty('productDescription') &&
            !element.hasOwnProperty('productImageUrl') &&
            !element.hasOwnProperty('gpcCategoryCode') &&
            !element.hasOwnProperty('netContent') &&
            !element.hasOwnProperty('countryOfSaleCode')

          )) {
            element.canDisplayAttributesSection = false;

          } else { element.canDisplayAttributesSection = true; }

          if (element.licenseeName) {
            element.addPeriod = element.licenseeName.trim().lastIndexOf('.') == (element.licenseeName.trim().length - 1);
          }
          else {
            element.addPeriod = false;
          }
        });

      }

      this.dataSource = new MatTableDataSource<any>(this.model.reverse());
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1)

      this.addOtherKeyFilterPredicate();
      this.loaderService.loader = false;

    }, error => {
      this.otherKeyAttribute = error;
      try {
        if (error.error.message) {
          if (error.error.errorCode = "E021")
          {
            error.error.message = this.validationService.translateErrorCodes("", "E021", "otherkey");
          }
          this.arrErrorMessages.push(error.error.message);
        }
        else {
          this.arrErrorMessages.push(this.validationService.translateErrorCodes("", "keyType", "otherkey"));
        }
      }
      catch (xe) { }

      console.log(error);
      this.loaderService.loader = false;
    });
  }

  clickSupportLink() {
    this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      window.open(currentMO.link, '_blank');

    }, err => { console.log(err); });
  }

  public CompanySearchClientValidation (companyName,streetAddress,postalCode,city,country) {
      this.companyCount = null;
      this.dataSource = null;
      this.arrErrorMessages = []
  
      // Validate Company Name
      if (!companyName.trim()) {
          this.arrErrorMessages.push(this.translate.instant("Please_Enter_A_Company_Name"));
      } else if (!/^(?=.*[𫚭\u4E00-\u9FFF\u3400-\u4DBF\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\uAC00-\uD7AF])|.{3,}$/.test(companyName)) {
          this.arrErrorMessages.push(this.translate.instant("company_name_must_be_at_least_3_characters_long"));
      }
      
       // validate country
       if(!country.trim()){
        this.arrErrorMessages.push(this.translate.instant("please_select_a_country"));
      }
  
      // Validate Street Address
      if (streetAddress) {
          if (streetAddress.length < 2) {
              this.arrErrorMessages.push(this.translate.instant("street_address_must_be_at_least_2_characters_long"));
          } else if (streetAddress.length > 100) {
              this.arrErrorMessages.push(this.translate.instant("street_address_can_be_max_100_characters_long"));
          }
      }
  
      // Validate Postal Code
      if (postalCode) {
          if (postalCode.length < 2) {
              this.arrErrorMessages.push(this.translate.instant("postal_code_must_be_at_least_2_characters_long"));
          } else if (postalCode.length > 10) {
              this.arrErrorMessages.push(this.translate.instant("postal_code_can_be_max_10_characters_long"));
          }
      }
  
      // Validate City
      if (city) {
          if (city.length < 2) {
              this.arrErrorMessages.push(this.translate.instant("city_must_have_a_minimum_of_2_characters"));
          } else if (city.length > 100) {
              this.arrErrorMessages.push(this.translate.instant("city_can_be_max_100_characters_long"));
          }
      }

      if(this.arrErrorMessages.length > 0){
        return true
      }else {
        return false
      }
  
  }
  public OnSearchCompanyClick(searchKeyword: string, triggerLanguageChange: boolean) {    
    this.sharedService.setshowCompanyList(!triggerLanguageChange);
    this.sharedService.setShowOtherKeyList(false);
    this.sharedService.setShowGlnList(false);
    this.sharedService.setShowGtinList(false);
    this.errorWithMultiSearchCount = 0
    this.clearFilter();

    if (this.tabToggleRef)
      this.tabToggleRef.hide = true;

    if (this.sort && this.sort.active != null) {
      this.sort.sort({ id: null, start: 'asc', disableClear: false });
    }

    this.arrErrorMessages = []

    if (!this.selectedKey) {
      this.selectedKey = ' ';
    }

    if (!searchKeyword) {
      this.dataSource = null;
      this.gtinAttribute = new Array();
      this.glnAttribute = null;
      //return;
    } else {
      this.companySearchKeyword = searchKeyword;
      this.countrySearchKey = this.selectedKey;
    }

    this.gtinAttribute = null;
    this.glnAttribute = null;

    this.searchGtins = searchKeyword;

    if(this.CompanySearchClientValidation(searchKeyword,this.street,this.postalcode,this.city,this.selectedKey)){
      return
    }
    
    setTimeout(() => this.loaderService.loader = true);
    var payload = {
      "licenseeName": searchKeyword,
      "country": this.selectedKey,
      "countryCode": this.selectedKey,
      "streetAddress": this.street,
      "postalCode": this.postalcode,
      "addressLocality": this.city,
      "size": 100

    }
    if (payload.country.toLowerCase() === 'all') {
      delete payload.countryCode;
    } else {
      delete payload.country;
    }


    this.sharedService.getCompanyies(payload).subscribe(response => {

      this.exportModel = JSON.parse(JSON.stringify(response));
      this.model = response;

      if (this.model.totalRecords == 0) {
        var message = this.translate.instant("Sorry_we_Couldn't_Find_Any_Results_For_Your_Search")
        this.arrErrorMessages.push(message);
        this.loaderService.loader = false
        return
      }
      this.model.licences.forEach(element => {
        element.canDisplayAttributesSection = false;
        element.glnTypeLabel = '';
        if (element.validationErrors) {

          element.validationErrors[0].errors.forEach(error => {
            var errorMessage_translation = this.validationService.translateErrorCodes(payload.licenseeName, error.errorCode, "company");
            var errors = errorMessage_translation.split('=');
            errors.forEach(errorText => {
              this.arrErrorMessages.push(errorText);
            });
          });
          this.errorMessage = element.validationErrors[0].errorMessage = this.arrErrorMessages.join(' ')
        }
      });


      this.dataSource = new MatTableDataSource<any>(this.model.licences);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.companyCount = this.model.totalRecords;

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1)

      this.addFilterPredicate();
      this.loaderService.loader = false;

    }, error => {
      this.dataSource = null;
      this.gtinAttribute = new Array();
      this.glnAttribute = null;
      this.gtinAttribute = error;
      try {
        if (error.error.validationErrors) {
          var translation = this.validationService.translateErrorCodes(payload.licenseeName, error.error.validationErrors[0].errors[0].errorCode, "company");
          this.arrErrorMessages.push(translation);
        }
        else {
          this.arrErrorMessages.push();
        }
      }
      catch (xe) { }

      console.log(error);
      this.loaderService.loader = false;
    });
  }

  get showList(): boolean {
    return this.sharedService.getShowGtinList();
  }
  get showGlnList(): boolean {
    return this.sharedService.getShowGlnnList();
  }
  get showCompanyList(): boolean {
    return this.sharedService.getshowCompanyList();
  }
  get showOtherKeyList(): boolean {
    return this.sharedService.getShowOtherKeyList();
  }

  //Navigate to Detailed Page
  public redirectToDetails(row) {
    localStorage.setItem(row.gtin, JSON.stringify(row));
    this.sharedService.setShowGtinList(false);
    this.router.navigate(['home/gtin-detail'], { queryParams: { GTIN: row.gtin } });

    sessionStorage.setItem("gtinlist", JSON.stringify(this.model));
    sessionStorage.setItem("exportGtinlist", JSON.stringify(this.exportModel));
    sessionStorage.setItem("gtinsearch", JSON.stringify(this.searchGtins));
    sessionStorage.setItem("pageIndex", JSON.stringify(this.paginator.pageIndex));
    sessionStorage.setItem("pageSize", JSON.stringify(this.paginator.pageSize));
    sessionStorage.setItem("filterValues", JSON.stringify(this.filterValues));
    sessionStorage.setItem("multiSearchErrorCount", JSON.stringify(this.errorWithMultiSearchCount));

    sessionStorage.setItem("sortActive", JSON.stringify(this.sort.active));
    sessionStorage.setItem("sortDirection", JSON.stringify(this.sort.direction));
  }
  public redirectToGlnDetails(row) {
    localStorage.setItem(row.gln, JSON.stringify(row));
    this.sharedService.setShowGlnList(false);
    this.router.navigate(['home/gtin-detail'], { queryParams: { GLN: row.gln } });

    sessionStorage.setItem("glnlist", JSON.stringify(this.model));
    sessionStorage.setItem("exportGtinlist", JSON.stringify(this.exportModel));
    sessionStorage.setItem("glnsearch", JSON.stringify(this.searchGlns));
    sessionStorage.setItem("pageIndex", JSON.stringify(this.paginator.pageIndex));
    sessionStorage.setItem("pageSize", JSON.stringify(this.paginator.pageSize));
    sessionStorage.setItem("filterValues", JSON.stringify(this.filterValues));
    sessionStorage.setItem("multiSearchErrorCount", JSON.stringify(this.errorWithMultiSearchCount));

    sessionStorage.setItem("sortActive", JSON.stringify(this.sort.active));
    sessionStorage.setItem("sortDirection", JSON.stringify(this.sort.direction));
  }
  public redirectToOtherKeyDetails(row) {

    const otherKeys = ['sscc', 'grai', 'giai', 'gsrn', 'gdti', 'ginc', 'gsin', 'gcn', 'cpid', 'gmn'];
    var otherKeyId;
    for (const key of otherKeys) {
      // Check if the key exists in the row object
      if (row.hasOwnProperty(key)) {
        // Replace row.gln with the corresponding value for the current key
        localStorage.setItem(row[key], JSON.stringify(row));
        otherKeyId = row[key];
        // You can break the loop here if you want to handle only the first matching key
        break;
      }
    }

    this.sharedService.setShowOtherKeyList(false);
    this.router.navigate(['home/gtin-detail'], { queryParams: { OtherKey: otherKeyId } });
    // TODO: analyze below and remove unwanted code
    sessionStorage.setItem("glnlist", JSON.stringify(this.model));
    sessionStorage.setItem("exportGtinlist", JSON.stringify(this.exportModel));
    sessionStorage.setItem("glnsearch", JSON.stringify(this.searchGlns));
    sessionStorage.setItem("pageIndex", JSON.stringify(this.paginator.pageIndex));
    sessionStorage.setItem("pageSize", JSON.stringify(this.paginator.pageSize));
    sessionStorage.setItem("filterValues", JSON.stringify(this.filterValues));
    sessionStorage.setItem("multiSearchErrorCount", JSON.stringify(this.errorWithMultiSearchCount));

    sessionStorage.setItem("sortActive", JSON.stringify(this.sort.active));
    sessionStorage.setItem("sortDirection", JSON.stringify(this.sort.direction));
  }
  public redirectToCompanyDetails(row) {

    this.sharedService.setshowCompanyList(false);
    localStorage.setItem(row.licenceKey, JSON.stringify(row));
    this.router.navigate(['home/gtin-detail'], { queryParams: { Company: row.licenceKey } });
    // TODO: analyze below and remove unwanted code
    sessionStorage.setItem("glnlist", JSON.stringify(this.model));
    sessionStorage.setItem("exportGtinlist", JSON.stringify(this.exportModel));
    sessionStorage.setItem("glnsearch", JSON.stringify(this.searchGlns));
    sessionStorage.setItem("pageIndex", JSON.stringify(this.paginator.pageIndex));
    sessionStorage.setItem("pageSize", JSON.stringify(this.paginator.pageSize));
    sessionStorage.setItem("filterValues", JSON.stringify(this.filterValues));
    sessionStorage.setItem("multiSearchErrorCount", JSON.stringify(this.errorWithMultiSearchCount));

    sessionStorage.setItem("sortActive", JSON.stringify(this.sort.active));
    sessionStorage.setItem("sortDirection", JSON.stringify(this.sort.direction));
  }

  //Product Description Filter
  applyFilter(filterValue: string, resetPageIndex = true) {

    if (filterValue.length >= 2) {
      this.filterValues.productionDescription = filterValue.trim().toLowerCase();
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    else {
      this.filterValues.productionDescription = '';
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    if (this.dataSource.paginator && resetPageIndex == true) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Is Complete filter
  private applyFilterIsComplete(selectedValue) {
    this.filterValues.isComplete = selectedValue.checked;
    this.dataSource.filter = JSON.stringify(this.filterValues);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private clearFilter() {
    this.filterValues.isComplete = false;
    this.filterValues.productionDescription = '';
  }

  //Datasource Filter Predicate
  private addFilterPredicate() {
    this.dataSource.filterPredicate = function (data, filterString: string): boolean {
      let filters = JSON.parse(filterString);

      if (data.description) {
        if (filters.isComplete == true) {
          return data.description.toLowerCase().includes(filters.productionDescription) && data.isComplete == filters.isComplete;
        }
        else {
          return data.description.toLowerCase().includes(filters.productionDescription);
        }
      }
      else {
        if (filters.productionDescription == '' && filters.isComplete == false) {
          return data;
        }
      }
    };
  }

  //Datasource Filter Predicate
  private addOtherKeyFilterPredicate() {
    this.dataSource.filterPredicate = function (data, filterString: string): boolean {
      let filters = JSON.parse(filterString);

      if (data.gs1Licence && (data.gs1Licence.licensingMO.moName || data.gs1Licence.licenseeName)) {
        return data.gs1Licence.licensingMO.moName.toLowerCase().includes(filters.productionDescription) || data.gs1Licence.licenseeName.toLowerCase().includes(filters.productionDescription);
      }

      else {
        if (filters.productionDescription == '') {
          return data;
        }
      }
    };
  }

  updateGPCTextAndErrorMessages() {
    //TODO: better way to trigger language change event
    let gtinParam, glnParam, companyParam, otherKeyParam = null;
    this.route.queryParamMap
      .subscribe((params) => {
        gtinParam = params.get('GTIN');
        glnParam = params.get('GLN');
        otherKeyParam = params.get('OtherKey');
        companyParam = params.get('Company');

      });

    if (this.selectedCardIndex == 0) {
      this.OnSearchGTINClick(this.searchGtins, gtinParam?.length > 0);
    }
    if (this.selectedCardIndex == 1) {
      this.OnSearchGlnClick(this.searchGlns, glnParam?.length > 0)
    }
    if (this.selectedCardIndex == 2) {
      this.OnSearchOtherKeysClick(this.searchGtins, otherKeyParam?.length > 0)
    }
    if (this.selectedCardIndex == 3) {
      this.OnSearchCompanyClick(this.companySearchKeyword, companyParam?.length > 0)
    }
  }

  private exportSearchResult() {
    let theJSON = JSON.stringify(this.exportModel);
    let blob = new Blob([theJSON], {
      type: 'text/json'
    });

    fileSaver.saveAs(blob, environment.exportFileName);
  }
  downloadPDF() {
    this._snackBar.open(this.translate.instant('Generating_PDF_Message'), this.translate.instant('Close'), {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    this.disablePDFButton = true;
    var gtinData = JSON.stringify(this.exportModel.filter(d => d.validationErrors == null));
    var lang = localStorage.getItem("preferedLanguageCode");
    this.sharedService.downloadPdf(gtinData, lang)
      .subscribe(
        (response) => {
          console.log(response);
          this.TouDocument = response as TouDocument;
          const arrayBuffer = this.sharedService.base64ToArrayBuffer(this.TouDocument.FileBytes);
          this.sharedService.createAndDownloadBlobFile(arrayBuffer, "Gtin Report", 'pdf');
          this.disablePDFButton = false;
          this._snackBar.dismiss();
        },
        error => {
          this.disablePDFButton = false;
          this._snackBar.dismiss();
          this._snackBar.open(this.translate.instant('Generating_PDF_Message_error'), "close", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'mat-snack-bar-error-container'
          });
        },
        () => {

        }
      )
  }
  onTabChanged($event) {
    //$event.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
