import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilityFunctions } from 'src/app/helpers/common-util-functions';
import { ValidationResponse } from 'src/app/models/validationResponse';
import { AuthService } from 'src/services/auth.service';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'gtin-detail',
  templateUrl: './gtin-detail.component.html',
  styleUrls: ['./gtin-detail.component.scss']
})

export class GtinDetailComponent implements OnInit {

  gtinData: any;
  glnData: any;
  otherKeysData: any;
  companyData: any;
  @ViewChild('matTabGroup', { static: true }) matTabGroup: ElementRef;
  canDisplayAttributesSection: boolean;
  validationResponse: ValidationResponse;
  gpcCategoryCodeValue: string;
  arrErrorMessages: Array<string> = new Array();
  ProductInfo_Label: string;
  ComapnyInfo_Label: string;
  Additional_Information: string;
  tabIndex: number;
  hasGlnInfo: boolean;
  //hideHeader: boolean;

  constructor(private sharedService: SharedService, private router: Router, private route: ActivatedRoute,
    public authService: AuthService, breakpointObserver: BreakpointObserver) {
    this.route.queryParamMap
      .subscribe((params) => {
        this.gtinData = undefined;
        this.glnData = undefined;
        this.otherKeysData = undefined;
        this.companyData = undefined;


        // Assuming params is an object with get method
        const gtinParam = params.get('GTIN');
        const glnParam = params.get('GLN');
        const companyParam = params.get('Company');
        const otherKeyParam = params.get('OtherKey');

        switch (true) {
          case gtinParam !== null:
            this.gtinData = JSON.parse(localStorage.getItem(gtinParam));
            break;

          case glnParam !== null:
            this.glnData = JSON.parse(localStorage.getItem(glnParam));
            var glnDataObject = { "glnRecordStatus": this.glnData.glnRecordStatus, "glnRecords": new Array(), "canDisplayAttributesSection": true, "gs1Licence": {},"linkset":{} };
            glnDataObject.glnRecords.push(this.glnData);
            glnDataObject.canDisplayAttributesSection = this.glnData.canDisplayAttributesSection;
            glnDataObject.gs1Licence = this.glnData.gs1Licence;
            glnDataObject.linkset = this.glnData?.linkset;
            this.hasGlnInfo = true;
            if (glnDataObject && glnDataObject.glnRecords != null && glnDataObject.glnRecords.length > 0) {
              this.canDisplayAttributesSection = glnDataObject.glnRecords[0].canDisplayAttributesSection;
              if (glnDataObject.glnRecords[0].glnTypeLabel == "") {
                this.hasGlnInfo = false;
              }
            }
            this.glnData = glnDataObject;
            break;

          case otherKeyParam !== null:
            this.otherKeysData = JSON.parse(localStorage.getItem(otherKeyParam));
            var glnDataObjectOtherKey = { "HasInActive": this.otherKeysData.HasInActive,  "HasActive": this.otherKeysData.HasActive, "HasDiscontinued": this.otherKeysData.HasDiscontinued,  "HasOk": this.otherKeysData.HasOk,  "glnRecords": new Array(), "canDisplayAttributesSection": false, "gs1Licence": {},"linkset": this.otherKeysData.linkset };
            glnDataObjectOtherKey.glnRecords.push(this.otherKeysData);   
            glnDataObjectOtherKey.gs1Licence = this.otherKeysData.gs1Licence;
            this.otherKeysData = glnDataObjectOtherKey;
            break;

          case companyParam !== null:

            this.companyData = JSON.parse(localStorage.getItem(companyParam));
            var glnDataObject = { "glnRecordStatus": undefined,  "glnRecords": new Array(), "canDisplayAttributesSection": false, "gs1Licence": {},"linkset":{} };
            glnDataObject.glnRecords.push(this.companyData);            
            glnDataObject.gs1Licence = this.companyData;
            //this.hideHeader = true
            this.companyData = glnDataObject;
            break;

          default:
            // Handle default case
            console.log('No valid case found.');
        }



        // let data = params.get('GTIN');
        // if (data != null && data != '') {
        //   this.gtinData = JSON.parse(localStorage.getItem(data));
        // }

        // data = params.get('GLN') || params.get('Company');
        // if (data != null && data != '') {
        //   var gln = JSON.parse(localStorage.getItem(data));
        //   var glnDataObject = { "glnRecords": new Array(), "canDisplayAttributesSection": true, "gs1Licence": {} };
        //   glnDataObject.glnRecords.push(gln);
        //   glnDataObject.canDisplayAttributesSection = gln.canDisplayAttributesSection;
        //   //TODO: seperte the logic for each search type for simplicity
        //   if (params.get('Company')) {
        //     glnDataObject.gs1Licence = gln;
        //     this.hideHeader = true
        //   }
        //   else
        //     glnDataObject.gs1Licence = gln.gs1Licence;

        //   this.glnData = glnDataObject;
        // }
      });

    if (breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.ProductInfo_Label = "Product_Information_Short";
      this.ComapnyInfo_Label = "Comapny_Information_Short";
      if (this.glnData) {
        this.ProductInfo_Label = "Gln_Information_Short";
      }
    } else {
      this.ProductInfo_Label = "Product_Information";
      this.ComapnyInfo_Label = "Comapny_Information";
      if (this.glnData) {
        this.ProductInfo_Label = "Gln_Information";
      }
    }
    this.Additional_Information = "Additional_Information";

  }


  selectedCardIndex: number = 0;

  onCardClicked(index: number) {

      this.selectedCardIndex = index;
      this.glnData = null;
      this.gtinData = null;

  }

  ngOnInit(): void {
    //let ntvEl = this.matTabGroup.nativeElement;    
    // ntvEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.tabIndex = 0;
    var arrCountryCodes = new Array();
    var arrNetContents = new Array();
    if (this.gtinData) {
      if (this.gtinData.hasOwnProperty('countryOfSaleCode')) {
        this.gtinData.countryOfSaleCode.forEach(element => {
          var countries = JSON.parse(localStorage.getItem("countries"));
          var countryName = countries.find(c => c.numeric === element.numeric)
            && countries.find(c => c.numeric === element.numeric).country;

          arrCountryCodes.push(countryName);
        });
        this.gtinData.countryOfSaleCode[0].countryOfSaleCodes = arrCountryCodes.join(';  ');
      }
      if (this.gtinData.hasOwnProperty('netContent')) {
        this.gtinData.netContent.forEach(element => {
          var unitCodes = JSON.parse(localStorage.getItem("unitCodes"));
          var unitCodeDescription = unitCodes.find(c => c.numeric === element.numeric)
            && unitCodes.find(c => c.unitCode === element.unitCode).description;

          arrNetContents.push(element.value + ' ' + unitCodeDescription);
        });
        this.gtinData.netContent[0].netContents = arrNetContents;
      }

      if (this.gtinData.hasOwnProperty('gpcCategoryCode')) {
        this.gpcCategoryCodeValue = this.gtinData.gpcCategoryCode;
        this.sharedService.getGPCdescription(this.gtinData.gpcCategoryCode, CommonUtilityFunctions.getGPCLanguageCode()).subscribe(result => {
          if (this.gtinData.gpcCategoryCode != result) { this.gtinData.gpcCategoryCode = `${this.gtinData.gpcCategoryCode} - ${result}`; }
          else { this.gtinData.gpcCategoryCode = result; }

        })

      }
      if (this.gtinData.dateUpdated) {
        var pipe = new DatePipe('en-US');
        this.gtinData.dateUpdated = pipe.transform(this.gtinData.dateUpdated);
      }
      this.canDisplayAttributesSection = this.gtinData.canDisplayAttributesSection;
    }
    // this.hasGlnInfo = true;
    // if (this.glnData && this.glnData.glnRecords != null && this.glnData.glnRecords.length > 0) {
    //   this.canDisplayAttributesSection = this.glnData.glnRecords[0].canDisplayAttributesSection;
    //   if (this.glnData.glnRecords[0].glnTypeLabel == "") {
    //     this.hasGlnInfo = false;
    //   }
    // }



  }

  updateGPCCategoryCode(languageCode) {
    //update gpc to translated language
    this.sharedService.getGPCdescription(this.gpcCategoryCodeValue, CommonUtilityFunctions.getGPCLanguageCode(languageCode)).subscribe(result => {
      if (this.gtinData.gpcCategoryCode != result) { this.gtinData.gpcCategoryCode = `${this.gpcCategoryCodeValue} - ${result}`; }
      else { this.gtinData.gpcCategoryCode = result; }
    })
  }
  public onNavigationClick() {
    this.router.navigate(['/home']);
    this.sharedService.setShowGtinList(true);
  }

  public onNavigationGlnClick() {
    this.router.navigate(['/home']);
    this.sharedService.setShowGlnList(true);
  }
  public onNavigationOtherKeysClick() {
    this.router.navigate(['/home']);
    this.sharedService.setShowOtherKeyList(true);
  }
  public onNavigationCompanesClick() {
    this.router.navigate(['/home']);
    this.sharedService.setshowCompanyList(true);
  }

  clickSupportLink() {
    this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      window.open(currentMO.link, '_blank');

    }, err => { console.log(err); });
  }
  onTabChanged($event) {

    // let ntvEl = this.matTabGroup.nativeElement;   
    // ntvEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
