import { SharedService } from 'src/services/shared.service';
import { Component, Input, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { environment } from 'src/environments/environment';
import { CommonUtilityFunctions } from 'src/app/helpers/common-util-functions';

@Component({
  selector: 'mobile-header',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {
  @Input() displayBanner: boolean;
  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();

  user_name: any;
  user_type: any;
  selected: any;
  user_languages: string[];
  user_defaultlanguage: string;
  header_image_url: string;
  isMenuOpen: boolean = false;
  cdnUrl: string = environment.cdnUrl+'/img';

  constructor(private router: Router, public authService: AuthService,
    private cookieService: CookieService,
    public translate: TranslateService, public sharedService: SharedService) {
  }

  ngOnInit(): void {

    this.header_image_url = environment.cdnUrl+"\\img\\" + this.authService.currentCRMUser.MOID + ".png";
    this.user_languages = JSON.parse(this.authService.currentCRMUser.GDOSCLanguage.toString());

    if (this.authService.currentCRMUser.GDOSCDefaultLanguage != '') {
      this.user_defaultlanguage = this.authService.currentCRMUser.GDOSCDefaultLanguage;
      CommonUtilityFunctions.SetLanguagePreferences(this.user_defaultlanguage);
      var preferedLanguageCode =localStorage.getItem("preferedLanguageCode");
      this.translate.use(preferedLanguageCode);
    }

    if (this.user_defaultlanguage == null || this.user_defaultlanguage == "") {
      this.user_defaultlanguage = this.user_languages[0];
      this.authService.currentCRMUser.GDOSCDefaultLanguage = this.user_defaultlanguage;

      this.sharedService.updateClaims(this.authService.currentCRMUser).subscribe(response => {
        //console.log("TOU version updated.")
      }, error => {
        console.log(error);
      });

    }

    this.user_languages.forEach((element, index) => {
      if (this.user_defaultlanguage == element && this.user_languages.length > 1) {
        this.user_languages.splice(index, 1);
      }
    });

    this.selected = "English";
  }
  login() {
    this.authService.login();
  }
  logout() {
    this.authService.logout();
  }

  clickLanguageMenuItem(selectedItem) {

    this.user_languages.push(this.user_defaultlanguage);
    this.user_defaultlanguage = selectedItem;
    CommonUtilityFunctions.SetLanguagePreferences(this.user_defaultlanguage);
    var preferedLanguageCode =localStorage.getItem("preferedLanguageCode");

    this.user_languages.forEach((element, index) => {
      if (this.user_defaultlanguage == element) this.user_languages.splice(index, 1);
    });

    this.translate.use(preferedLanguageCode);

    this.authService.currentCRMUser.GDOSCDefaultLanguage = this.user_defaultlanguage;
    localStorage.setItem('CRMUserProfile', JSON.stringify(this.authService.currentCRMUser));

    this.sharedService.updateClaims(this.authService.currentCRMUser).subscribe(response => {
      //console.log("TOU version updated.")
    }, error => {
      console.log(error);
    });
    this.languageChanged.emit(preferedLanguageCode);
  }

  clickSupportLink() {
    this.sharedService.getCountries(), this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      window.open(currentMO.link, '_blank');

    }, err => { console.log(err); });
  }

  onOpenMenu(event) {
    this.isMenuOpen = !this.isMenuOpen;
    let navbar_toggle = event.target.closest('.navbar-toggle');
    if (this.isMenuOpen) {
      navbar_toggle.classList.remove("collapsed");
      setTimeout(() => {
        navbar_toggle.classList.add("expanded");
      }, 0)
    }
    else {
      navbar_toggle.classList.remove("expanded");
      setTimeout(() => {
        navbar_toggle.classList.add("collapsed");
      }, 0)
    }
  }

  OpenDivClick(event) {
    let open_div = event.target.closest('.open-div');
    if (open_div.style.transform == "rotate(180deg)") {
      open_div.style.transform = "rotate(0deg)";
      let content_div = event.target.closest('.main-link-div');
      content_div.querySelector('.content-div').className = 'content-div dn'
    }
    else {
      open_div.style.transform = "rotate(180deg)"
      let content_div = event.target.closest('.main-link-div');
      content_div.querySelector('.content-div').className = 'content-div ps'

    }
    event.target.style.display = "block";
  }
  redirectToCustomURL() {   
    this.sharedService.getSupportLink().subscribe((response: Array<any>) => {
      var currentMO = response.find(c => c.moid === this.authService.currentCRMUser.MOID);
      if (currentMO.customRedirectlink)
        window.open(currentMO.customRedirectlink, '_blank');
      else
        this.router.navigate(['/home']);

    }, err => { console.log(err); });
  }
}
