<div class="container">
<footer class="footer">
  <div style="height:40px;">
    <span>Ⓒ GS1 AISBL {{currentYear}}</span>
    <span>|</span>
    <span><a (click)="redirectToTermOfUse()" title="{{'Terms_of_Use' | translate}}" target="_blank" class="toulink">{{'Terms_of_Use' | translate}}</a></span>
    <span>|</span>
    <span><a (click)="redirectToCustomURL()" rel="noopener"  title="{{'Privacy_Policy' | translate}}" class="toulink" target="_blank">{{'Privacy_Policy' | translate}}</a></span>
  </div>
</footer>
</div>
